/* eslint-disable react-hooks/exhaustive-deps */
import React,{ Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Box,  Button, Chip, Theme, Tooltip, createStyles, makeStyles, styled, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridToolbar, GridFilterModel, GridRowData } from "@material-ui/data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import CustomDialog from '../../components/custom_components/Dialog';
import Actions from "../../actions/activity";
import "moment/dist/locale/th";
import { SelectBox } from '../../types/activity';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useGetServiceSlot } from './hook';
import moment from 'moment';
import "moment/dist/locale/th";
import {thaiYear} from '../../reducers/selectors/thaiYear';
import { ExtendButtonBase, IconButton, IconButtonTypeMap } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ServiceSlotManage from './dialog';

interface IProps {
  // loading:boolean;
  // groupData: Record<string, any>;
  // filltered: Record<string, any>;
  // getCases: (filter: Record<string, any>) => Promise<void>;
  // currentPage: number;
  // pageSize: number;
  // handlePageChange: (page: number, details?: any) => void;
}

const useStyles = makeStyles<Theme, "tooltip">((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: ".8em"
    }
  }),
);

const StyledChip = styled(Chip)((props:any) => ({
  color: props.fontcolor,
  borderColor: props.bordercolor,
  backgroundColor: props.bgcolor,
}));

const ServiceSlotList:FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const globalState = useSelector((state: RootState) => state);
  const [dialogOpen, setDialogOpen]=useState(false);

  const tooltip = (id:string, label:string, icon:any, action=()=>{})=>{
    return <Tooltip arrow placement="top" 
            key={id} 
            style={{cursor:'pointer'}}
            classes={{ tooltip: classes.tooltip }}
            title={label}>
              <IconButton onClick={action}>{icon}</IconButton>
          </Tooltip>
  };

  const { isError, isSuccess, isLoading, data, error, refetch  } = useGetServiceSlot();
  
  if (isError) {
    console.log("Error: ", error);
  }

  if (isSuccess) {
    console.log("ServiceSlotList Get Success: ", data);
  }

  const columns: GridColDef[] = [
    { 
      field: 'user_id', 
      headerName: 'รหัสผู้ใช้งาน', 
      headerClassName:'apm-header', 
      headerAlign:'center', 
      align:'center',
      flex: 0.6
    },
    { 
      field: 'createDateTime', 
      headerName: 'วันที่สร้าง', 
      headerClassName:'apm-header', 
      headerAlign:'center', 
      align:'center',
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        return thaiYear(moment(params.row.createDateTime),"d MMM YYYY hh:mm");
      }
    },
    { 
      field: 'updateDateTime', 
      headerName: 'วันที่ปรับปรุง', 
      headerClassName:'apm-header', 
      headerAlign:'center', 
      align:'center',
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        return thaiYear(moment(params.row.updateDateTime),"d MMM YYYY hh:mm");
      }
    },
    { 
      field: 'command', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        return <>
          {tooltip("edit","แก้ไข",<EditIcon />, ()=>setDialogOpen(true)) }
          {tooltip("delete","ลบ",<DeleteIcon />)}
        </>
      }
    }
  ];
  // isLoading?[]:data
  return (
    <Box style={{marginTop:3,width: '100%'}}>
      <DataGrid
        rows={[]}
        columns={columns}
        loading={isLoading}
        autoHeight
        pagination
        paginationMode="server"
        // page={props.currentPage}
        // pageSize={props.pageSize}
        // rowsPerPageOptions={[props.pageSize]}
        rowCount={globalState.activity.caseList.count}
        // onPageChange={(page:number)=>props.handlePageChange(page)}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        // filterMode='client'
        // filterModel={filterModel}
        // onFilterModelChange={(model) => setFilterModel(model)}
      />
      {(dialogOpen)&&<ServiceSlotManage title="แก้ไขงาน" open={dialogOpen} setOpen={setDialogOpen}/>}
    </Box>
  );
}

export default ServiceSlotList;