/* eslint-disable @typescript-eslint/no-unused-vars */
import { SEND_CM } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';
import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const updateGroup = fetchEntity.bind(null, Actions.UpdateGroup, Api.updateGroup);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getCases = fetchEntity.bind(null, Actions.GetCases, Api.getCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);

function* firstAction(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {
        return call(actionGetCases, action);
      },
      onFailure: () => put(snackbarFailure('updateGroup failed',1500))
    }
  );
}

function* actionGetCases(action: any) {
  yield call(
    getCases, { data: action.getCases.params },
    {
      onSuccess: (success: any) => {    
        return put(snackbarSuccess(action.updateCases.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getCases failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(SEND_CM.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
