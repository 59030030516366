/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, createRef } from "react";
import * as UAPI from "../../services/UniversalAPI";
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import parseDOM from 'html-react-parser';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { getCurrentUser } from "../../services/auth.service";

import img_cc01 from "../../images/questionnaire/cc01.png";
import img_cc02 from "../../images/questionnaire/cc02.png";
import img_cc03 from "../../images/questionnaire/cc03.png";
import img_cc04 from "../../images/questionnaire/cc04.png";
import img_cc05 from "../../images/questionnaire/cc05.png";
import img_cc06 from "../../images/questionnaire/cc06.png";
import img_cc07 from "../../images/questionnaire/cc07.png";
import img_cc08 from "../../images/questionnaire/cc08.png";
import img_cc09 from "../../images/questionnaire/cc09.png";
import img_cc10 from "../../images/questionnaire/cc10.png";
import img_cc11 from "../../images/questionnaire/cc11.png";
import img_cc12 from "../../images/questionnaire/cc12.png";
import img_cc13 from "../../images/questionnaire/cc13.png";
import img_cc14 from "../../images/questionnaire/cc14.png";
import img_cc15 from "../../images/questionnaire/cc15.png";
import img_cc16 from "../../images/questionnaire/cc16.png";
import img_cc17 from "../../images/questionnaire/cc17.png";
import img_cc18 from "../../images/questionnaire/cc18.png";
import img_cc19 from "../../images/questionnaire/cc19.png";

import child1 from "../../images/questionnaire/child1.png";
import child2 from "../../images/questionnaire/child2.png";
import child3 from "../../images/questionnaire/child3.png";
import child4 from "../../images/questionnaire/child4.png";
import face1 from "../../images/questionnaire/face1.png";
import face2 from "../../images/questionnaire/face2.png";
import face3 from "../../images/questionnaire/face3.png";
import face4 from "../../images/questionnaire/face4.png";

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  }
}));

export default function QuestionnaireView(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [data, setData] = useState(null);
  const [curTime, setCurTime] = useState((new Date()).getHours().toString() + (new Date()).getMinutes().toString() + (new Date()).getSeconds().toString());
  const [returnAnswerPath, setReturnAnswerPath] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [keyNumberValue, setKeyNumberValue] = useState([]);
  const answerLog = useRef('');
  const activeElement = useRef([]);
  // const elRefs = React.useRef([]);

  if (activeElement.current.length !== keyNumberValue.length) {
    // add or remove refs
    activeElement.current = Array(keyNumberValue.length).fill().map((_, i) => activeElement.current[i] || createRef());
  }

  const checkObjectData = (x) => {
    let r = [];
    if (typeof x !== 'undefined') {
      if (x !== null) {
        r = x;
      }
    }
    return r;
  }

  const getInterventionAnswer = async (interventionId, questionId, sequenceId) => {
    let xParams = {
      filter: {
        limit: 1,
        where: {
          id: interventionId
        },
      }
    };
    let response = await UAPI.getAll(xParams, 'interventions', null);
    if (response.status === 200) {
      if (response.data) {
        let x = response.data[0];
        if (typeof x.activities !== 'undefined') {
          if (typeof x.activities.homeservice !== 'undefined') {
            x.activities.homeservice.forEach(h => {
              if (typeof h.serviceType !== 'undefined') {
                if (h.serviceType === 'oscc') {
                  if (typeof h.questionnaire !== 'undefined') {
                    h.questionnaire.forEach(q => {
                      // console.log(q);
                      // if (typeof q.id !== 'undefined') {
                      //   if (q.id === questionId) {
                      // ลูกทุ่งโดย เอกภาพ
                      // if (typeof q.sequenceId !== 'undefined') {
                      //   if (q.sequenceId === sequenceId) {
                          // console.log(q);
                          let questionRunNumber = -1;
                          q.questionsList.forEach(i => {
                            if (typeof i.groupQuestionsList === 'undefined') {
                              ++questionRunNumber;
                              i['keyNumber'] = questionRunNumber;
                              if (typeof i.answersList !== 'undefined') {
                                if (parseInt(i.questionType) === 2 || parseInt(i.questionType) === 3) {
                                  i.answersList.forEach(element => {
                                    ++questionRunNumber;
                                    element['keyNumber'] = questionRunNumber;
                                  });
                                }
                              }
                            }else {
                              i.groupQuestionsList.forEach(s => {
                                ++questionRunNumber;
                                s['keyNumber'] = questionRunNumber;
                                if (typeof s.answersList !== 'undefined') {
                                  if (parseInt(s.questionType) === 2 || parseInt(s.questionType) === 3) {
                                    s.answersList.forEach(element => {
                                      ++questionRunNumber;
                                      element['keyNumber'] = questionRunNumber;
                                    });
                                  }
                                }
                              });
                            }
                          });
                          let temp_keyNumberValue = [];
                          for (var i = 0; i <= questionRunNumber; ++i) {
                            // temp_keyNumberValue[i] = '';
                            temp_keyNumberValue.push(i);
                          }
                          // console.log(q);
                          // console.log(temp_keyNumberValue);
                          setKeyNumberValue(temp_keyNumberValue);
                          console.log(q);
                          setData(q);
                          answerLog.current = q;
                      //   }
                      // }else{

                      // }
                    });
                  }
                }
              }
            });
          }
        }
      }
    }
  }

  const getQuestionnaire = async (q) => {
    // console.log(q);
    let attachedQuestionnaire = 0;
    if (typeof q.questionnaire !== 'undefined') {
      if (Object.keys(q.questionnaire).length > 0) {
        attachedQuestionnaire = 1;
      }
    }
    let x = {};
    if (attachedQuestionnaire === 1) {
      // console.log('Attached');
      x = q.questionnaire;
    }
    else {
      // console.log('NOT Attached');
      let id = q.id;
      if (typeof id !== 'undefined') {
        let response = await UAPI.get(id, 'questionnaires', '3009');
        if (response.status === 200) {
          if (response.data) {
            x = response.data;
          }
        }
      }
    }
    // console.log(q);
    // console.log(x);
    // console.log(props.location.state.questionData);
    x.planServiceId = props.location.state.questionData.planServiceId;
    x.questionsList.forEach(i => {
      if (parseInt(i.questionType) === 2 || parseInt(i.questionType) === 3) {
        if (typeof i.answersList !== 'undefined') {
          i.answersList.forEach(l => {
            if (typeof l.checked === 'undefined') {
              l.checked = false;
            }
          });
        }
      }
      else {
        i.answersList = "";
      }
    });
    x.sumScore = 0;

    let questionRunNumber = -1;
    x.questionsList.forEach(i => {
      if (typeof i.groupQuestionsList === 'undefined') {
        ++questionRunNumber;
        i['keyNumber'] = questionRunNumber;
        if (typeof i.answersList !== 'undefined') {
          if (i.answersList.length > 0) {
            i.answersList.forEach(element => {
              ++questionRunNumber;
              element['keyNumber'] = questionRunNumber;
            });
          }
        }
      }
      else {
        i.groupQuestionsList.forEach(s => {
          ++questionRunNumber;
          s['keyNumber'] = questionRunNumber;
          if (typeof s.answersList !== 'undefined') {
            if (s.answersList.length > 0) {
              s.answersList.forEach(element => {
                ++questionRunNumber;
                element['keyNumber'] = questionRunNumber;
              });
            }
          }
        });
      }
    });
    // console.log(x);
    let temp_keyNumberValue = [];
    for (var i = 0; i <= questionRunNumber; ++i) {
      temp_keyNumberValue[i] = '';
    }
    // console.log(temp_keyNumberValue);
    setKeyNumberValue(temp_keyNumberValue);
    setData(x);
    answerLog.current = x;
  }

  const changeCheckBox = (e, z, o) => {
    let x = JSON.parse(JSON.stringify(answerLog.current));
    x.questionsList.forEach(q => {
      if (q.question === z.question) {
        q.answersList.forEach(a => {
          if (a.answer === o.answer) {
            a.checked = e.target.checked;
          }
        });
      }
      if (typeof q.groupQuestionsList !== 'undefined') {
        q.groupQuestionsList.forEach(qq => {
          if (qq.keyNumber === z.keyNumber) {
            qq.answersList.forEach(aa => {
              if (aa.keyNumber === o.keyNumber) {
                aa.checked = true;
              }
              else {
                aa.checked = false;
              }
            });
          }
        });
      }
    });
    let a = calSumScore(x);
    answerLog.current = a;
  }

  const changeAnswerSpecify = (e, z, o) => {
    let x = JSON.parse(JSON.stringify(answerLog.current));
    x.questionsList.forEach(q => {
      if (q.keyNumber === z.keyNumber) {
        q.answerSpecify = e.target.value;
      }
      if (typeof q.groupQuestionsList !== 'undefined') {
        q.groupQuestionsList.forEach(gq => {
          if (gq.keyNumber === z.keyNumber) {
            gq.answerSpecify = e.target.value;
          }
        });
      }
    });
    answerLog.current = x;
  }

  const clickRadio = (e, z, o) => {
    // console.log(o.keyNumber);
    // console.log(activeElement.current);
    let c = activeElement.current[o.keyNumber].current;
    let p = c.parentElement.parentElement.parentElement.getElementsByTagName('input');
    // console.log(p);
    for (var i=0;i<p.length;++i) {
      p[i].checked = false;
    }
    c.checked = true;

    let x = JSON.parse(JSON.stringify(answerLog.current));
    x.questionsList.forEach(q => {
      if (q.keyNumber === z.keyNumber) {
        q.answersList.forEach(a => {
          if (a.keyNumber === o.keyNumber) {
            a.checked = true;
          }
          else {
            a.checked = false;
          }
        });
      }
      if (typeof q.groupQuestionsList !== 'undefined') {
        q.groupQuestionsList.forEach(qq => {
          if (qq.keyNumber === z.keyNumber) {
            qq.answersList.forEach(aa => {
              if (aa.keyNumber === o.keyNumber) {
                aa.checked = true;
              }
              else {
                aa.checked = false;
              }
            });
          }
        });
      }
    });
    let a = calSumScore(x);
    answerLog.current = a;
  }

  const changeText = (e, z) => {
    let keyNumber = z.keyNumber;
    let x = JSON.parse(JSON.stringify(answerLog.current));
    x.questionsList.forEach(q => {
      if (parseInt(q.keyNumber) === parseInt(keyNumber)) {
        q.answersList = e.target.value;
      }
      if (typeof q.groupQuestionsList !== 'undefined') {
        q.groupQuestionsList.forEach(qq => {
          if (parseInt(qq.keyNumber) === parseInt(keyNumber)) {
            qq.answersList = e.target.value;
          }
        });
      }
    });
    answerLog.current = x;
  }

  const mkCheckBox = (x) => {
    if (typeof x !== 'undefined') {
      if (x) {
        let a = x.answersList;
        let l = [];
        let a_n = 0;
        a.forEach(o => {
          ++a_n;
          let answerSpecify = [];
          if (a_n === a.length) {
            if (parseInt(x.questionType) === 2 || parseInt(x.questionType) === 3) {
              if (typeof x.answerSpecify !== 'undefined') {
                answerSpecify.push(
                  <TextField
                    key={genKey()}
                    style={{ marginLeft: 5 }}
                    defaultValue={x.answerSpecify}
                    onBlur={(e) => changeAnswerSpecify(e, x, o)}
                  />
                );
              }
            }
          }
          l.push(
            <div key={genKey()}>

              {/* <FormControlLabel key={a_n}
                control={<Checkbox onChange={(e) => changeCheckBox(e, x, o)} />}
                checked={o.checked}
                value={o.score}
                label={o.answer}
                style={{ height: 25, margin: 0, padding: 0 }}
              disabled={inputDisabled}
              /> */}

              <div style={{ display: 'inline-block'}}>
                <input 
                  id={o.keyNumber}
                  ref={activeElement.current[o.keyNumber]}
                  type="checkbox" 
                  value={o.score}
                  onClick={(e) => changeCheckBox(e, x, o)} 
                  defaultChecked={o.checked}
                  disabled={inputDisabled}
                /> 
              </div>
              <div style={{ display: 'inline-block' }}>
                <label htmlFor={o.keyNumber}>&nbsp;{o.answer}</label>
              </div>

              {answerSpecify}
            </div>
          );
        });
        return (
          <div key={'answersList' + genKey()} style={{ paddingLeft: 10, marginTop: 10, marginBottom: 10 }}>
            {l}
          </div>
          // <div key={'answersList' + genKey()} style={{ paddingLeft: 10 }}>
          //   <FormGroup>
          //     {l}
          //   </FormGroup>
          // </div>
        );
      }
    }
  }

  const mkRadio = (x) => {
    if (typeof x !== 'undefined') {
      if (x) {
        let a = x.answersList;
        let l = [];
        let a_n = 0;
        let haveImgs=false;
        a.forEach(o => {
          ++a_n;
          let answerSpecify = [];
          if (a_n === a.length) {
            if (parseInt(x.questionType) === 2 || parseInt(x.questionType) === 3) {
              if (typeof x.answerSpecify !== 'undefined') {
                answerSpecify.push(
                  <TextField
                    key={genKey()}
                    style={{ marginLeft: 5 }}
                    defaultValue={x.answerSpecify}
                    onBlur={(e) => changeAnswerSpecify(e, x, o)}
                  />
                );
              }
            }
          }

          let answerSpecifyEachChoice = [];
          if (typeof o.answerSpecify !== 'undefined') {
            answerSpecifyEachChoice.push(
              <TextField
                key={genKey()}
                style={{ marginLeft: 5 }}
                defaultValue={o.answerSpecify}
                onBlur={(e) => changeAnswerSpecify(e, x, o)}
              />
            );
          }

          let imgs = [];
          let imgsDiv;
          if (typeof o.images !== 'undefined') {
            haveImgs=true;
            o.images.forEach(im => {
              imgs.push(<img key={genKey()} src={require(`../../images/questionnaire/${im}`).default} alt={im} style={{ width: isDesktop ? '30%' : '50%' }} />);
            });
            imgsDiv = <div>{imgs}</div>;
          }

          let thisLabel = [];
          if (imgs.length>0) {
            if (typeof o.answer !== 'undefined') {
              thisLabel.push(
                <label key={genKey()} htmlFor={o.keyNumber}>
                  {imgsDiv}
                  &nbsp;{o.answer}
                  {answerSpecifyEachChoice}
                </label>
              );
            }
            else {
              thisLabel.push(
                <label key={genKey()} htmlFor={o.keyNumber}>
                  {imgsDiv}
                </label>
              );
            }
          }
          else {
            thisLabel.push(
              <label key={genKey()} htmlFor={o.keyNumber}>
                &nbsp;{o.answer}
                {answerSpecifyEachChoice}
              </label>
            );
          }

          l.push(
            <div key={genKey()} style={{ height: imgs.lenngth===0?25:'auto',display: 'flex' ,justifyContent: 'flex-start' }} >
              <div style={{ display: 'inline-block', paddingTop: 0 }}>
                <input 
                  id={o.keyNumber}
                  ref={activeElement.current[o.keyNumber]}
                  type="radio" 
                  value={o.score}
                  onClick={(e) => clickRadio(e, x, o)} 
                  defaultChecked={o.checked}
                  disabled={inputDisabled}
                /> 
              </div>
              <div style={{ display: 'inline-block' }}>
                {thisLabel}
              </div>

              {answerSpecify}

            </div>
          );
        });
        return (
          <div key={'answersList' + genKey()} style={{ paddingLeft: 10, marginTop: 10, marginBottom: 10 }}>
            {l}
          </div>
        );
      }
    }
  }

  const mkQuestionsList = () => {
    if (typeof data.questionsList !== 'undefined') {
      let x = JSON.parse(JSON.stringify(data.questionsList));
      let r = [];
      let n = 0;
      let m = x.length;
      x.forEach(i => {
        // console.log(i);
        let c = [];
        if (typeof i.groupDescription !== 'undefined') {
          let eee = [];
          eee.push(convertVarible(i.groupDescription));
          c.push(<div key={genKey()}><b>{eee}</b></div>);
          if (typeof i.remark !== 'undefined') {
            c.push(<div key={genKey()}>{i.remark}</div>);
          }
          // console.log(i);
          if (typeof i.groupQuestionsList !== 'undefined') {
            ++n;
            i.groupQuestionsList.forEach(gq => {
              let imgs = [];
              let imgsDiv="";
              if (typeof gq.images !== 'undefined') {
                gq.images.forEach(im => {
                  imgs.push(<img key={genKey()} src={require(`../../images/questionnaire/${im}`).default} alt={im} style={{ width: isDesktop ? '30%' : '50%' }} />);
                });
                imgsDiv = <div style={{ marginTop: 50}}>{imgs}</div>;
              }
              // console.log((gq.question));
              c.push(
                <div key={genKey()}>
                  {imgsDiv}
                  {(gq.question)&&convertVarible(gq.question)} 
                  {parseInt(gq.questionType) === 3 ? '(เลือกได้หลายข้อ)' : ''}
                  {typeof gq.remark !== 'undefined' && (gq.remark)}
                </div>
              );
              if (parseInt(gq.questionType) === 3) {
                c.push(mkCheckBox(gq));
              }else if (parseInt(gq.questionType) === 2) {
                c.push(mkRadio(gq));
              }else {
                // console.log(gq);
                let defaultValue = answersDefaultValue(gq.answersDefault)
                if (typeof props.location.state.questionData.interventionId !== 'undefined') {
                  if (props.location.state.questionData.interventionId !== null) {
                    if (props.location.state.questionData.interventionId !== "") {
                      if (props.location.state.questionData.interventionId.length>0) {
                        defaultValue = gq.answersList;
                      }
                    }
                  }
                }

                let keyNumber = gq.keyNumber;
                let temp_answerLog = JSON.parse(JSON.stringify(answerLog.current));
                if (temp_answerLog!=='') {
                  temp_answerLog.questionsList.forEach(al_q => {
                    if (parseInt(al_q.keyNumber) === parseInt(keyNumber)) {
                      al_q.answersList = defaultValue;
                    }
                    if (typeof al_q.groupQuestionsList !== 'undefined') {
                      al_q.groupQuestionsList.forEach(al_qq => {
                        if (parseInt(al_qq.keyNumber) === parseInt(keyNumber)) {
                          al_qq.answersList = defaultValue;
                        }
                      });
                    }
                  });
                  answerLog.current = temp_answerLog;
                }

                c.push(
                  <div key={genKey()} style={{ paddingLeft: 10 }}>
                    <TextField
                      defaultValue={defaultValue}
                      onBlur={(e) => changeText(e, gq)}
                      placeholder={'กรุณาพิมพ์คำตอบ'}
                      variant="outlined"
                      style={{ marginBottom: 8, marginLeft: 10, width: '90%' }}
                      InputProps={{ classes: { input: classes.TextField } }}
                      disabled={inputDisabled}
                    />
                  </div>
                );
              }
            });
          }
          if (typeof i.remarkAtEnd !== 'undefined') {
            c.push(<div key={genKey()} style={{ marginTop: 20, border: 'solid 1px blue', borderRadius: 4, padding: 5, color: 'blue' }}>{i.remarkAtEnd}</div>);
          }
        }else {
          ++n;
          let imgs = [];
          let imgsDiv;
          if (typeof i.images !== 'undefined') {
            i.images.forEach(im => {
              imgs.push(<img key={genKey()} src={require(`../../images/questionnaire/${im}`).default} alt={im} style={{ width: isDesktop ? '30%' : '50%' }} />);
            });
            imgsDiv = <div>{imgs}</div>;
          }
          c.push(
            <div key={genKey()}>
              {imgsDiv}
              {convertVarible(i.question)} {parseInt(i.questionType) === 3 ? '(เลือกได้หลายข้อ)' : ''}
              {typeof i.remark !== 'undefined' && (
                i.remark
              )}
            </div>
          );
          if (parseInt(i.questionType) === 3) {
            c.push(mkCheckBox(i));
          }
          else if (parseInt(i.questionType) === 2) {
            c.push(mkRadio(i));
          }
          else {
            // console.log(gq);
            let defaultValue = answersDefaultValue(i.answersDefault)
            if (typeof props.location.state.questionData.interventionId !== 'undefined') {
              if (props.location.state.questionData.interventionId !== null) {
                if (props.location.state.questionData.interventionId !== "") {
                  if (props.location.state.questionData.interventionId.length>0) {
                    defaultValue = i.answersList;
                  }
                }
              }
            }

            let keyNumber = i.keyNumber;
            let temp_answerLog = JSON.parse(JSON.stringify(answerLog.current));
            if (temp_answerLog!=='') {
              temp_answerLog.questionsList.forEach(al_q => {
                if (parseInt(al_q.keyNumber) === parseInt(keyNumber)) {
                  al_q.answersList = defaultValue;
                }
                if (typeof al_q.groupQuestionsList !== 'undefined') {
                  al_q.groupQuestionsList.forEach(al_qq => {
                    if (parseInt(al_qq.keyNumber) === parseInt(keyNumber)) {
                      al_qq.answersList = defaultValue;
                    }
                  });
                }
              });
              answerLog.current = temp_answerLog;
            }

            c.push(
              <div key={genKey()} style={{ paddingLeft: 10 }}>
                <TextField
                  defaultValue={defaultValue}
                  onBlur={(e) => changeText(e, i)}
                  placeholder={'กรุณาพิมพ์คำตอบ'}
                  variant="outlined"
                  style={{ marginBottom: 8, marginLeft: 10, width: '90%' }}
                  InputProps={{ classes: { input: classes.TextField } }}
                disabled={inputDisabled}
                />
              </div>
            );
          }
        }
        r.push(
          <div key={genKey()} style={{ borderBottom: 'solid ' + (n === m ? 0 : 3) + 'px #000000', padding: 5, paddingLeft: 10 }}>
            {c}
          </div>
        );
      });
      return (<div>{r}<div style={{ visibility: 'hidden' }}>{curTime}</div></div>);
    }
  }

  const customParseHTML = (x) => {
    let doc = parseDOM('<span key='+genKey()+'>'+x+'</span>');
    // console.log(doc);
    return doc;
  }

  const convertVarible = (x) => {
    // console.log(x);
    let r = [];
    if ( x.indexOf('[[')>-1 && x.indexOf(']]')>-1 ) {
      let xSplited = x.split('[[');
      let xParts = [];
      if (xSplited.length>0) {
        xParts.push(
          <span key={genKey()}>
            {customParseHTML(xSplited[0])}
          </span>
        );
        xSplited.forEach(i => {
          if (i.indexOf(']]')>-1) {
            let zSplited = i.split(']]');
            let varInfo = zSplited[0].split(':');
            let variableText = varInfo[0];
            let variableName = varInfo[1];
            let variableConverted = answersDefaultValue(variableName);
            // console.log(variableName);
            xParts.push(
              <span key={genKey()} style={{ color: 'red' }}>
                {variableConverted!==''?variableConverted:variableText}
              </span>
            );
            xParts.push(
              <span key={genKey()}>
                {customParseHTML(zSplited[1])}
              </span>
            );
          }
        });
      }
      r.push(xParts);
    } else {
      r.push(customParseHTML(x));
    }
    return r;
  }

  const genKey = () => {
    return Math.floor(Math.random() * 1000000) + 1;
  }

  const calSumScore = (AL) => {
    let r;
    if (typeof AL.questionsList !== 'undefined') {
      let x = AL.questionsList;
      let s = 0;
      x.forEach(i => {
        if (parseInt(i.questionType) === 2 || parseInt(i.questionType) === 3) {
          if (typeof i.answersList !== 'undefined') {
            i.answersList.forEach(o => {
              if (o.checked === true) {
                s = s + parseInt(o.score);
              }
            });
          }
        }
      });
      let z = AL;
      z['sumScore'] = s;
      r = z;
    }
    return r;
  }

  const collectAnswer = () => {
    // console.log('useRef- ', answerLog.current);
    let x = props.location.state.caseData;
    // x['answerData'] = data;
    x['answerData'] = answerLog.current;
    // console.log(x);
    x.answerData.questionsList.forEach(q1 => {
      if (typeof q1.groupQuestionsList === 'undefined') {
        delete q1['keyNumber'];
        if (typeof q1.questionType !== 'undefined') {
          if (parseInt(q1.questionType) === 2 || parseInt(q1.questionType) === 3) {
            q1.answersList.forEach(q2 => {
              delete q2['keyNumber'];
            });
          }
        }
      }else {
        q1.groupQuestionsList.forEach(q1 => {
          delete q1['keyNumber'];
          if (typeof q1.questionType !== 'undefined') {
            if (parseInt(q1.questionType) === 2 || parseInt(q1.questionType) === 3) {
              q1.answersList.forEach(q2 => {
                delete q2['keyNumber'];
              });
            }
          }
        });
      }
    });
    // console.log(props.location.state);
    if (typeof props.location.state.questionData.sequenceId !== 'undefined') {
      x.answerData['sequenceId'] = props.location.state.questionData.sequenceId;
    }
    if (typeof props.location.state.questionData.inform !== 'undefined') {
      x.answerData['inform'] = props.location.state.questionData.inform;
    }
    if (typeof props.location.state.questionData.consent !== 'undefined') {
      x.answerData['consent'] = props.location.state.questionData.consent;
    }
    // console.log("บันทึก",x);
    if (returnAnswerPath) {
      history.push({
        pathname: returnAnswerPath,
        state: x,
      });
    }
  }

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

  const answersDefaultValue = (param) => {
    let r = "";
    let careGiverFirstName = '';
    let careGiverLastName = '';
    let careGiverFullName = '';
    let careGiverName = '';
    let careGiverCalledName = '';
    let caseFirstName = '';
    let caseLastName = '';
    let caseFullName = '';
    let caseAddressInfo ='';
    let caseSex = '';
    let casePhoneNumber = '';
    let caseAge = 0;
    let officerFirstName = '';
    let officerLastName = '';
    let officerFullName = '';
    let officerWorkPlace = '';
    if (typeof props.location.state !== 'undefined') {
      if (typeof props.location.state.caseData !== 'undefined') {
        let x = props.location.state.caseData;
        caseFirstName = x.fname;
        caseLastName = x.lname;
        caseFullName = x.fname+' '+x.lname;
        caseAddressInfo = "ม." + parseInt(x.villagecode.substr(-2)) + " ต." + x.tambol_name + " อ." + x.amphur_name + " จ." + x.province_name;
        caseSex = x.sex;
        if (typeof x.careGiver !== 'undefined') {
          careGiverFullName = (x.careGiver.fname?x.careGiver.fname:'')+(x.careGiver.lname?' '+x.careGiver.lname:'');
          careGiverFirstName = x.careGiver.fname;
          careGiverLastName = x.careGiver.lname;
          careGiverCalledName=x.careGiver.careGiverFullName;
          if (typeof x.careGiver.nameCalledByCase !== 'undefined') {
            if (x.careGiver.nameCalledByCase) {
              if (x.careGiver.nameCalledByCase !== '') {
                careGiverCalledName=x.careGiver.nameCalledByCase;
              }
            }
          }
        }
      }
      if (typeof props.location.state.personData !== 'undefined') {
        let z = props.location.state.personData;
        casePhoneNumber = typeof z.hometel !=='undefined'?(z.hometel?z.hometel:''):'';
        let caseBirth = typeof z.birthday !=='undefined'?(z.birthday?z.birthday:''):'';
        if (caseBirth!=='') {
          caseAge = getAge(caseBirth);
        }
      }
    }

    let cu = getCurrentUser();
    if (typeof cu !== 'undefined') {
      if (typeof cu.user !== 'undefined') {
        officerFullName = cu.user.fullname;
        let ofn = officerFullName.split(' ');
        officerFirstName = ofn[0];
        officerLastName = ofn[1];
        officerWorkPlace = typeof cu.department !== 'undefined'?cu.department.hos_name:'';
      }
    }

    switch (param) {
      case "caseFirstName":
        r = caseFirstName;
        break;

      case "caseLastName":
        r = caseLastName;
        break;

      case "caseFullName":
        r = caseFullName;
        break;

      case "caseAddressInfo":
        r = caseAddressInfo;
        break;

      case "casePhoneNumber":
        r = casePhoneNumber;
        break;

      case "caseAge":
        r = caseAge;
        break;

      case "caseSex":
        r = caseSex;
        break;

      case "careGiverFirstName":
        r = careGiverFirstName;
        break;

      case "careGiverLastName":
        r = careGiverLastName;
        break;

      case "careGiverFullName":
        r = careGiverFullName;
        break;
      
      case "careGiverName":
        r = careGiverFullName;
        break;

      case "careGiverCalledName":
        r = careGiverCalledName;
        break;

      case "officerFirstName":
        r = officerFirstName;
        break;

      case "officerLastName":
        r = officerLastName;
        break;

      case "officerFullName":
        r = officerFullName;
        break;

      case "officerWorkPlace":
        r = officerWorkPlace;
        break;
        
      default:
        break;
    }
    return r;
  }

  useEffect(() => {
    if (keyNumberValue.length>0 &&answerLog.current!=='') {
      let x = JSON.parse(JSON.stringify(answerLog.current));
      x.questionsList.forEach(q => {
        if (typeof q.groupQuestionsList === 'undefined') {
          if (typeof q.answersList === 'object') {
            q.answersList.forEach(a => {
              if (a.checked === true) {
                let ca = activeElement.current[a.keyNumber].current;
                ca.checked = true;
              }
            });
          }
        }
        if (typeof q.groupQuestionsList !== 'undefined') {
          q.groupQuestionsList.forEach(qq => {
            if (typeof qq.answersList === 'object') {
              qq.answersList.forEach(aa => {
                if (aa.checked === true) {
                  let caa = activeElement.current[aa.keyNumber].current;
                  caa.checked = true;
                }
              });
            }
          });
        }
      }); 
    }
  },[keyNumberValue]);

  useEffect(() => {
    // console.log(props.location.state);
    window.scrollTo(0, 0);
    let x = checkObjectData(props.location.state.questionData);
    // console.log(props);
    console.log(x.veiwType);
    // console.log(x);
    if (typeof x !== 'undefined') {
      if (Object.keys(x).length > 0) {
        if (typeof x.returnAnswerTo != 'undefined') {
          if (x.returnAnswerTo !== '' && x.returnAnswerTo !== null) {
            setReturnAnswerPath(x.returnAnswerTo);
          }
        }
        
        if (typeof props.location.state.questionData.veiwType !== 'undefined') {
          if (props.location.state.questionData.veiwType === 'recordable') {
            setInputDisabled(false);
          }
        }
        // ลูกทุ่งโดย เอกภาพ

        if(x.veiwType==='readonly'){setInputDisabled(true);}
        // console.log('Z');
        // ถ้าเคยมีคำตอบใน intervention แล้ว ก็เอาจาก intervention มาแสดงเลย
        if (typeof x.interventionId !== 'undefined') {
          if (x.interventionId !== '' && x.interventionId !== null) {
            // console.log('A');
            // console.log(x.interventionId);
            getInterventionAnswer(x.interventionId, x.id, x.sequenceId);
          } else {
            // console.log('B');
            getQuestionnaire(x);
          }
        }
        else {
          // console.log('C');
          getQuestionnaire(x);
        }
      }
    }
  }, [props]); 

  return (
    <div style={{ marginTop: -10, marginBottom: 20 }}>

      <div>
        <Grid container direction={isDesktop ? "row" : "column-reverse"}>

          <Grid item xs={12} sm={9}>
            {data && (
              <>
                <h4>
                  [{data.questionnaire_code}]&nbsp;
                  {data.questionnaire_name}
                </h4>
                {typeof data.remark != 'undefined' && (
                  <div>
                    {data.remark}
                  </div>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={3}>
            <Grid container justify="flex-end">
              <ButtonGroup size={isDesktop ? 'large' : 'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
                <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => { history.goBack() }}
                >
                  กลับ
                </Button>
                {returnAnswerPath && (
                  <Button variant="contained" color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} onClick={collectAnswer} disabled={inputDisabled}>
                    บันทึก
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>

        </Grid>
        {data && (
          <div style={{ borderRadius: 7, border: 'solid 3px #000000', marginTop: 5 }}>
            {mkQuestionsList()}
          </div>
        )}

        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup size={isDesktop ? 'large' : 'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
            <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
              onClick={() => { history.goBack() }}
            >
              กลับ
            </Button>
            {returnAnswerPath && (
              <Button variant="contained" color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} onClick={collectAnswer} disabled={inputDisabled}>
                บันทึก
              </Button>
            )}
          </ButtonGroup>
        </div>

      </div>

    </div>
  )
}
