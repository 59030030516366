/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_ANSWER } from "../index";

const GetAnswer = {
  request: (payload:any) => action(GET_ANSWER.REQUEST, {data:payload}),
  success: (response: any) => action(GET_ANSWER.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_ANSWER.FAILURE, { error }),
};

export default GetAnswer;