/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, createStyles, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import moment from "moment";
import "moment/dist/locale/th";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  type: string;
  open: true;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data?: Record<string,any>
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

export default function FormDialog(props: IProps) {
  // const classes = useStyles();
  // const theme = useTheme();
  // const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const questionaireList = globalState.activity.questionaireList.data;
  const groupList = globalState.activity.groupList.data;

  const [groupsList, setGroupsList] = useState([{ id: "", label: "" }]);
  const [questionairesList, setQuestionairesList] = useState<any>({});
  const [questionairesChoose, setQuestionairesChoose] = useState<any>([]);
  const [questionairesDataGrid, setQuestionairesDataGrid] = useState<any>([]);

  const [casesList, setCasesList] = useState<any>([]);

  // const [groupName, setGroupName] = useState<string>("");

  const [formData, setFormData] = useState({
    type: "group",
    groupId: null,
    teams: {},
    questionaire: [],
    assignDatetime: "",
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => props.setOpen(false);
  // const riskLevel = (riskLevel: string) => {
  //   let risk = "";
  //   switch (riskLevel) {
  //     case "low":
  //       risk = "ความเสี่ยงต่ำ";
  //       break;
  //     case "medium":
  //       risk = "ความเสี่ยงปานปลาง";
  //       break;
  //     case "high":
  //       risk = "ความเสี่ยงสูง";
  //       break;
  //     case "victim":
  //       risk = "เหยื่อ";
  //       break;
  //     default:
  //       risk = "อื่นๆ";
  //       break;
  //   }
  //   return risk;
  // };
  useEffect(() => {
    if (groupList && groupList.length > 0) {
      const optionsValue = groupList.map((item) => {
        return {
          id: item.id as string,
          label: item.groupName,
        };
      });
      setGroupsList(optionsValue);
    }
  }, [groupList]);
  useEffect(() => {
    if (questionaireList && questionaireList.length > 0) {
      const optionsValue = questionaireList.filter((f:any)=>{
        if(props.type==="single"){
          return (!f.questionnaire_group);
        }else{
          return (f.questionnaire_group);
        }
      }).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });
      setQuestionairesList(optionsValue);
    }
  }, [questionaireList,props.type]);

  // useEffect(() => {
    // console.log(questionairesChoose);
  // }, [questionairesChoose]);
  // useEffect(() => {
    // console.log(formData);
  // }, [formData]);
  const addQuestionaire = () => {
    const cloneQuestionaireList = [...casesList];
    cloneQuestionaireList.unshift(questionairesChoose.value);
    setQuestionairesDataGrid(cloneQuestionaireList);
    setQuestionairesChoose({ id: "", label: "" });
  };
  // const removeGroupCase=(row:any)=>{
  //   // add case back to searchCase
  //   // const cloneCases = [...cases];
  //   // cloneCases.unshift({
  //   //   id:row.cid as string,
  //   //   label: "["+row.cid+"] "+row.fname+" "+row.lname+" | เพศ"+row.sex+" | อายุ "+row.age+" ปี | "+riskLevel(row.riskLevel),
  //   //   value: row
  //   // });
  //   // setCases(cloneCases);

  //   // remove case from table
  //   const newCasesList = casesList.filter((item:any)=>item.id !== row.id);
  //   setCasesList(newCasesList);
  // }
  const saveAssign = () => {
    let questionaire: Array<Record<string,any>> = [];
    if (questionaireList && questionaireList.length > 0) {
      questionaire = questionaireList.filter((f:any)=>{
        return (props.type==="single")?(!f.questionnaire_group):(f.questionnaire_group)
      }).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });
    }
    const params = {
      type: props.type,
      teams: globalState.auth.user.response?.user.osccteams[0],
      questionaire: questionaire,
      cid: props.data?.cid,
      fname: props.data?.fname,
      lname: props.data?.lname,
      sex: props.data?.sex,
      age: props.data?.age,
      risk: props.data?.riskLevel,
      createDatetime: moment().format("YYYY-MM-DD hh:mm"),
    };
    // console.log(props.data,params);
    try {
      // dispatch(Actions.InsertGroup.request(params));
    } finally {
      // dispatch(
      //   Actions.GetGroupList.request({
      //     teamCode:
      //       globalState.auth.user.response?.user.osccteams[0].teamCode,
      //   })
      // );
      props.setOpen(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open || globalState.activity.insertGroup.isFetching}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography style={{ paddingTop: "24px" }}>
        <Typography variant="h4" style={{ marginBottom: 0 }}>
          {(props.type==="single")?"มอบหมายเคสแบบเดี่ยว":"มอบหมายเคสแบบกลุ่ม"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(props.type!=="single")&&(
            <Grid item xs={12}>
              <Autocomplete
                id="groupCases"
                fullWidth
                freeSolo={true}
                options={groupsList}
                value={formData.groupId}
                getOptionLabel={(option: any) => option.label}
                size="small"
                style={{ height: "100%" }}
                onChange={(_, value: any) =>handleChange({ target: { name: "groupId", value: value } })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="เลือกกลุ่ม"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={9}>
            <Autocomplete
              id="searchCases"
              fullWidth
              multiple
              disableCloseOnSelect
              freeSolo={true}
              options={questionairesList}
              value={questionairesChoose}
              getOptionLabel={(option: any) => option.label}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </React.Fragment>
              )}
              size="small"
              style={{ height: "100%" }}
              onChange={(_, value: any) => setQuestionairesChoose(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="แบบสอบถาม"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              onClick={addQuestionaire}
            >
              เลือกแบบสอบถาม
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <GroupCasesList casesList={casesList} removeGroupCase={removeGroupCase}/>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          ยกเลิก
        </Button>
        <ButtonLoading
          variant="contained"
          color="primary"
          onClick={saveAssign}
          loading={globalState.activity.insertGroup.isFetching}
        >
          บันทึก
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}
