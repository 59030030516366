/* eslint-disable @typescript-eslint/no-unused-vars */
import { GET_TEAMSUSERS } from '../../actions';
import Actions from '../../actions/teams';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { call, fork, put,takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

const getTeamsusers = fetchEntity.bind(null, Actions.getTeamsUsers, api.getTeamsUsers);
const getTeamusersTambon = fetchEntity.bind(null, Actions.getTeamsUsersTambon, api.getTeamusersTambon);

function* callFirstAction(action: any) {
  yield call(
    getTeamsusers,{data: action.getTeamsusers.params},
    {
      onSuccess: (success: any) => {
        const test = call(actionGetTeamusersTambon, action, success);
        return test;
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('getTeamsusers FAILURE',4000));
      },
    }
  );
}

function* actionGetTeamusersTambon(action: any, userlist:Array<Record<string,any>>) {
  if(userlist.length>0){
    const departmentList = userlist.map((u:any)=>u.department.hcode);
    const departments = _.uniq(departmentList);
    action.getHospitalInfo.params.where.hos_id.inq = departments;
    yield call(
      getTeamusersTambon, {data: action.getHospitalInfo.params, userlist:userlist},
      {
        onSuccess: (success: any) => {
          const hosinfo = success;
          const newUserlist: any = [];
          if(hosinfo && hosinfo!==null &&hosinfo.length>0){
            userlist.forEach((user:any)=>{
              const tambonInfo= hosinfo.filter((hos:any)=>user.department.hcode===hos.hos_id)[0];
              if(!_.isEmpty(tambonInfo)){
                user["tambon"]= {
                  tambon_id: tambonInfo.tambol.addressid,
                  tambon_name: tambonInfo.tambol.name
                }
              }
              newUserlist.push(user);
            });
          }
          return newUserlist;
        },
        onFailure: () => put(snackbarFailure('getTeams failed',1500))
      }
    );
  }else{
    yield put(snackbarSuccess('userlist empty',4000))
  }
}

function* watchFirstRequest() {
  yield takeLatest(GET_TEAMSUSERS.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
