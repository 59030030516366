/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from '../const';
import { FIRST_ACTION, SECOND_ACTION, THIRD_ACTION, FOURTH_ACTION } from '../index';

export const firstAction = {
  request: (payload: any) => action(FIRST_ACTION.REQUEST, { data: payload }),
  success: (response: any) => action(FIRST_ACTION.SUCCESS, { data: response }),
  failure: (error: any) => action(FIRST_ACTION.FAILURE, { error }),
};

export const secondAction = {
  request: (payload: any) => action(SECOND_ACTION.REQUEST, { data: payload }),
  success: (response: any) => action(SECOND_ACTION.SUCCESS, { data: response }),
  failure: (error: any) => action(SECOND_ACTION.FAILURE, { error }),
};

export const thirdAction = {
  request: (payload: any) => action(THIRD_ACTION.REQUEST, { data: payload }),
  success: (response: any) => action(THIRD_ACTION.SUCCESS, { data: response }),
  failure: (error: any) => action(THIRD_ACTION.FAILURE, { error }),
};

export const fourthAction = {
  request: (payload: any) => action(FOURTH_ACTION.REQUEST, { data: payload }),
  success: (response: any) => action(FOURTH_ACTION.SUCCESS, { data: response }),
  failure: (error: any) => action(FOURTH_ACTION.FAILURE, { error }),
};
