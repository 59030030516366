const authHeader = () => {
  const user = JSON.parse(localStorage.getItem('user')) || {};

  if (user && user.id) {
    return { Authorization: 'Bearer ' + user.id }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
};

const getToken = () => {
  const user = JSON.parse(localStorage.getItem('OSCC')) || {};
  return { Authorization: user.id };
};


const setHeader = () => {
  const user = JSON.parse(localStorage.getItem('OSCC')) || {};
  return { Authorization: user.id, 'content-type': 'multipart/form-data' };
};

const API_URL = process.env.REACT_APP_API_URL;
const PORT = process.env.REACT_APP_API_PORT;
const API_URL_FULL = API_URL + ':' + PORT + '/api/';
const SYSTEM_TOKEN = process.env.REACT_APP_SYSTEM_TOKEN;
export { authHeader, getToken, setHeader, API_URL, PORT, API_URL_FULL, SYSTEM_TOKEN };
