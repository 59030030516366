import React,{ Suspense, useEffect } from 'react';
import { Switch, Route, useHistory, BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import clsx from 'clsx';
import { getCurrentUser } from './services/auth.service';
import { useIdleTimer } from 'react-idle-timer';
import { ConfirmProvider } from 'material-ui-confirm';
import { DialogProvider } from './services/dialog/ModalProvider';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { mainRoute } from './routes/index';
import Appbar from './layout/Appbar';
import Sidebar from './layout/Sidebar';
import Notifier from './components/Notifier';
import useGlobal from './store';
import { history } from './store/redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
// import * as AST from './services/AutoSetting' ;

// const appHistory = createBrowserHistory({ basename: '/' });
const drawerWidth = 240;
const theme = createMuiTheme();
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    '& .MuiToolbar-root h6': { width: '100%' },
    '& .navMenu': {
      position: 'absolute',
      right: '24px',
    },
    '& .MuiAppBar-colorSecondary': {
      color: '#fff',
      backgroundColor: '#2e2e37',
    },
    '& a.navbar-brand, .navMenu a': { color: '#fdfeff', textDecoration: 'none' },
    '& a.navbar-brand:hover, .navMenu a:hover': { color: '#e0e0e0', textDecoration: 'none' },
    '& .MuiDrawer-root a': { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    '& .MuiDrawer-root a:hover': { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    // "& .MuiButton-root:hover":{backgroundColor:'#ffffff0f'},
    '& .MuiButton-root': { marginLeft: '5px' },
    '& .MuiDialog-root': {
      position: 'fixed',
      zIndex: 1300,
      inset: '0px',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  popupMenuLink: {
    '& a': { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    '& a:hover': { textDecoration: 'none' },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 40,
    marginRight: '10px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  avatarSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
const Loader = () => (
  <div className='App'>
    <div>loading...</div>
  </div>
);
const App = () => {
  const [globalState, globalActions] = useGlobal();
  const classes = useStyles();
  const redirect = useHistory();
  const handleOnIdle = () => {
    if (globalState.loginStatus) {
      // redirect.push('/logout');
    }
  };
  const { reset, pause, resume } = useIdleTimer({
    timeout: 1000 * 60 * process.env.REACT_APP_USER_IDLE_TIMEOUT,
    stopOnIdle: true,
    startOnMount: false,
    eventsThrottle: 1000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // const [openBackdrop, setOpenBackdrop] = useState(false);

  // const preAutoSetting = async () => {
  //   let todayDate = localStorage.getItem('todayDate');
  //   let date = new Date();
  //   var d =
  //     date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
  //   let todayFirstAccess = true;
  //   if (typeof todayDate !== 'undefined') {
  //     if (todayDate && todayDate !== '') {
  //       if (d === todayDate) {
  //         todayFirstAccess = false;
  //       }
  //     }
  //   }
  //   if (todayFirstAccess === true) {
  //     setOpenBackdrop(true);
  //     let runFromSystemSetting = false;
  //     console.log('RUN AutoSetting : ครั้งแรกของวัน');
  //     let x = await AST.AutoSetting(getCurrentUser(), redirect, runFromSystemSetting);
  //     console.log('RUN AutoSetting : ครั้งแรกของวัน : ', x);
  //     setOpenBackdrop(false);
  //     if (x === true) {
  //       window.location = '/';
  //     }
  //     // setTimeout(() => {
  //     //   setOpenBackdrop(false);
  //     // }, 30000);
  //   } 

  //   localStorage.setItem('todayDate', d);
  // };

  useEffect(() => {window.process = {...window.process};}, []);
  useEffect(() => {
    if (globalState.loginStatus) {
      resume();
      reset();
      // let cu = getCurrentUser();
      // if (parseInt(cu.user.userType.userTypeId) === 3 && ['CM', 'AdminTeams', 'Teams'].indexOf(cu.user.role) > -1) {
        // preAutoSetting(cu);
      // } 
    } else {
      pause();
    }
  }, [globalState.loginStatus,pause,reset,resume]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if (Object.keys(globalState.currentUser).length > 0) {
        globalActions.setUserRole(globalState.currentUser.user.role);
        globalActions.changeLoginStatus(true);
        if (globalState.currentUser.user.picture !== null &&typeof globalState.currentUser.user.picture !== 'undefined') {
          globalActions.setIsLineLogin(true);
        } else {
          globalActions.changeLoginStatus(true);
        }
      } else {
        let currentUser = getCurrentUser();
        if (currentUser != null) {
          globalActions.setCurrentUser(currentUser);
          globalActions.setUserRole(currentUser.user.role);
        }
      }
    }
    return ()=>{mounted = false};
  }, [globalState.currentUser,globalActions]);

  return ( 
    <ConnectedRouter history={history}>
      <Suspense fallback={<Loader />}>
        <div className={classes.root}>
          <Appbar />
          <Sidebar />
          <Notifier />
          <ThemeProvider theme={theme}>
            <DialogProvider>
              <ConfirmProvider>
                <main className={clsx(classes.content, { [classes.contentShift]: globalState.drawerOpen })}>
                  <div className={classes.toolbar} />
                    <Switch>
                      {mainRoute.map((route, index) =>
                          (route.roles.includes(globalState.userRole) && route.component!==null) && (
                            <Route
                              key={index}
                              exact
                              path={route.path}
                              component={route.component}
                              // render={(props) => <route.component {...props} />}
                            >
                              {/* <route.component/> */}
                            </Route>
                          )
                      )}
                    </Switch>
                </main>
              </ConfirmProvider>
            </DialogProvider>
          </ThemeProvider>
        </div>
        <Dialog disableBackdropClick={true} className={classes.backdrop} open={false}>
          <DialogContent>
            <div style={{ textAlign: 'center', padding: 10 }}>
              <CircularProgress color='inherit' />
              <div>กำลังตั้งค่าข้อมูลสำหรับหน่วยบริการ กรุณารอ...</div>
            </div>
          </DialogContent>
        </Dialog>
      </Suspense>
    </ConnectedRouter>
  );
};

export default App;