/* eslint-disable @typescript-eslint/no-unused-vars */
import { UPDATE_CASES } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);

function* firstAction(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {
        return call(actionGetGroups, action);
      },
      onFailure: () => put(snackbarFailure('updateCases failed',1500))
    }
  );
}

function* actionGetGroups(action: any) {
  yield call(
    getGroups, { data: action.getGroups.params },
    {
      onSuccess: (success: any) => {    
        return put(snackbarSuccess(action.updateCases.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getGroups failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(UPDATE_CASES.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
