import { UPDATE_CASES } from "../../actions";
import { AnyAction } from "redux";
import { UpdateCase } from "../../types/activity";

const initialState:UpdateCase = {
  isFetching: false,
  isSuccess:false,
  data: {}
};

export default function updateCases(
  state = initialState,
  action: AnyAction
): UpdateCase {
  switch (action.type) {
    case UPDATE_CASES.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case UPDATE_CASES.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case UPDATE_CASES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
