import { GridRowData } from "@material-ui/data-grid";
import { callGet, callPost, callDelete, callPatch } from "../request";
import { getToken } from "./getToken";
const token = getToken();

export const getQuestionnaires = async (payload: any={}) => {
	// let params: any = {
	//   access_token: token,
	//   filter: {
	// 	order: "questionnaire_code asc",
	//   },
	// };
    let params: any = {
        access_token: token,
        filter: {
            "fields":{
              "id":true,
              "questionnaire_group": true,
              "questionnaire_repeat": true,
              "questionnaire_view": true,
              "questionnaire_manage": true,
              "questionnaire_code":true, 
              "questionnaire_name":true, 
              "questionsList":true,
              "questionnaire_datagrid_column":true
            },
            "where":{
            "questionnaire_type": "PLH2"
            },
            "include":{
            "relation": "questionnaire_answer",
            //   "scope": {
            //     "where": {
            //       [(!mixState.group)?"case_id":"group_id"] : mixState.data.id
            //     }
            //   }
            },
            "order": "questionnaire_code asc"
        }
      }
	
	if(payload.where && payload.where!==null){
	  params.filter["where"] = payload.where;
	}
  
	if (payload.pagination) {
	  params.filter["limit"] = payload.pagination.limit;
	  params.filter["skip"] = payload.pagination.skip;
	}

	const response = await callGet(`questionnaires`, token , params);
  if(!response){
    return {data:[],totalPage:0};
  }else{
    if(response.response){
      return { 
        data : response.response.data as readonly GridRowData[], 
        totalPage: response.response.headers["x-total-count"] as number
      };
    }
  }
};
export const getQuestionnairesAnswer = async (groupId: string,tokenSelf?:string) => {
  
  let params: any = {
    access_token: (token)?token:tokenSelf,
    filter: {
      fields: {
        id: true,
        questionaire_id: true,
        answer: true,
      },
      where:{
        group_id: groupId
      },
      include: {
        relation: "questionnaire",
        scope:{
          fields: {
            questionsList: true
          }
        }
      }
    }
  };
  if(!groupId && groupId ===""){
    return {status: false, message:"GroupId Undefined"};
  }
	const resposne = await callGet(`questionnaire_answers`, token , params);
  // console.log(JSON.stringify(params));
  // console.log(params);
  // console.log(resposne);
  return resposne;
};