import { DELETE_ASSIGN } from "../../actions";
import { AnyAction } from "redux";
import { DeleteAssign } from "../../types/activity";

const initialState:DeleteAssign = {
  isFetching: false,
  data: {}
};

export default function deleteAssign(
  state = initialState,
  action: AnyAction
): DeleteAssign {
  switch (action.type) {
    case DELETE_ASSIGN.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case DELETE_ASSIGN.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case DELETE_ASSIGN.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
