/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { UPDATE_CASE } from "../index";

const updateCase = {
  request: (payload:any) => action(UPDATE_CASE.REQUEST, {id:payload.id, data:payload.data}),
  success: (response: any) => action(UPDATE_CASE.SUCCESS, { data: response }),
  failure: (error: any) => action(UPDATE_CASE.FAILURE, { error }),
};

export default updateCase;