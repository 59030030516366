import React, { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';

function ThaidDialog(props:any) {
  const thaidAuthURL = `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=${process.env.REACT_APP_DEV_THAID_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DEV_THAID_CALLBACK}&scope=${process.env.REACT_APP_THAID_SCOPE}&state=${process.env.REACT_APP_THAID_STATE}`
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    console.log("Dialog open");
    if(props.open){
      if (iframeRef.current) {
        console.log(iframeRef.current);
        console.log(iframeRef.current.src);
      }
    }
  }, [props.open]);

  return (
    <Dialog 
      fullWidth
      maxWidth={"sm"}
      disableBackdropClick={true} 
      open={props.open||false}
      >
      <DialogTitle id='alert-dialog-title'>ThaiD Login</DialogTitle>
      <DialogContent style={{height:"100vh", overflow:"hidden"}}>
        <iframe 
          ref={iframeRef}
          src={thaidAuthURL} 
          scrolling="no"
          style={{width:"100%", height:"-webkit-fill-available", overflow:"hidden", border: "none"}}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
          >
        </iframe>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={()=>props.setOpen(false)}>
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ThaidDialog;