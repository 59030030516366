import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

const LS: string | null = localStorage.getItem('OSCC');
const TOKEN: string = (LS) && JSON.parse(LS)["id"];
const SYSTEM_TOKEN  = process.env.REACT_APP_SYSTEM_TOKEN||"";
const API_URL       = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api`;

class CustomError extends Error {
  statusCode: number | undefined;
  constructor(error: any) {
    if (axios.isAxiosError(error))  {
      super(error.response?.data?.error?.message || error.response?.statusText || error.message);
      if (error.response?.status === 401) {
        super("Unauthorized");
        console.log("CustomError -> Unauthorized : ",error.toJSON());
      }
    }else{
      super(error.message);
    }
    this.name = this.constructor.name;
    this.statusCode = error.response?.status || 500;
    Error.captureStackTrace(this, this.constructor);
  }
}

const AXIOS: AxiosInstance = axios.create();
AXIOS.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: Error|AxiosError) => {
    if (axios.isAxiosError(error))  {
      if (error.response?.status === 401) {
        console.log(error.toJSON());
        return Promise.reject(error.toJSON());
        // localStorage.removeItem("OSCC");
        // window.location.replace('/login');
      }
    } 
    return Promise.reject(error);
  }
);

const getToken = () => {
  const LS: string | null = localStorage.getItem('OSCC');
  const TOKEN: string = (LS) && JSON.parse(LS)["id"];
  return TOKEN;
}

const queryParams = (obj: Record<string, any>, parentKey = ''): string => {
  return Object.keys(obj).map(key => {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return queryParams(value, newKey);
    } else {
      return `${encodeURIComponent(newKey)}=${encodeURIComponent(value)}`;
    }
  }).join('&');
};

export {
  TOKEN,
  SYSTEM_TOKEN ,
  API_URL,
  AXIOS,
  CustomError,
  queryParams,
  getToken
}