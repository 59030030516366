/* eslint-disable react-hooks/exhaustive-deps */
import React,{FC, useCallback, useEffect,useMemo,useState} from 'react';
import { Box, Button, ButtonGroup, createStyles, Fab, Grid,makeStyles,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Theme,Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Questionaire } from '../../types/activity';
import { useForm, SubmitHandler, UseFormRegister, UseFormSetValue, Control, UseFormHandleSubmit } from "react-hook-form";
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/activity';
import ButtonLoading from '../../components/custom_components/ButtonLoading';
import ScrollTop from './helper/goToTop';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Skeleton from '@material-ui/lab/Skeleton';
import useDeepCompareEffect from 'use-deep-compare-effect';
import GenTextfield from './questionair_helper/GenTextfield';
import GenTextarea from './questionair_helper/GenTextarea';
import GenRadioGroup from './questionair_helper/GenRadioGroup';
import GenDatePicker from './questionair_helper/GenDatePicker';
import GenTimePicker from './questionair_helper/GenTimePicker';
import GenCheckbox from './questionair_helper/GenCheckbox';
import moment from 'moment';
import 'moment/dist/locale/th';
import _, { sortedLastIndex } from 'lodash';
import { getQuestionnairesAnswer } from '../../services/api/questionnairs';
import { getToken } from '../../services/api/getToken';
const useStyles = makeStyles((_theme:Theme) =>
  createStyles({
    table: {
      borderRadius: "7px",
      border: "3px solid rgb(0, 0, 0)",
      marginTop: "5px"
    },
    row: {
      marginTop: ".8rem",
      borderBottom: "3px solid rgb(0, 0, 0)",
      padding: "5px 5px .8rem 10px"
    },
    groupHeader:{
      fontWeight:'bold',
    },
    radio:{
      marginBottom: 0
    },
    newTable: {
      minWidth: 650,
      borderCollapse: 'collapse'
    },
    newTableCell:{
      border: '1px solid #dddddd',
      padding: '4px',
      textAlign:'center'
    },
    submitBtn: {
      height: "100%",
      width: "50%",
      margin: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }),
);

interface stateType {
  groupData: Record<string,any>;
  group: boolean;
  coach?:boolean;
  questionaire_id: string;
  questionaire_answer?: number;
  data: Record<string,any>;
}
interface IGenInput {
  key: number;
  name: any;
  answersList: Array<Record<string,any>>;
  disable: boolean;
  uForm: {
    register: UseFormRegister<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    setValue: UseFormSetValue<any>;
    control: Control<any, any>;
  };
}
interface IGenInputNew {
  key: number;
  name: any;
  label: string;
  itemValue: number|string;
  placeholder?: string;
  disable: boolean;
  uForm: {
    register: UseFormRegister<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    setValue: UseFormSetValue<any>;
    control: Control<any, any>;
  };
}
const TakeQuestionnair: FC = () => {
  moment().locale('th')
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation<stateType>();
  const questionnaire_type = (state.group) ? state.data.groupQuestionnaireType: 'PLH2';
  const { register, handleSubmit, setValue,getValues, control} = useForm<any>();

  const globalState = useSelector((state: RootState) => state);
  const questionaireList:Questionaire = globalState.activity.questionaireList;
  const userinfo: any = globalState.auth.user.response.user||{};
  const role: string = globalState.auth.user.response.user.role||"";
  const roles = useMemo(()=>[...new Set( userinfo?.teams?.map((r:Record<string,string>)=>r.role) )],[userinfo]) // Multiple Role
  const loadingGet = globalState.activity.questionaireList.isFetching;
  const loadingUpdate = globalState.activity.updateAnswer.isFetching;
  const loadingInsert = globalState.activity.insertAnswer.isFetching;
  const groupListTeams = globalState.activity.groupListTeams.data;
  const [startEffect, setStartEffect] = useState(true);
  const [loadingPage,setLoadingPage]=useState(true);
  const [disabledInput,setDisabledInput] = useState<boolean>(true);
  const [oldAnswer, setOldAnswer] = useState<Array<Record<string,any>>|Record<string,any>>([]);
  const [formElements, setFormElements] = useState<any>(null);
  const [qName,setQname] = useState("");
  // console.log(state);
  const getStateTeamcode=()=>{
    // เผื่อกรณี User อยู่หลายทีม
    // ถ้าเป็นการทำแบบสอบถามเคสเดี่ยว 
    // ให้หา teamCode จาก Case ให้ได้ก่อน
    // โดย match teamCode ของ Case และ User
    // ให้เอา teamCode จาก osccperson_assign[0].teams.teamCode
    const caseTeamCode = state.data.osccperson_assign[0]?.teams.teamCode;
    const userTeams = globalState.auth.user.response.user.teams;
    const userTeamcode = userTeams.filter((f:any)=>f.teamCode===caseTeamCode).map((m:any)=>m.teamCode)[0];
    
    if(state.group){
      if(state.data.teams){
        return state.data.teams.teamCode;
      }else if(state.data.osccTeamId){
        return state.data.osccTeamId[0];
      }else{
        return userTeamcode;
      }
    }else{
      
      if(state.groupData?.teams){
        return state.groupData?.teams?.teamCode;
      }else if(state.data.osccTeamId){
        return state.data.osccTeamId[0];
      }else{
        return userTeamcode;
      }
    }
  }

  const onSubmit: SubmitHandler<any> = (awnser:any) => {
    const newAwnser = {...awnser};
    // console.log(newAwnser);
    Object.keys(newAwnser).forEach(key=>{
      if(key!=="id"){
        if(typeof newAwnser[key]==="object"){newAwnser[key]=newAwnser[key].format("HH:mm")}
        const isBoolean = Boolean(newAwnser[key]);
        if(!isBoolean){newAwnser[key] = null;}
        // console.log("newAwnser[key]",key,newAwnser[key]);
        if(newAwnser[key]!==null && typeof newAwnser[key]==="string"){
          newAwnser[key] = newAwnser[key].replace(/[\r\n\t]/g,'').replace(/  +/g, ' ').trim();
        }
      }
    });

    const teamCode = getStateTeamcode();
    const data = questionaireList.data[0];
    if(teamCode && teamCode !==null){
      if(data.questionnaire_answer && data.questionnaire_answer.length===0){ // เพิ่มคำตอบแบบสอบถาม
        const params = {
          insert: {
            data: {
              assigned_id: (!state.group)?state.data.osccperson_assign[0].id:state.data.osccperson_assign.filter((f:any)=>f.type==="group").map((m:any)=>m.id)[0],
              questionaire_id:state.questionaire_id,
              case_id: (!state.group)?state.data.id:null,
              group_id: (!state.group)?state.data.groupId:state.data.id,
              answer: (data.questionnaire_repeat===true)?[newAwnser]:newAwnser,
              answer_respondent: {
                teamCode: teamCode,
                userid: userinfo.id , 
                fullname: userinfo.fullname
              },
              answer_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
            },
            successMsg:"บันทึกคำตอบแบบสอบถามเรียบร้อย"
          },
          get: {
            param: {
              questionnaire_type: questionnaire_type,
              questionnaire_group: state.group
            },
            state: state
          }
        };
        console.log("INSERT",params);
        dispatch(Actions.InsertAnswer.request(params));
      }else{ // แก้ไขคำตอบแบบสอบถาม
        let params: Record<string,any> = {};
        if(data.questionnaire_repeat){ // แบบสอบถามแบบทำซ้ำใช้แบบสอบถามเดิมมีหลายคำตอบ answer เป็น array
          const newAwnserArray: any = (oldAnswer && oldAnswer.length>0)?oldAnswer:[];
          if(typeof state.questionaire_answer !== "undefined"){ // แก้ไขคำตอบเดิม 
            newAwnserArray[state.questionaire_answer as number] = newAwnser;
          }else{ // เพิ่มคำตอบ
            newAwnserArray.push(newAwnser);
          }
          params = {
            update: {
              id: data.questionnaire_answer[0].id,
              data: {
                answer: _.sortBy(newAwnserArray, ['CRF_q1'],['asc']),
                answer_respondent: {
                  // teamCode: userinfo.osccteams.filter((c:any)=>(c.teamName)&&c.teamName.includes("ทีมพื้นที่")).map((m:any)=>m.teamCode)[0], 
                  teamCode: (state.group)?state.data.teams.teamCode:state.data.osccperson_assign[0].teams.teamCode,
                  userid: userinfo.id , 
                  fullname: userinfo.fullname
                },
                answer_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
              },
              successMsg:"แก้ไขคำตอบแบบสอบถามสำเร็จ"
            },
            get: {
              param: {
                questionnaire_type: questionnaire_type,
                questionnaire_group: state.group
              },
              state: state
            }
          }
        }else{ // แบบสอบถามแบบไม่ทำซ้ำ ใช้แบบสอบถามใหม่ไปเรื่อยๆมีคำตอบเดียว answer เป็น  {}
          params = {
            update: {
              id: data.questionnaire_answer[0].id,
              data: {
                answer: newAwnser,
                answer_respondent: {
                  teamCode: teamCode,
                  userid: userinfo.id , 
                  fullname: userinfo.fullname
                },
                answer_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
              },
              successMsg:"แก้ไขคำตอบแบบสอบถามสำเร็จ"
            },
            get: {
              param: {
                questionnaire_type: questionnaire_type,
                questionnaire_group: state.group
              },
              state: state
            }
          }
        }
        // console.log("UPDATE",params);
        dispatch(Actions.UpdateAnswer.request(params));
      }
    }else{
      alert("มีปัญหาบางอย่างเกี่ยวกับ Teamcode กรณีติดต่อผู้ดูแลระบบ");
    }
  }

  const genInput= useCallback((key:number,item:any,disableInputs:boolean) => {
    const params: IGenInput = {
      key: key,
      name: item.inputName,
      answersList: item.answersList,
      disable: disableInputs,
      uForm: { register, handleSubmit, setValue, control }
    };
    switch(item.inputType){
      case 'text' : return <GenTextfield  data={params}/>;
      case 'textarea' : return <GenTextarea data={params}/>;
      case 'radio' :  return <GenRadioGroup data={params}/>;
      case 'date' :  return <GenDatePicker data={params}/>;
      case 'time' :  return <GenTimePicker data={params}/>;
      default : return <></>;
    }
  },[control, handleSubmit, register, setValue]);

  
  interface NestedObject {[key: string]: any;}
  function searchRef(allAnswer: NestedObject[], ref: string, inputName:string, currentAnswer: NestedObject): Array<NestedObject> {   
    const returnData:Array<Record<string,any>>=[];
    const hasValue = currentAnswer.hasOwnProperty(inputName) 
      && currentAnswer[inputName] !== null 
      && currentAnswer[inputName] !== undefined 
      && currentAnswer[inputName] !== '';
    if(!hasValue){
      for(const item of allAnswer){
        const structure: NestedObject[] = item.questionnaire.questionsList;
        const refAnswer: NestedObject   = item.answer;
        // console.log("structure",structure);
        // console.log("refAnswer",refAnswer);
        for(const questionsList of structure){
          const key: string = questionsList.inputName;
          const value: string = refAnswer[key];
          if (_.isObject(value)) { //ถ้าวนมาเจอ object ให้ทำซ้ำฟังก์ชั่นใหม่
            // console.log("เจอ Object วนซ้ำอีก");
            return searchRef(allAnswer as NestedObject[], ref, inputName, currentAnswer);
          }
          if(questionsList.ref===ref){
            returnData.push({[key]: value});
          }
        };
      };
    }
    // console.log("returnData",returnData);
    return returnData;
  };
  const refData: Record<string,any> = {};
  const genRefData = useCallback(async()=>{
    const defaultValues: any = [];
    refData["user"]= [{fullname:userinfo.fullname}];
    // console.log("questionaireList",questionaireList);
    if(!_.isEmpty(questionaireList.data[0]) && questionaireList.data[0].id === state.questionaire_id){
      // console.log("state",state);
      if(state.group){
        refData["teamMembers"]=state.data.teamMembers.filter((f:any)=>{
          for(const t of f.osccteams){
            return (t.teamCode === state.data.teams.teamCode)
          }
        }).map((m:any)=>({fullname:m.fullname, hos_fullname:m.department.hos_fullname}));
        const questionsnairs = questionaireList.data[0].questionsList || questionaireList.data[0].questionnaire_layout; // ข้อมูลโครงสร้างแบบสอบถาม
        if(questionaireList.data[0].questionnaire_layout){// แบบสอบถามแบบใหม่ PARe
          for(const layoutGroup of questionsnairs){ // วนกลุ่ม layout
            for(const row of layoutGroup.rows){ // วนแถว
              for(const col of row.cols){ // วนคอลัมน์
                if(col.type!=="label"){ // ไม่ใช่ label
                  if(col.defaultValue){ // มี defaultValue
                    col.defaultValue["inputName"]=col.name;
                    defaultValues.push(col.defaultValue);
                  }
                }
              } 
            }
          }
        }else{
          for(const questionsnair of questionsnairs){
            if(questionsnair.groupQuestionsList){
              for(const groupQuestionsList of questionsnair.groupQuestionsList){
                const { defaultValue, inputName }:NestedObject = groupQuestionsList;
                if(defaultValue){
                  defaultValue["inputName"]=inputName;
                  defaultValues.push(defaultValue);
                }
              }
            }else{
              const { defaultValue, inputName }:NestedObject = questionsnair;
              if(defaultValue){
                defaultValue["inputName"]=inputName;
                defaultValues.push(defaultValue);
              }
            }
          }
        } 
        // console.log(refData);
        // console.log(defaultValues);
        for(const ref of defaultValues){
          const { fromArray, index, key, inputName } = ref;
          const item = refData[fromArray][index];
          if(item){
            const value = item[key];
            if(!getValues(inputName)){
              setValue(inputName,value);
            }
          }
        }
      }else{
        groupListTeams.filter((g:any)=>g.id===state.data.groupId).map((m:any)=>{
          refData["teamMembers"] = m.teamMembers.map((mm:any)=>({fullname:mm.fullname, hos_fullname:mm.department.hos_fullname}));
          return null;
        });
        const questionsnairs = questionaireList.data[0].questionsList || questionaireList.data[0].questionnaire_layout;
        for(const questionsnair of questionsnairs){
          if(questionsnair.groupQuestionsList){
            for(const groupQuestionsList of questionsnair.groupQuestionsList){
              const { defaultValue, inputName }:NestedObject = groupQuestionsList;
              if(defaultValue){
                defaultValue["inputName"]=inputName;
                defaultValues.push(defaultValue);
              }
            }
          }else{
            const { defaultValue, inputName }:NestedObject = questionsnair;
            if(defaultValue){
              defaultValue["inputName"]=inputName;
              defaultValues.push(defaultValue);
            }
          }
        }
        // console.log(refData);
        // console.log(defaultValues);
        for(const ref of defaultValues){
          const { fromArray, index, key, inputName } = ref;
          if(refData[fromArray]){
            const item = refData[fromArray][index];
            if(item){
              const value = item[key];
              if(!getValues(inputName)){
                setValue(inputName,value);
              }
            }
          }
        }
      }
    }
  },[questionaireList.data,state,setValue,getValues]);

  const getQN = useCallback(()=>{    
    let param: any = {
      "fields":{
        "id":true,
        "questionnaire_group": true,
        "questionnaire_repeat": true,
        "questionnaire_view": true,
        "questionnaire_manage": true,
        "questionnaire_code":true, 
        "questionnaire_name":true, 
        "questionsList":true,
        "questionnaire_datagrid_column":true,
        "questionnaire_layout": true
      },
      "where":{
        "id": state.questionaire_id
      },
      "include":{
        "relation": "questionnaire_answer",
        "scope": {
          "where": {[(state.group)?"group_id":"case_id"]: (state.group)?state.data.id:state.data.id}
        }
      }
    }
    if(state.data.caseStatus==="waitConclusion"){
      param.include.scope.where = {
        or: [
          {case_id: state.data.id},
          {group_id: state.data.groupId}
        ]
      }
    }
    // console.log(param);
    dispatch(Actions.GetQuestionnaire.request(param));
  },[dispatch, state.data.id, state.group, state.questionaire_id]);

  const genInputNew= useCallback((key:number,item:any,disabled:boolean) => {
    const params: IGenInputNew = {
      key: key,
      name: item.name,
      label: item.label,
      itemValue: (item.itemValue)?item.itemValue:"",
      placeholder: item.placeholder,
      disable: disabled,
      uForm: { register, handleSubmit, setValue, control }
    };
    switch(item.type){
      case 'inputText' : return <GenTextfield  data={params}/>;
      case 'checkBox': return <GenCheckbox data={params}/>;
      case 'date' :  return <GenDatePicker data={params}/>;
      default : return <></>;
    }
  },[control, handleSubmit, register, setValue]);
  const genNewForm = ()=>{
    if(!_.isEmpty(questionaireList.data[0]) && questionaireList.data[0].id === state.questionaire_id){
      const Q=questionaireList.data[0];
      if(Q.questionnaire_layout && Q.questionnaire_layout.length>0){
        const layout = Q.questionnaire_layout;
        const allElements = layout.map((item:any,index:number)=>{
          const layoutGroup = item;
          if(layoutGroup.type==="table"){
            let headerElement=null;
            let rowElement=null;
            if(layoutGroup.header && layoutGroup.header.length>0){
              headerElement = layoutGroup.header.map((row:any, r:number)=>{
                return <TableRow key={"header-row"+r}>{
                    row.cols.map((col:any, c:number)=>{
                      return <TableCell className={classes.newTableCell} key={c} 
                        rowSpan={(col.rowSpan)&&col.rowSpan} 
                        colSpan={(col.colSpan)&&col.colSpan}
                        style={{width: (col.width) ? col.width : null }}
                        >
                        <Typography style={{fontSize:"1rem",textAlign:"center",fontWeight:"bold"}}>
                          <Trans components={{br: <br />}}>{col.content}</Trans>
                        </Typography>
                      </TableCell>
                    })
                  }
                </TableRow>
              })
            }
            if(layoutGroup.rows && layoutGroup.rows.length>0){
              // console.log(layoutGroup.rows);
              rowElement = layoutGroup.rows.map((row:any, r:number)=>{
                return <TableRow key={r}>{
                  row.cols.map((col:any,c:number)=>{
                    if(col.type && col.type==="label"){
                      return <TableCell key={c} className={classes.newTableCell} colSpan={(col.colSpan)&&col.colSpan}>
                          <Typography 
                            style={{
                              fontSize:"1rem",
                              paddingLeft: (col.textAlign==="left")? "1rem":0,
                              textAlign: (col.textAlign)?col.textAlign:"center"
                            }}>
                            {col.content}
                          </Typography>
                        </TableCell>;
                    }else{
                      return <TableCell key={c} className={classes.newTableCell} colSpan={(col.colSpan)&&col.colSpan}>{
                        genInputNew(c, col, false)
                      }</TableCell>;
                    }
                  })
                }</TableRow>;
              });
            }
            return <TableContainer key={"table"+index} component={Paper} style={{marginBottom:"2rem"}}>
              <Table className={classes.newTable} aria-label="simple table">
                { (headerElement) && (
                  <TableHead>{headerElement}</TableHead>
                )}
                <TableBody>{rowElement}</TableBody>
              </Table>
            </TableContainer>
          }else{
            return null;
          }
        });
        // const tableContainer = <TableContainer component={Paper}>
        //   <Table className={classes.newTable} aria-label="simple table">
        //     { (headers&&headers.length>0) && (
        //       <TableHead>{headers}</TableHead>
        //     )}
        //     <TableBody>{allRows}</TableBody>
        //   </Table>
        // </TableContainer>;
        const mainLayout = <>{allElements}</>
        setFormElements(mainLayout);
        setLoadingPage(false);
        setStartEffect(false);
      }
    }
  };
  const genForm = useCallback(async() => {
    // console.log("genForm");
    // if(questionaireList.data.length>0){
      if(!_.isEmpty(questionaireList.data[0]) && questionaireList.data[0].id === state.questionaire_id){
        const Q=questionaireList.data[0];
        const qname = "["+Q.questionnaire_code?.replace("_","&")+"] :"+Q.questionnaire_name;
        setQname(qname);
        
        let disableInputs = false;
        if(["waitConclusion","finish"].includes(state.data.caseStatus) || ["waitConclusion","finish"].includes(state.data.groupStatus)){
          disableInputs=true;
        }else{
          // If User Multiple Roles intersection questionnaire_manage then remove disabled input
          const intersect = ((_.intersectionBy(Q.questionnaire_manage, roles)).length>0);
          disableInputs = !intersect;
        }
        setDisabledInput(disableInputs);

        const questionsList = Q.questionsList;
        // console.log("questionsList",questionsList);
        if(questionsList && questionsList.length>0){
          const totalRows=questionsList.length;
          const formElement = questionsList.map((item:any,key:number)=>{
            
            if(!item.hasOwnProperty('groupDescription')){ // คำถามแบบเดี่ยว
              return  <Grid key={key} item xs={12} className={((key+1)<=totalRows)?classes.row:""}>
                        { (item.question) &&<Box><Trans components={{br: <br />}}>{item.question}</Trans></Box> }
                        <Box>{genInput(key,item,disableInputs)}</Box>
                      </Grid>
            }else if(item.hasOwnProperty('groupDescription')){ // คำถามแบบกลุ่ม
              return  <Grid key={key} item xs={12} className={((key+1)<=totalRows)?classes.row:""}>
                        <Grid container spacing={2}>
                          <Grid key={key} item xs={12} className={classes.groupHeader}>
                            {/* คำถาม */}
                            <Box><Trans components={{br: <br />}}>{item.groupDescription}</Trans></Box>
                          </Grid>
                          {
                            // คำตอบในรูปแบบต่างๆ
                            (item.hasOwnProperty('groupQuestionsList') && item.groupQuestionsList.length>0 )&& 
                              item.groupQuestionsList.map((i:any,k:number)=>{
                                return  <Grid key={k} item xs={12}>
                                          { (i.question) &&
                                            <Box style={{marginLeft:'1rem'}}>
                                              {/* คำตอบ */}
                                              <Trans components={{br: <br />}}>{i.question}</Trans>
                                            </Box>
                                          }
                                          <Box style={{marginLeft:(k<10)?'2rem':'2rem'}}>
                                            {/* สร้าง input ตามคอนฟิก */}
                                            { genInput(k,i,disableInputs) }
                                          </Box>
                                        </Grid>
                              })
                          }
                        </Grid>
                      </Grid>
            }else{
              return null;
            }
          });
          setLoadingPage(false);
          setFormElements(formElement);
          setStartEffect(false);
        }else{
          genNewForm();
        }
      }
    // }
  },[classes.groupHeader, classes.row, genInput, questionaireList.data, role, state.questionaire_id]);

  const genAnswer = useCallback(()=>{
    // console.log("questionaireList",questionaireList);
    if(questionaireList.data.length>0){
      if(!_.isEmpty(questionaireList.data[0]) && questionaireList.data[0].id === state.questionaire_id){
        const Q = questionaireList.data[0];
        // console.log(Q.questionnaire_answer);
        if(Q.questionnaire_answer && Q.questionnaire_answer.length>0){ // ข้อมูลคำตอบแบบสอบถาม
          setOldAnswer(Q.questionnaire_answer[0].answer);
          if(Q.questionnaire_repeat){ // แบบสอบถามประเภทตอบซ้ำ เก็บคำตอบได้หลายคำตอบต่อเคสหรือต่อกลุ่ม
            if(typeof state.questionaire_answer !== "undefined"){ // ถ้าเป็นการแก้ไขคำตอบเดิม (ส่งค่า key array มาจากบรรทัดที่กด icon ดินสอ)
              const answer = Q.questionnaire_answer[0].answer[state.questionaire_answer as number]; // ข้อมูลคำตอบของแถวที่กดไอค่อนดินสอ
              const newAnswer = Object.assign(answer); // สำเนา object
              Object.keys(newAnswer).forEach(function(key:any) { 
                if(typeof newAnswer[key] !== "undefined" && newAnswer[key]!==null){// คำตอบเดิมต้องมีค่า ห้าม undefined ห้าม null
                  if(newAnswer[key].length===5 && newAnswer[key].includes(":")){
                    // กรณีคำตอบเป็นเวลา ต้องได้ใส่ข้อมูลในรูปแบบ datetime object จึงต้องเติมวันที่เข้าไปด้วย
                    // เนื่องจาก Component MaterialUI Timepicker ต้องการ value เป็น datetime object (ใส่แต่เวลาไม่ได้)
                    setValue(key, moment( moment().format("YYYY-MM-DD")+" "+newAnswer[key]));
                  }else{
                    setValue(key,newAnswer[key]);
                  }
                }
              });
            }
          }else{ // ถ้าใช้แบบสอบถามแบบใครมัน ตอบไม่ซ้ำ
            // วนลูปเอาคำตอบใส่ใน useForm value ตามชื่อคีย์
            const answer = Q.questionnaire_answer[0].answer;
            Object.keys(answer).forEach(function(key:any) { 
              // console.log(key);
              if(typeof answer[key] !== "undefined" && answer[key]!==null){
                if(answer[key].length===5 && answer[key].includes(":")){
                  // กรณีคำตอบเป็นเวลา ต้องได้ใส่ข้อมูลในรูปแบบ datetime object จึงต้องเติมวันที่เข้าไปด้วย
                  // เนื่องจาก Component MaterialUI Timepicker ต้องการ value เป็น datetime object (ใส่แต่เวลาไม่ได้)
                  setValue(key, moment( moment().format("YYYY-MM-DD")+" "+answer[key]));
                }else{
                  setValue(key,answer[key]);
                }
              }
            });
          }
          // ค้นคำตอบจากแบบสอบถามอื่น โดยค่า ref
          genRefData();
        }else{
          // ค้นคำตอบจากแบบสอบถามอื่น โดยค่า ref
          genRefData();
        }
      }
    }
  },[questionaireList.data, setValue, state.questionaire_answer, state.questionaire_id])

  useEffect(() => { // ดึงข้อมูลแบบสอบถาม
    if(startEffect){getQN()}
    return ()=>{setStartEffect(false)}
  },[getQN, startEffect]);

  useDeepCompareEffect(()=>{ // ถ้าข้อมูลแบบสอบถามมีการเปลี่ยนแปลง เรียกฟังก์ชั่น genForm
    // console.log("ถ้าข้อมูลแบบสอบถามมีการเปลี่ยนแปลง เรียกฟังก์ชั่น genForm");
    // console.log(state.questionaire_id);
    // console.log(questionaireList.data);
    if(questionaireList.data.length===1){
      if(!_.isEmpty(questionaireList.data[0]) && questionaireList.data[0].id === state.questionaire_id){
        genForm();
      }
    }
  },[questionaireList.data, state.questionaire_id]);

  useEffect(()=>{ // ถ้าแบบฟอร์มพร้อมแล้ว เรียกฟังก์ชั่น genAnswer
    if(!_.isEmpty(formElements)){
      genAnswer();
      // console.log(formElements);
    }
  },[formElements, genAnswer]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} style={{padding:"0 1.5rem 2rem"}}>
        <Grid item xs={12} style={{justifyContent:"end",display:"flex",padding:0,margin:"1rem 0"}}>
          <ButtonGroup  id="back-to-top-anchor" size="large" disableElevation variant="contained" color="primary">
            <Button variant="outlined" color="primary" onClick={ ()=>history.push({pathname:"/questionnaire_list", state:state})}>กลับ</Button>
            <ButtonLoading variant="contained" color="primary" type="submit" disabled={disabledInput}
              loading={loadingInsert || loadingUpdate || loadingGet}>
                บันทึก
            </ButtonLoading>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} style={{padding: "12px 0"}}>
          <Typography variant="h5">
            {
              (loadingPage)? "" : qName
            }
          </Typography>
        </Grid>
        
        {(loadingPage) ? 
          <Grid item xs={12} style={{padding:0}}>
            <Grid container>
              <Skeleton variant="rect" width="100%" height={300}/>
              <Skeleton />
              <Skeleton width="60%" />
            </Grid>
          </Grid> : 
          <Grid item xs={12} style={{padding:0}}>
            {/* <Grid container spacing={3} className={classes.table}>
              { formElements }
            </Grid> */}
            {formElements}
          </Grid>
        }
        <Grid item xs={12} style={{justifyContent:"end",display:"flex",padding:0,margin:"1rem 0"}}>
          <ButtonGroup size="large" disableElevation variant="contained" color="primary">
            <Button variant="outlined" color="primary" className={classes.submitBtn} onClick={ ()=>history.push({pathname:"/questionnaire_list", state:state})}>กลับ</Button>
            <ButtonLoading variant="contained" color="primary" type="submit" disabled={disabledInput}
              loading={loadingInsert || loadingUpdate || loadingGet}>
                บันทึก
            </ButtonLoading>
          </ButtonGroup>
        </Grid>
      </Grid>
      <ScrollTop>
        <Fab color="primary" size="small" aria-label="กลับไปบนสุด" title="กลับไปบนสุด">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </form>
  );
}

export default TakeQuestionnair;