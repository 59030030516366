/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { getAll } from "../../services/UniversalAPI";
import { getCurrentUser } from '../../services/auth.service';
import Permission from "../../services/Permission";

const getImproveLevelData = async (dashboardFilter) => {
  const currentUser = getCurrentUser();
  const { role, changwat, amphur, hospcode, casestatusRole } = Permission("dashboard");
  let officerId = "";
  let userTeams = [];
  if(currentUser!=null){
    officerId = currentUser.userId;
    userTeams = (currentUser.user.teams!==undefined)?currentUser.user.teams.map((teams)=>{return teams.id;}):[];
  }
  const params = {
    filter: {
      fields: { name: true, osccpersonCount: true },
      counts: "osccperson"
    }
  };
  try {
    if (role === "R8") { // นับทั้งหมด

    } else if (role === "CH") { // นับเฉพาะจังหวัดตนเอง
      params.filter.countsWhere = [{ province_name: dashboardFilter.changwat.changwatname }];
    } else if (role === "HOS") { // นับเฉพาะหน่วยบริการตนเอง
      params.filter.countsWhere = [{ hcode: dashboardFilter.hospital.hos_id }];
    } else if (role === "OG") { // Organization
      params.filter.countsWhere = {and:[]};
      if(dashboardFilter.changwat.changwatname !== ""){
        params.filter.countsWhere.and.push({ province_name: dashboardFilter.changwat.changwatname });
      }else if(dashboardFilter.amphur.amphur_name !== ""){
        params.filter.countsWhere.and.push({ amphur_name: dashboardFilter.amphur.amphur_name });
      }else if(dashboardFilter.hospital.hos_id !== ""){
        params.filter.countsWhere.and.push({ hcode: dashboardFilter.hospital.hos_id });
      }else{
        params.filter.countsWhere = {};
      }
    } else if (role === "CM") { // นับเฉพาะในอำเภอตนเอง
      if(dashboardFilter.amphur.amphur_name!==""){
        params.filter.countsWhere = [{ amphur_name: dashboardFilter.amphur.amphur_name }];
      }
    } else if (role === "ATM") { // Admin Teams
      if(userTeams!==""){
        params.filter.countsWhere = [{ osccTeamId : { in: userTeams } }];
      }
    } else if (role==="TM") { // Teams
      if(officerId!==""){
        params.filter.countsWhere = [{ officerId : { in : [officerId] } }];
      }
    }
    // console.log(JSON.stringify(params.filter));
    // console.log("improveLevels",params);
    const response = await getAll(params, "improvelevels");
    // console.log(response);
    if (response.status === 200) {
      let improveLevel = {
        labels:[],
        improved:0,
        unchanged:0,
        worse:0
      };
      // console.log(response.data);
      response.data.map(function (currentValue, index) {
        let counts = (typeof currentValue.osccpersonCount !=='undefined' && currentValue.osccpersonCount !==null)?currentValue.osccpersonCount:0;
        if(["unchanged","Unchange"].includes(currentValue.name)){
          improveLevel.unchanged = counts;
        }else{
          improveLevel[currentValue.name] = counts;
        }
        
        return null;
      });
      
      improveLevel.labels[0]="ดีขึ้น : "+improveLevel.improved;
      improveLevel.labels[1]="คงเดิม : "+improveLevel.unchanged;
      improveLevel.labels[2]="แย่ลง : "+improveLevel.worse;
      // console.log(improveLevel);
      return improveLevel;
    }
  } catch (error) { console.log('error', error) }
};

export default getImproveLevelData