import { INSERT_TEAMS } from "../../actions";
import { AnyAction } from "redux";
import { TeamsCRUD } from "../../types/teams";

const initialState:TeamsCRUD = {
  isFetching: false,
  isSuccess: false,
  data: {}
};

export default function insertTeams(
  state = initialState,
  action: AnyAction
): TeamsCRUD {
  switch (action.type) {
    case INSERT_TEAMS.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        data: {}
      };
    }
    case INSERT_TEAMS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        data: action.data
      };
    }
    case INSERT_TEAMS.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: action.data
      };
    default:
      return state;
  }
}
