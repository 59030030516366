/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState,useCallback, useRef, MutableRefObject} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/teams';
import { Button, Divider, Drawer, Grid,Icon,TextField,Typography } from "@material-ui/core";
import {  useHistory, useLocation } from 'react-router-dom';
// import TeamLists from './teamsList';
// import DialogAdd from './teamsDialogAdd';

interface IProps {}
type TProps = IProps;

const Teams=()=>{
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const teamsList = globalState.teams.teamsList.data;
  
  const role = globalState.auth.user.response?.user.role;
  const amphur = globalState.auth.user.response?.user.amphur.amphur_id;

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tambon, setTambon] = useState("");
  const [filltered, setFilltered] = useState<Record<string,any>>({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const genParams = (filter:Record<string,any>)=>{
    let params : Record<string,any> = {and: []};
    Object.keys(filter).forEach((key:string)=>{
      switch(key){
        case 'changwat' : 
          (filter[key] && filter[key]!==null) && params.and.push({"amphur.amphur_id": {regexp: "^"+ filter[key]?.id }});
          break;
        case 'ampur' :
          (filter[key] && filter[key]!==null) && params.and.push({"amphur.amphur_id": filter[key]?.id});
          break;
        case 'tambon' : 
          (filter[key] && filter[key]!==null) && params.and.push({"tambon.tambon_id": filter[key]?.id});
          break;
        default: break;
      }
    });
    return params;
  }
  const getTeams = useCallback(async(filter:Record<string,any>) => {
    let params : Record<string,any> = {};
    if( filter.type && filter.type!==null && filter.type==="searchText" ){
      params = 
        {and:[
          {"amphur.amphur_id": amphur},
          {name:{regexp: filter.value}}
        ]};
    }else{
      if(role==="Coach" || role==="Assessor"){
        params=genParams(filter);
        if(params.and.length===0 || params.and.amphur_id==="" ){
          params.and.push({"amphur.amphur_id": amphur});
        }else{
          params.and.push({"amphur.amphur_id": amphur});
        }
      }else if(role==="CM"){
        params=genParams(filter);
        if(params.and.length===0 || params.and.amphur_id==="" ){
          params.and.push({"amphur.amphur_id": amphur});
        }else{
          params.and.push({"amphur.amphur_id": amphur});
        }
      }else if(role==="AdminTeams" || role ==="Teams"){
        params=genParams(filter);
        if(params.and.length===0  || params.and.amphur_id==="" ){
          params.and.push({tambol_id: tambon});
        }else{
          params.and.push({"amphur.amphur_id": amphur});
        }
      }else{
        
      }
    }
    const newParams = {
      where: params, 
      pagination: {
        limit: pageSize, 
        skip: currentPage * pageSize
      }
    };
    // console.log(JSON.stringify(newParams));
    setFilltered(filter);
    dispatch(Actions.getTeams.request(newParams));
    setLoading(false);
  },[filltered,currentPage]);

  const timer = useRef<NodeJS.Timeout|null>(null);
  useEffect(() => {
    if(searchText!==""){
      clearTimeout(timer.current as NodeJS.Timeout);
      timer.current = setTimeout(() => {
        getTeams({type:"searchText",value:searchText});
      },1000);
    }
    return () => clearTimeout(timer.current as NodeJS.Timeout);
  },[searchText]);

  useEffect(() => { // รันครั้งแรก
    let mounted = true;
    setLoading(true);
    setSearchText("");
    if(mounted){
      getTeams({});
    }
    return()=>{mounted = false};
  },[]);
  
  useEffect(() => { // ✅ ถ้าข้อมูลทีม มีการเปลี่ยนแปลงให้ปิด Dialog Add
    setShowModalAdd(false);
    setLoading(false);
  },[teamsList]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >ประเมินวิทยากร</Typography>
        {/* <Typography variant="h6" style={{marginBottom:"1.5rem"}}></Typography> */}
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {/* <TeamLists 
          loading={loading}
          filltered={genParams(filltered)}
          currentPage={currentPage} 
          pageSize={pageSize} 
          handlePageChange={handlePageChange}
        /> */}
      </Grid>
      {
        // (showModalAdd)&&<DialogAdd open={showModalAdd} setOpen={setShowModalAdd} loading={loading} setLoading={setLoading}/>
      }
    </Grid>
  );
}

export default Teams;