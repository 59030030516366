import * as Action from "../../actions";
import { AnyAction } from "redux";
import { filter } from "../../types/filter";

const initialState:filter = {
  isFetching: false,
  count: 0,
  data: []
};

export default function changwat(
  state = initialState,
  action: AnyAction
): filter {
  switch (action.type) {
    case Action.GET_CHANGWAT.REQUEST:{
      return {
        ...state,
        isFetching: true,
        data:[]
      };
    }
    case Action.GET_CHANGWAT.SUCCESS: {
      const newData = action.data.rows.map((item:any)=>{
        return {
          id: item.changwatcode,
          label: item.changwatname,
          value: item
        }
      });
      return {
        ...state,
        isFetching: false,
        data: newData
      };
    }
    case Action.GET_CHANGWAT.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
