/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useCallback, useEffect,useState} from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from '@material-ui/core';
import moment from "moment";
import "moment/dist/locale/th";
import { thaiYear } from '../../reducers/selectors/thaiYear';
import { Link } from 'react-router-dom';

interface IProps {
  title:string;
  type: string;
  column:Array<string>;
  data: Array<Record<string,string>>;
}

const ExcelExport=(props:IProps)=>{
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const exportToExcel = useCallback((data:any, columnTH:any) => {   
    const fileName = "report1_"+(moment().format("YYYYMMDDHHmmss"));
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [columnTH], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + ".xlsx");
  },[]);

  const prepairData= ()=>{
    if(props.data.length>0){
      const newData: Array<Record<string,string>> = [];
      if(props.type==="button"){

          props.data.forEach((d:any,index:number)=>{
            if(d.member3){delete d.member3}
            const data = {
              id: d.id, member1: d.member1, member2 :d.member2, area: d.teamName, group: d.groupName , family: d.familyCount,
              PSu1: d.PSu1, PCP: d.PCP, PARe: d.PARe, CRF: d.CRF,
              FF1: d.FF1, FF2: d.FF2, FF3: d.FF3, FF4: d.FF4, FF5: d.FF5, FF6: d.FF6, FF7: d.FF7, FF8: d.FF8,
              FSR: d.FSR,"L&M": d.L_M, PCRe: d.PCRe, PSu2: d.PSu2, total: d.total, remark: "", summaryDate: thaiYear(moment(),"ll")
            };
            newData.push(data);
        });
      }
      if(newData.length > 0){
        // console.log(props.data);
        // console.log(newData);
        exportToExcel(newData,props.column);
      }
    }
  }

  // useEffect(()=>{
  //   console.log(props);
  // },[props]);
  return (props.type==="button") ?
          <Button 
            fullWidth 
            size="medium"
            variant="contained" 
            color="primary"
            style={{ height: "40px", margin: 0 }}
            onClick={prepairData}
            >
              {props.title}
          </Button> :
          <Button 
            size="small" 
            color="primary"
            onClick={prepairData}
            >
              {props.title}
          </Button>
}

export default ExcelExport;