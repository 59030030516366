/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, Slide, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Actions from "../../actions/activity";
import moment from "moment";
import "moment/dist/locale/th";
import FilterListIcon from '@material-ui/icons/FilterList';

import { getHospitalInfo } from "../../services/api";
import _ from "lodash";
import { GridRowId } from "@material-ui/data-grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransitionProps } from "@material-ui/core/transitions";

import { useGetServiceSlotObserver } from './hook';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ServiceSlotManage(props: IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))||false;
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);

  const [cases,setCases]=useState([{id:"",label:"",value: {}}]);
  const [searchCase,setSearchCase]=useState<Record<string,any>>({id:"",label:"",value: {}});

  const { isError, isSuccess, isLoading, data, error, refetch } = useGetServiceSlotObserver();
  if(isSuccess){
    console.log("Dialog : Get Data from cache Success",data);
  }
  const handleClose = () => props.setOpen(false);
  const handleSave = () => {

  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      TransitionComponent={Transition}
      open={props.open}
      onClose={handleClose}
      disableBackdropClick={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        disableTypography
        style={{ paddingTop: "24px" }}
      >
        <Typography variant="h4" style={{ marginBottom: 0 }}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <TextField
              autoFocus
              id="groupName"
              label="ชื่อกลุ่ม"
              type="text"
              variant="outlined"
              size="small"
              style={{ height: "100%" }}
              fullWidth
              // onChange={(e) => setGroupName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              id="searchTeam"
              fullWidth
              freeSolo={true}
              options={[]}
              getOptionLabel={(option: any) => option.label}
              size="small"
              style={{ height: "100%" }}
              // onChange={(_, value: any) => setGroupTeam(value)}
              renderInput={
                (params) => 
                <TextField {...params} 
                  inputProps={{ ...params.inputProps, readOnly: true, autoComplete: 'off' }}
                  label="ทีม"
                  variant="outlined" 
                  fullWidth
                  
                  />
              }
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Autocomplete
              id="searchCases"
              fullWidth
              freeSolo={true}
              options={cases}
              value={searchCase}
              loading={globalState.activity.caseList.isFetching}
              getOptionLabel={(option: any) => option.label}
              size="small"
              style={{ height: "100%" }}
              disabled={globalState.activity.caseList.isFetching}
              onChange={(_, value: any) => setSearchCase(value)}
              // onInputChange={(e:any)=>{
              //   if(e!==null){ setSearchText(e.target.value);}
              // }}
              renderInput={
                (params) => 
                <TextField {...params} 
                  label={globalState.activity.caseList.isFetching?<CircularProgress size={16}/>:"ค้นหาเคส"} 
                  variant="outlined" 
                  fullWidth
                  />
              }
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary"
              startIcon={<FilterListIcon />} //SearchIcon
              style={{ height: "100%" }}
              // onClick={()=>setShowFilter(true)}
              >
                ตัวกรอง
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              // onClick={addGroupCase}
            >
              เพิ่มเข้ากลุ่ม
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          ยกเลิก
        </Button>
        <ButtonLoading 
          variant="contained"
          color="primary"
          onClick={handleSave}
          // loading={loading}
          >
            บันทึก
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}
