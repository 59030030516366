import { QueryObserver, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getServiceSlot, insertServiceSlot, updateServiceSlot, deleteServiceSlot } from '../../../services/api/serviceslot';

const key = 'serviceSlot'

export const useEditServiceSlot = () => {
    const queryClient = useQueryClient();
    return useMutation(updateServiceSlot, {
        onSuccess: (newData:any) => {
            queryClient.setQueryData([key],
                (prevData:any) => {
                    if (prevData) {
                        prevData.map((user:any) => {
                            if (user.id === newData.id) {
                                user.name = newData.name
                            }
                            return user
                        })
                    }
                    return prevData
                }
            )
        }
    })
}

export const useCreateServiceSlot= () => {
    const queryClient = useQueryClient();
    return useMutation(insertServiceSlot, {
        onSuccess: (data) => {
            queryClient.setQueryData([key],
                (prevData: any) => prevData ? [data, ...prevData] : [data]
            )
        }
    })
}

export const useDeleteServiceSlot = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteServiceSlot, {
        onSuccess: (id) => {
            queryClient.setQueryData([key],
                (prevData: any) => prevData ? prevData.filter((data:any) => data.id !== id) : prevData
            )
        }
    });
}

export const useGetServiceSlot = (user_id="") => {
    let payload: any={};
    if(user_id!==""){
        payload["user_id"]=user_id;
    }
    return useQuery(
        [key,payload],
        async () => await getServiceSlot(payload),
        {
            keepPreviousData: true,
            enabled: user_id!==""
        }
    );
}

export const useGetServiceSlotObserver = () => {

    const get_serviceslot = useGetServiceSlot()

    const queryClient = useQueryClient()

    const [serviceSlot, setServiceSlot] = useState(() => {
        // get data from cache
        const data = queryClient.getQueryData([key])
        return data ?? []
    })

    useEffect(() => {
        const observer = new QueryObserver(queryClient, { queryKey: [key] })

        const unsubscribe = observer.subscribe(result => {
            if (result.data) setServiceSlot(result.data)
        })

        return () => { unsubscribe() }
    }, [queryClient]);

    return {
        ...get_serviceslot,
        data: serviceSlot,
    }
}