import { combineReducers } from "redux";
import changwat from "./changwat";
import ampur from "./ampur";
import tambon from "./tambon";
import village from "./village";
import agency from "./agency";
import setFillter from "./setFilter";
export default combineReducers({
  changwat,
  ampur,
  tambon,
  village,
  agency,
  setFillter
});
