/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useState} from 'react';
import { Button, Divider, Grid,TextField,Typography } from "@material-ui/core";
import TeamLists from './list';
import DialogAdd from './teamsDialogAdd';
import { SearchProvider } from './helper/SetPageContext';
import ButtonLoading from '../../components/custom_components/ButtonLoading';

const Teams=()=>{
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchContext, setSearchContext] = useState("");
  const onSearch = ()=>{
    setSearchContext(searchText);
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >จัดการทีม</Typography>
        <Typography variant="h6" style={{marginBottom:"1.5rem"}}>ทีม คือ กลุ่มของผู้ใช้งานหรือกลุ่มของเจ้าหน้าที่ ซึ่งผู้ใช้งานที่อยู่กลุ่มเดียวกันจะสามารถจัดการเคสร่วมกันได้</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{display:"flex"}}>
        <Grid container spacing={3} style={{display:'flex', justifyContent:'space-between'}}>
          <Grid item xs={6} style={{display:"flex", flexGrow:"1"}}>
            <Grid item xs={10} >
              <TextField
                id="name"
                label="ค้นหา"
                type="text"
                size='small'
                variant='outlined'
                value={searchText}
                fullWidth
                onChange={(e)=>{
                  if(e.target.value===""){setSearchContext("");}
                  setSearchText(e.target.value);
                }}
                style={{ height: "100%" }}
              />
            </Grid>
            <Grid item xs={2} >
              <ButtonLoading fullwidth variant="contained" color="primary" onClick={onSearch} loading={loading}>ค้นหา</ButtonLoading>
              </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button 
              fullWidth 
              size="medium" 
              variant="contained" 
              color="primary" 
              style={{ height:"40px", margin: 0  }}
              onClick={()=>setShowModalAdd(true)}
              >
              สร้างทีม
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SearchProvider searchText={searchContext} setLoading={setLoading}>
          <TeamLists />
        </SearchProvider>
      </Grid>
      {
        // (showModalAdd)&&<DialogAdd open={showModalAdd} setOpen={setShowModalAdd} loading={loading} setLoading={setLoading}/>
      }
    </Grid>
  );
}

export default Teams;