/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory, useLocation} from 'react-router-dom';
import { Box, CircularProgress, createStyles, Grid, IconButton, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Actions from "../../actions/activity";
import moment from "moment";
import "moment/dist/locale/th";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { getHospitalInfo } from "../../services/api";
import _ from "lodash";
import { DataGrid, GridCellParams, GridColDef, GridRowData, GridRowId, GridRowParams } from "@material-ui/data-grid";
import { thaiYear } from "../../reducers/selectors/thaiYear";
import CustomDialog from '../../components/custom_components/Dialog';
import { updateAnswer } from '../../services/api';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DataGridMobile from './helper/datagridMobile';
import ButtonsByRole from "./helper/actionByRole";
import useDeepCompareEffect from 'use-deep-compare-effect';

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);


interface IProps {
  data: GridRowData;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  isMobile: boolean;
}

interface stateType {
  group: boolean;
  questionaire_id: string;
  data: Record<string,any>;
}

export default function CRF(props: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<stateType>();
  const globalState = useSelector((state: RootState) => state);
  const token: string = globalState.auth.user.response?.id||"";
  const role = globalState.auth.user.response.user.role;
  const userinfo:any = globalState.auth.user.response?.user||{};

  const questionaireList = globalState.activity.questionaireList;
  const [questionaireId,setQuestionaireId]=useState("");
  const [questionaireAnswerId,setQuestionaireAnswerId]=useState("");
  const [dataGrid,setDataGrid]=useState<GridRowData[]>([]);
  const [rowFocus, setRowFocus] = useState<GridRowData>({});
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false);
  const [loading,setLoading]=useState(false);
  const [columns,setColumns]=useState<Array<GridColDef>>([]);
  const [startEffect, setStartEffect] = useState(true);

  const deleteAnswer=async()=>{ // ✅ ลบข้อมูลคำตอบ CRF
    setLoading(true);
    const questionaire_answer_id = (rowFocus.id as number)-1;
    const oldAnswer = [...dataGrid];
    const newAnswer = oldAnswer.filter((row:any,index:number)=>index !== questionaire_answer_id);
    setTimeout(() => {setDataGrid(newAnswer)});
    const params = {
      update: {
        data:{
          id: questionaireAnswerId,
          data: {
            answer: newAnswer,
            answer_respondent: {
              // teamCode: userinfo.osccteams.filter((c:any)=>(c.teamName)&&c.teamName.includes("ทีมพื้นที่")).map((m:any)=>m.teamCode)[0], 
              teamCode: state.data.teams.teamCode,
              userid: userinfo.id , 
              fullname: userinfo.fullname
            },
            answer_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
          },
          successMsg:"แก้ไขคำตอบแบบสอบถามสำเร็จ"
        }
      },
      get: {
        param: {
          questionnaire_type: 'PLH2',
          questionnaire_group: state.group
        },
        state: state
      }
    }
    // console.log("deleteAnswer",params);
    await updateAnswer(token,params.update);
    props.refresh();
    // dispatch(Actions.UpdateAnswer.request(params));
  }

  const manageMenu: GridColDef = useMemo(()=>{
    let title="";
    if(props.data.questionnaire_manage && props.data.questionnaire_manage.includes(role)){
      title="จัดการ";
    }else{
      title="ดูคำตอบ";
    }
    return { 
      field: 'manage', 
      headerName: title, 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        const f = allFunction(params);
        return (
          <ButtonsByRole manage={props.data.questionnaire_manage} role={role||"" as string} caseStatus="" handleClick={f}/>
        )
      }
    }
  },[state, props.data, history]);

  const genColumns=useCallback((C: Array<GridColDef>,A: Array<GridRowData>)=>{
    if(C && C.length>0){
      const menu: Array<GridColDef> = C.map((item:GridColDef)=>{
        if(item.type==="date"){
          item["renderCell"]=(params: GridCellParams)=> {
            return thaiYear(moment(params.row[item.field as string]),"ll");
          };
        }
        return item;
      });
      menu.push(manageMenu);
      setColumns(menu);
      setDataGrid(A);
    }
    setStartEffect(false);
  },[manageMenu]);

  const allFunction = useCallback((params:any)=>{
    return {
      viewAnswer: ()=>{
        let newLocationState = Object.assign(state);
        newLocationState["coach"]=true;
        newLocationState["questionaire_id"]=props.data.id;
        newLocationState["questionaire_answer"]=(params.id as number)-1;
        newLocationState["readOnly"] = (state.data.caseState==="waitConclusion"  || state.data.groupStatus==="waitConclusion");
        history.push({ 
          pathname: '/questionaire', 
          state: newLocationState
        })
      },
      editAnswer: ()=>{
        let newLocationState = Object.assign(state);
        newLocationState["coach"]=true;
        newLocationState["questionaire_id"]=props.data.id;
        newLocationState["questionaire_answer"]=(params.id as number)-1;
        newLocationState["readOnly"] = (state.data.caseState==="waitConclusion"  || state.data.groupStatus==="waitConclusion");
        history.push({ 
          pathname: '/questionaire', 
          state: newLocationState
        })
      },
      deleteAnswer: ()=>{
        setRowFocus(params.row)
        setDeleteDialogOpen(true);
      }
    }
  },[props.data]);

  const [mobileData, setMobileData] = useState([]);
  const genDataForMobile = useCallback((column:any,answer:any)=>{
    // console.log(column,answer)
    if(answer.length>0){
      let title="";
      if(props.data.questionnaire_manage && props.data.questionnaire_manage.includes(role)){
        title="จัดการ";
      }else{
        title="ดูคำตอบ";
      }
      const newData: any = answer.map((item:any,ai:number)=>{
        return {
          name: item[column[0].field],
          status: "",
          data: column.filter((f:any,ff:any)=>ff!==0).map((m:any)=>{
            return {
              label: m.headerName,
              value: answer[ai][m.field],
              style: {label:6,value:5}
            }
          }),
          allFunction: allFunction(item),
          manage: props.data.questionnaire_manage
        };
      });
      // console.log(newData);
      if(newData.length>0){setMobileData(newData)}
    }
  },[]);

  useDeepCompareEffect(() => {
    if(startEffect){
      const data = {...props.data};
      // console.log(data);
      setQuestionaireId(data.id);
      if(!_.isEmpty(data.questionnaire_answer)){
        const questionnaire_answer = data.questionnaire_answer[0];
        const questionnaire = data.questionsList;
        setQuestionaireAnswerId(questionnaire_answer.id);
        if(_.isArray(questionnaire_answer.answer)){
          const ANSWER_TEMP = questionnaire_answer.answer.map((a:any,i:number)=>{
            a.id=(i+1);
            return a;
          });
          // console.log("ANSWER_TEMP",ANSWER_TEMP);
          const sortMe: string[] = [];
          questionnaire.map((q:any)=>{
            if(q.groupQuestionsList){
              q.groupQuestionsList.filter((f:any)=>f.hasOwnProperty("sortMe")).map((m:any)=>sortMe.push(m.inputName))
            }
          });
          const ANSWERS = _.sortBy(ANSWER_TEMP, sortMe,['asc']);
          if(props.isMobile){
            console.log("ANSWERS",ANSWERS);
            // genDataForMobile(data.questionnaire_datagrid_column,ANSWERS);
          }
          // console.log(data.questionnaire_datagrid_column,ANSWERS);
          genColumns(data.questionnaire_datagrid_column, ANSWERS);
          
        }
      }   
      // if(props.isMobile){
      //   console.log("No Answer");
      //   genDataForMobile(data.questionnaire_datagrid_column,[]);
      // }
    }    
    
    // setDeleteDialogOpen(false);
    // setLoading(false);
  },[genColumns, props.data, startEffect]);

  useDeepCompareEffect(() => {
    // console.log("questionaireList change",questionaireList.data);
    setDeleteDialogOpen(false);
    setLoading(false);
  },[questionaireList.data]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={()=>props.setOpen(false)}
        disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          disableTypography
          style={{ paddingTop: "24px" }}
        >
          <Typography variant={(props.isMobile) ? "body1" : "h5"} style={{ marginBottom: 0 }}>{props.data.questionnaire_code} : {props.data.questionnaire_name} </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {((props.data.questionnaire_manage && props.data.questionnaire_manage.includes(role)))&&
              <Grid item xs={12} style={{display:'flex',justifyContent:'end'}}>
                <Button 
                  variant="contained" 
                  color="primary"
                  startIcon={<AddIcon/>}
                  size={(props.isMobile)?"small":"medium"}
                  style={{ height: "100%" }}
                  onClick={()=>{
                    let newLocationState = Object.assign(state);
                    newLocationState["coach"]=true;
                    newLocationState["questionaire_id"]=questionaireId;
                    delete newLocationState.questionaire_answer;
                    // console.log(newLocationState);
                    history.push({ 
                      pathname: '/questionaire', 
                      state: newLocationState
                    })
                  }}>
                    ประเมินเพิ่ม
                </Button>
              </Grid>
            }
            <Grid item xs={12}>
              {
                (props.isMobile) ?
                  <DataGridMobile 
                    data={mobileData}
                    page="/questionair_repeat"
                  /> 
                :
                <DataGrid
                  rows={dataGrid}
                  columns={columns}
                  className={classes.dataGrid}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableColumnMenu
                  disableColumnSelector
                  disableSelectionOnClick
                  disableDensitySelector
                />
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "12px 24px 24px" }}>
          <Button onClick={()=>props.setOpen(false)} variant="outlined" color="primary">
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog 
        content={<Box style={{color:'red',fontWeight:'bold'}}>คุณแน่ใจว่าคุณต้องการลบคำตอบของแบบสอบถามนี้</Box>}
        state={{open:deleteDialogOpen,setOpen:setDeleteDialogOpen}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={deleteAnswer} loading={loading}>
                    ลบ
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setDeleteDialogOpen(false)}>ยกเลิก</Button>
        }}
        />
    </>
  );
}
