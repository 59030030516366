/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { SEND_CM } from "../index";

const sendCM = {
  request: (payload:any) => action(SEND_CM.REQUEST, payload),
  success: (response: any) => action(SEND_CM.SUCCESS, { data: response }),
  failure: (error: any) => action(SEND_CM.FAILURE, { error }),
};

export default sendCM;