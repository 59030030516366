import { GET_TEAMS } from "../../actions";
import { AnyAction } from "redux";
import { TeamsData } from "../../types/teams";

const initialState:TeamsData = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getTeams(
  state = initialState,
  action: AnyAction
): TeamsData {
  switch (action.type) {
    case GET_TEAMS.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        data:[]
      };
    }
    case GET_TEAMS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: action.data.rows
      };
    }
    case GET_TEAMS.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: [],
      };
    default:
      return state;
  }
}
