import { AnyAction } from "redux";
import * as types from "../../actions";
import { importOsccpersonRequest } from "../../types/osccperson";

const initialState: importOsccpersonRequest = {
  isFetching: false,
  isSuccess: false,
  importData: [],
  listData: [],
};

export default function importOsccperson(
  state = initialState,
  actions: AnyAction
): importOsccpersonRequest {
  switch (actions.type) {
    case types.OSCCPERSON.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.OSCCPERSON.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        listData: actions.payload,
      };
    case types.OSCCPERSON.IMPORT:
      const data = actions.payload.map((x: any, key: string) => {
        return { ...x, id: key + 1 };
      });
      return {
        ...state,
        isFetching: false,
        importData: data,
      };

    case types.OSCCPERSON.CLEAR:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        importData: [],
      };

    case types.OSCCPERSON.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
