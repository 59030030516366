const validate = (config, e) => {
  const name = (typeof e.target !== 'undefined') ? e.target.name : e.name;
  const value = (typeof e.target !== 'undefined' && e.target !== null) ? e.target.value : e.value;
  let updateForm = { ...config.formElements };
  if (typeof updateForm[name] !== 'undefined') {
    if (typeof updateForm[name].validator !== 'undefined') {
      const validatorObject = checkValidator(value, updateForm[name].validator, updateForm[name].error);
      updateForm[name].error = {
        status: validatorObject.status,
        // message: validatorObject.message,
        message: updateForm[name].error.message
      }
      let formStatus = true;
      for (let elName in updateForm) {
        if(elName!=="formValid"){
          if (updateForm[elName].validator.required === true) {
            formStatus = (!updateForm[elName].error.status) ? formStatus : false;
          }
        }
      }
      return { formElements: updateForm, formValid: formStatus };
    } else {
      console.log('Object not set validator yet.');
    }
  }
}
const checkValidator = (value, rule, error) => {
  let valid = true;
  let message = '';
  // ห้ามว่าง
  if (rule.required) {
    if (typeof value === 'object') {
      if( value !== null ){
        var keys = Object.keys(value);
        value = value[keys[1]];
        if (Object.keys(value).length === 0) {
          valid = false;
          message = (error.message==='')?'กรุณาเลือกข้อมูล':error.message;
        }
      }else{
        valid = false;
        message = (error.message==='')?'กรุณาเลือกข้อมูล':error.message;;
      }
    } else {
      if (value.trim().length === 0) {
        valid = false;
        message = (error.message==='')?'กรุณาเลือกข้อมูล':error.message;
      }
    }
  }
  // ตัวเลขเท่านั้น
  if (typeof rule.number !== 'undefined' && valid) {
    if (/^[0-9]+$/.test(value) === false) {
      valid = false;
      message = 'ตัวเลขเท่านั้น';
    }
  }
  // ความยาวเท่ากับ
  if (typeof rule.stringLength !== 'undefined' && value.length !== rule.stringLength && valid) {
    valid = false;
    message = `ความยาว ${rule.stringLength} ตัวอักษร`;
  }
  // ความยาวอย่างน้อย
  if (typeof rule.minLength !== 'undefined' && value.length < rule.minLength && valid) {
    valid = false;
    message = `กรุณากรอกข้อมูลอย่างน้อย ${rule.minLength} ตัวอักษร`;
  }
  // ความยาวไม่มากกว่า
  if (typeof rule.maxLength !== 'undefined' && value.length > rule.maxLength && valid) {
    valid = false;
    message = `กรุณากรอกข้อมูลไม่เกิน ${rule.maxLength} ตัวอักษร`;
  }
  // รูปแบบอีเมลล์
  if (typeof rule.pattern !== 'undefined' && rule.pattern === 'email' && valid) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === false) {
      valid = false;
      message = `อีเมลล์ไม่ถูกต้อง`;
    }
  }
  // รูปแบบบัตรประชาชน
  if (typeof rule.pattern !== 'undefined' && rule.pattern === 'mod13' && valid) {
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(value.charAt(i)) * (13 - i);
    }
    if ((11 - sum % 11) % 10 !== parseInt(value.charAt(12))) {
      valid = false;
      message = `หมายเลขบัตรประชาชนไม่ถูกต้อง`;
    }
  }
  // ตรงกันกับฟิลด์ที่ระบุ
  if (typeof rule.confirmMatch !== 'undefined' && valid) {
    if(value!==rule.confirmMatch){
      valid = false;
      message = `กรุณายืนยันรหัสผ่านให้ตรงกัน`;
    }
  }

  return { status: !valid, message: message };
}

export default validate;
