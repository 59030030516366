import { DELETE_INTERVENTION } from "../../actions";
import { AnyAction } from "redux";
import { DeleteIntervention } from "../../types/activity";

const initialState:DeleteIntervention = {
  isFetching: false,
  data: {}
};

export default function deleteIntervention(
  state = initialState,
  action: AnyAction
): DeleteIntervention {
  switch (action.type) {
    case DELETE_INTERVENTION.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case DELETE_INTERVENTION.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case DELETE_INTERVENTION.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
