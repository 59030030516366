/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, fork, put, takeLatest } from "redux-saga/effects";
import { OSCCPERSON } from "../../actions";
import Actions from "../../actions/osccperson";
import { snackbarFailure } from "../../actions/notification";
import * as Api from "../../services/api";
import { fetchEntity } from "../saga";
import _ from "lodash/index";

const osccTemps = fetchEntity.bind(
  null,
  Actions.insertOsccPersonImport,
  Api.osccpersonImport
);

function* importOsccPerson(payload: any) {
  console.log(_.chunk(payload.data, 2));
  yield put(snackbarFailure("TEST SYSTEM", 1500));
    // yield call(osccTemps, payload.data, {
    //   onSuccess: (success: any) => {},
    //   onFailure: () => put(snackbarFailure("Import OSCCPERSON Failed", 1500)),
    // });
}

function* watchImpoerOsccpersonRequest() {
  yield takeLatest(OSCCPERSON.REQUEST, importOsccPerson);
  return;
}

export default function* root() {
  yield fork(watchImpoerOsccpersonRequest);
}
