/* 
  For loopback API, we need to pass access_token in the query string.
*/

import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL, AXIOS, CustomError, queryParams, getToken } from '../helper';

const GET = async (
  endpoint: string,
  params: Record<string, any> | null | undefined
): Promise<AxiosResponse> => {
  try {
    const TOKEN = getToken();
    const PARAMS = (params) ? `filter=${encodeURIComponent(JSON.stringify(params))}` : undefined;
    const REQUEST_URL=`${API_URL}${endpoint}?access_token=${TOKEN}${(PARAMS)?'&'+PARAMS:''}`;
    const RESPONSE = await AXIOS.get(REQUEST_URL, {
      headers: TOKEN ? { Authorization: `${TOKEN}` } : undefined,
    });
    return RESPONSE;
  } catch (error: any) {
    throw new CustomError(error as Error | AxiosError);
  }
}

const POST = async (
  endpoint: string,
  params: Record<string, any> | null | undefined
): Promise<AxiosResponse<any>> => {
  try {
    const TOKEN = getToken();
    const REQUEST_URL=`${API_URL}${endpoint}?access_token=${TOKEN}`;
    const RESPONSE = await AXIOS.post(REQUEST_URL, params?params:undefined );
    return RESPONSE;
  } catch (error: any) {
    throw new CustomError(error as Error | AxiosError);
  }
}

export {  GET, POST };
