import { GET_ASSIGNCASES } from "../../actions";
import { AnyAction } from "redux";
import { AssignCases } from "../../types/activity";
// import moment from 'moment';
// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const initialState:AssignCases = {
  isFetching:false,
  count: 0,
  data: []
};

export default function getAssignCase(
  state = initialState,
  action: AnyAction
): AssignCases {
  switch (action.type) {
    case GET_ASSIGNCASES.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ASSIGNCASES.SUCCESS: {
      const caseData = action.data.rows.map((item:any)=>{
        return {
          id: item.id,
          cid: item.cid,
          fullname: item.fname+" "+item.lname,
          sex: item.sex,
          age: item.age,
          risk: item.risk
        }
      });
      return {
        ...state,
        isFetching: false,
        count: action.data.count,
        data: caseData
      };
    }
    case GET_ASSIGNCASES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
