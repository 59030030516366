/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect,useState,useCallback, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../../reducers";
// import { getTeams,getCoachInfo, getCoachTeams, getGroupMembers, getQuestionaireAnswer } from '../../services/api';
import { Button, Grid, makeStyles,TextField,Typography } from "@material-ui/core";
import {  Link, useHistory } from 'react-router-dom';
import _ from "lodash";
import { Autocomplete } from '@material-ui/lab';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import ExcelExport from './excel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { AxiosResponse } from 'axios';
import structuredClone from '@ungap/structured-clone';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment/dist/locale/th";
// import { thaiYear } from '../../reducers/selectors/thaiYear';
// import { useGet } from '../../hooks';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GET } from '../../hooks/request';

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#494949',
      color: 'white',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '0.9em'
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      fontSize: '0.9em'
    }
  },
  loadingOverlay: {
    position:'relative',
    width: '100%',
    height: '100dvh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
    backdropFilter: 'blur(3px)', // Blur effect
    zIndex: 999,
  },
});
interface TGroups {
  id: string;
  groupName: string;
  teams: {
    teamCode: string;
    teamName: string;
  };
  member: Record<string,number|string|boolean|[]>[];
  questionnaire_answer: Record<string,any>[];
}
const QC = ["PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L_M","PCRe","PSu2"];
const excludeUser = ["64b55443031902d3a6aef362"];
const Report1=()=>{
  const classes = useStyles();
  const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  const token = globalState.auth?.user?.response?.id;
  const [loading,setLoading] = useState(false);
  const [generationValue, setGenerationValue] = useState<Record<string,any>|null>(null);
  const [generationOptions, setGenerationOptions] = useState<Record<string,any>[]>([]);
  const [reportDaTa, setReportDaTa] = useState<Array<Record<string,any>>>([]);
  const [answerDaTa, setAnswerDaTa] = useState<Array<Record<string,any>>>([]);

  const generationHandleChange = (value: any) => {
    setGenerationValue(value);
  }
  // { between: ["1","99"] }
  const teams: UseQueryResult<any> = useQuery({
    queryKey: ['Teams',token,generationValue],
		queryFn: () => GET(`/osccteams`,{
      where : {
        version: "PLH2", 
        id: { neq : "659a496e9a6ae7543599a131"}
        // id: "64c4fa40d676000089005f42"
      }, 
      order: "id,name asc"
    }),
    refetchOnWindowFocus: false,
		enabled: !!token
	});

  const [teamsId, setTeamsId] = useState<string[]>([]);
  const groups = useQuery({
    queryKey: ['Groups',token,teamsId],
		queryFn: () => GET(`/osccperson_groups`, {
      fields: {
        id: true,
        groupStatus: true,
        groupName: true,
        teams: true,
        member: true,
      },
      where: {
        "teams.teamCode": { in: teamsId },
      },
      include: {
        relation: "questionnaire_answer",
        scope: {
          include: [
            {
              relation: "osccperson_assign"
            },
            {
              relation: "questionnaire",
              scope: {
                fields: { id: true, questionnaire_code: true, questionnaire_group: true,  questionsList: true, questionnaire_layout:true },
              },
            }
          ],
        },
      },
    }),
    refetchOnWindowFocus: false,
		enabled: !!(teamsId.length>0) && !!token
	});

  const generationData = useMemo(()=>{
    if(teams.data){
      // Generation Option Lists
      const currentGEN = (generationValue) ? generationValue.value : "1";
      const generation = _.uniqBy(teams.data, "generation");
      const genOptions = generation.map((item: any,index:number) => {
        return {
          id: item.generation,
          label: `รุ่นที่ ${item.generation}`,
          value: item.generation
        }
      });
      setGenerationOptions(genOptions);

      const teamsList: string[] = [];
      // console.log("teams.data",teams.data);
      const teamsData = teams.data.filter((f:any)=>f.generation===currentGEN).map((item: any) => {
        return item.members.map((member: any) => {
          const matchTeam=member.osccteams.filter((f:any)=>f.teamCode===item.id)[0];
          if(matchTeam){
            if( !excludeUser.includes(member.id) && ["CM","AdminTeams"].includes(matchTeam.memberTypeName) ){
              teamsList.push(item.id);
              return {
                generation: item.generation,
                teamCode: item.id,
                teamName: item.name,
                id: member.id,
                fullname: member.fullname.replace("นางสาว","น.ส."),
                role: member.role||member.type||"",
                memberTeamCode: matchTeam.teamCode,
                memberTeamName: matchTeam.teamName
              }
            }
          }
        });
      });

      const flatten = _.flattenDeep(teamsData);
      const result  = _.chain(flatten)
            .groupBy("teamCode")
            .toPairs()
            .map((currentItem)=>_.zipObject(['teamCode', 'teamMembers'], currentItem))
            .value();
      result.shift();
      setTeamsId(teamsList);
      return result;
    }
    return [];
  },[teams.data, generationValue]);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const exportToExcel = useCallback((title:string, data:any, columnTH:any) => {   
    const fileName = "ANSWER_"+title+"_"+(moment().format("YYYYMMDDHHmmss"));
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [columnTH], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + ".xlsx");
  },[]);
  const genExcelColumn=(QN:any)=>{
    let excelCols: Array<string> = ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    (QN?QN:[]).forEach((q:any)=>{
      if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
        excelCols.push(q.question.replace("<br>",""));
      }else{
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach((g:any)=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }
    });
    // console.log(excelCols);
    return excelCols;
  }
  const genExcelColumn2=(QN:any,prefixColumn=false)=>{
    let excelCols: Array<string> = (!prefixColumn)?[]: ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    (QN?QN:[]).forEach((q:any)=>{
      if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
        excelCols.push(q.question.replace("<br>",""));
      }else{
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach((g:any)=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }
    });
    // console.log(excelCols);
    return excelCols;
  }
  const combindAnswer = (QN:any,ANSWER:any)=>{
    // console.log("QN",QN);
    // console.log("ANSWER",ANSWER);
    if(!_.isArray(ANSWER)){
      let newAnswer: Record<string,any> = {};
      (QN?QN:[]).forEach((q:any)=>{
        // console.log("QN,ANSWER",QN,ANSWER);
        if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
          // console.log("question",q.inputName,ANSWER[q.inputName]);
          if(ANSWER[q.inputName]!==null){
            newAnswer = Object.assign({...newAnswer,[q.inputName] : ANSWER[q.inputName]||""});
          }
        }else{
          if(_.isArray(q.groupQuestionsList)){
            q.groupQuestionsList.forEach((g:any)=>{
              if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                
                const answer = g.answersList.filter((l:any)=>l.score===ANSWER[g.inputName]).map((m:any)=>m.answer)[0];
                newAnswer = Object.assign({...newAnswer,[g.inputName] : answer||""});
                // console.log("question radiobox",g.answer);
              }else{
                // console.log("question",g.inputName,ANSWER[g.inputName]);
                newAnswer = Object.assign({...newAnswer,[g.inputName] : ANSWER[g.inputName]||""});
              }
            });
          }
        }
      });
      // console.log("newAnswer",newAnswer);
      return newAnswer;
    }else{
      // console.log("ANSWER",ANSWER);
      let newAnswerArray: Array<Record<string,any>> = [];
      ANSWER.forEach((a:any)=>{
        let newQN = structuredClone(QN);
        let newAnswer: Record<string,any> = {};
        newQN.forEach((q:any)=>{
          if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
            if(a[q.inputName]!==null){
              newAnswer = Object.assign({...newAnswer,[q.inputName] : a[q.inputName]||""});
            }
          }else{
            if(_.isArray(q.groupQuestionsList)){
              q.groupQuestionsList.forEach((g:any)=>{
                if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                  const answer = g.answersList.filter((l:any)=>l.score===a[g.inputName]).map((m:any)=>m.answer)[0];
                  newAnswer = Object.assign({...newAnswer,[g.inputName] : answer||""});
                }else{
                  newAnswer = Object.assign({...newAnswer,[g.inputName] : a[g.inputName]||""});
                }
              });
            }
          }
        });
        // console.log("newAnswer",newAnswer);
        newAnswerArray.push(newAnswer);
      });
      // console.log("newAnswerArray",newAnswerArray);
      return newAnswerArray;
    }
  };
  const prepareAnswerData = (row:any, questionnaire_code:string) =>{
    const singleQ = ["PSu1","PSu2","PCP"];
    const groupQ = ["PARe","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","L_M","PCRe"];
    const groupQM = ["CRF","FSR"];
    const teamCode=row.teamCode;
    const riskLevel = [
      {id:1, label:'ต่ำ',value:'low'},
      {id:2, label:'ปานกลาง',value:'medium'},
      {id:3, label:'สูง',value:'high'},
      {id:4, label:'เหยื่อ',value:'victim'},
      {id:5, label:'ปกติ',value:'normal'},
      {id:6, label:'อื่นๆ',value:'other'}
    ];
    let data:any = [];
    // console.log("answerDaTa",answerDaTa);
    if(groups.data && groups.data){
      const answerDaTa: TGroups[] = _.cloneDeep(groups.data) as any;
      const groupData = answerDaTa.filter((t:any)=>t.teams.teamCode===teamCode && !t.groupName.includes("ทดสอบ"))[0];
      // console.log("groupData",groupData);
      if(typeof groupData!=="undefined"){
        const allMember = groupData.member;
        const qAnswer = groupData.questionnaire_answer.filter((q:any)=>q.questionnaire.questionnaire_code===questionnaire_code);
        let excelCols:any = [];
        if(allMember.length>0){
          // console.log("allMember",allMember);
          if(singleQ.includes(questionnaire_code)){
            if(typeof qAnswer[0]!=="undefined"){
              qAnswer.forEach((a:any) => {
                const ql = a.questionnaire.questionsList;
                const answer = a.answer;
                const member = allMember.filter((m:any)=>m.id===a.case_id)[0];// ข้อมูลเคส
                // console.log(member);
                if(member){
                  const newAnswer =  combindAnswer(ql,answer);
                  let caseInfo: Record<string,string> = {caseName : "",cid: "",sex: "", age: "",risk: "",fa: ""};
                  if(typeof a.osccperson_assign !=="undefined"){
                    caseInfo = {
                      caseName : member.fname +" "+ member.lname,
                      cid: member.cid+'',
                      sex: member.sex+'',
                      // birthdate: member.birthdate||"",
                      age: member.age.toString(),
                      risk: riskLevel.filter((r:any)=>r.value===member.riskLevel).map((l:any)=>l.label)[0]||"",
                      fa: row.member1+"|"+row.member2
                    }
                    data.push({...caseInfo,...newAnswer});
                  }
                }
              });
              // console.log(excelCols);
              // console.log(data);
              exportToExcel(questionnaire_code,data,excelCols);
            }
          }else if(groupQ.includes(questionnaire_code)){
            const questionsList:any=[];
            const groupQuestionsList:any=[];
            const answerList:any=[];
            allMember.forEach((m:any,mIndex:number) => {
              let caseInfo = {
                caseName : m.fname +" "+ m.lname,
                cid: m.cid,
                sex: m.sex,
                age: m.age.toString(),
                risk: riskLevel.filter((r:any)=>r.value===m.riskLevel).map((l:any)=>l.label)[0]||"",
                fa: row.member1+"|"+row.member2
              };
              if(typeof qAnswer[0]!=="undefined"){
                const a = qAnswer[0];
                const ql = a.questionnaire.questionsList;
                const answer = a.answer;
                if(!ql){ // PARe ฟอร์มใหม่
                  questionsList.length=0;
                  groupQuestionsList.length=0;
                  answerList.length=0;
                  const questionnaire_layout = a.questionnaire.questionnaire_layout;
                  if(questionnaire_layout){
                    for(const layouts in questionnaire_layout){
                      const layout = questionnaire_layout[layouts].rows;
                      const focusLayout = (+layouts===2) ? [{...layout[mIndex]}] : layout;
                      for(const rows of focusLayout){
                        // console.log(rows);
                        for(const col of rows.cols){
                          if(col.name){
                            questionsList.push({
                              question:(col.placeholder)?col.placeholder:col.label,
                              inputName: col.name,
                              answer: answer[col.name]
                            });
                            answerList.push((!col.name.includes("date")) ? (answer[col.name]).replace(/-/g,"") : answer[col.name]);
                          }
                        }
                        
                      }
                    }
                  }     
                  // console.log(questionsList);
                  // console.log({...answerList});
                  excelCols = genExcelColumn2(questionsList);           
                  data.push({...answerList});
                }else{ // ฟอร์มเก่า
                  if(a.questionnaire.questionnaire_code==="PARe"){ // PARe เก่า
                    const groupData:any = [];
                    questionsList.length=0;
                    groupQuestionsList.length=0;
                    answerList.length=0;
                    for (let key in answer) {
                      const match = key.match(/PARe_(parent\d+)_/);
                      if (match) {
                        const parentKey = match[1];
                        if (!groupData[parentKey]) {
                          groupData[parentKey] = {};
                          for (let otherKey in answer) {
                            if (!otherKey.includes("parent")) {
                              if(!otherKey.includes("date")){
                                groupData[parentKey][otherKey] = answer[otherKey].replace(/-/g,"");
                              }else{
                                groupData[parentKey][otherKey] = answer[otherKey];
                              }
                            }
                          }
                        }
                        if(!key.includes("date")){
                          groupData[parentKey][key] = answer[key].replace(/-/g,"");
                        }else{
                          groupData[parentKey][key] = answer[key];
                        }
                      }
                    }
                    let focusGroup:any = {};
                    Object.values(groupData).forEach((item:any)=>{
                      for(let key in item){
                        if(key.includes("children_cid")){
                          if(item[key]===m.cid){
                            focusGroup=item;
                          }
                        }
                      }
                    });
                    for( const key in focusGroup){
                      answerList.push(focusGroup[key]);
                      for(const row of ql){
                        if(!row.groupQuestionsList){
                          if(row.inputName===key){
                            questionsList.push({
                              question: row.question,
                              inputName: row.inputName,
                              answer: answer[row.inputName]
                            });
                          }
                        }else{
                          for(const group of row.groupQuestionsList){
                            if(group.inputName===key){
                              questionsList.push({
                                question: group.question,
                                inputName: group.inputName,
                                answer: answer[group.inputName]
                              });
                            }
                          }
                        }
                      }
                    }
                  }else{ // Form อื่นๆ
                    questionsList.length=0;
                    groupQuestionsList.length=0;
                    answerList.length=0;
                    for( const key in answer){
                      answerList.push(answer[key]);
                      for(const row of ql){
                        if(!row.groupQuestionsList){
                          if(row.inputName===key){
                            questionsList.push({
                              question: row.question,
                              inputName: row.inputName,
                              answer: answer[row.inputName]
                            });
                          }
                        }else{
                          for(const group of row.groupQuestionsList){
                            if(group.inputName===key){
                              questionsList.push({
                                question: group.question,
                                inputName: group.inputName,
                                answer: answer[group.inputName]
                              });
                            }
                          }
                        }
                      }
                    }
                  }

                  excelCols = (a.questionnaire.questionnaire_code!=="PARe") ? 
                    genExcelColumn(questionsList) :
                    genExcelColumn2([...questionsList,...groupQuestionsList],true);
                  const caseInfoArr=Object.values(caseInfo).map((m:any)=>m);
                  let newAnwser: any = [...caseInfoArr,...answerList];
                  data.push({...newAnwser});
                }
              }else{
                console.log("หา questionnaire_code ไม่เจอในคำตอบแบบสอบถาม");
              }
            });
            // console.log(JSON.stringify(excelCols,null,2));
            // console.log(JSON.stringify(data,null,2));
            exportToExcel(questionnaire_code,data,excelCols);
          }else if(groupQM.includes(questionnaire_code)){
            allMember.forEach((m:any) => {
              let caseInfo = {caseName : "",cid: "",sex: "",age: "",risk: "",fa: ""};
              caseInfo = {
                caseName : m.fname +" "+ m.lname,
                cid: m.cid,
                sex: m.sex,
                // birthdate: m.birthdate||"",
                age: m.age.toString(),
                risk: riskLevel.filter((r:any)=>r.value===m.riskLevel).map((l:any)=>l.label)[0]||"",
                fa: row.member1+"|"+row.member2
              };
              // console.log("caseInfo",caseInfo);
              if(typeof qAnswer[0]!=="undefined"){
                // console.log("qAnswer",qAnswer);
                qAnswer.forEach((a:any) => {
                  const ql = a.questionnaire.questionsList;
                  const answer = a.answer;
                  // console.log("a.answer",a.answer);
                  const newAnswer = combindAnswer(ql,answer);
                  newAnswer.forEach((a:any) => {
                    data.push({...caseInfo,...a});
                  });
                });
              }else{
                // console.log(groupData.questionnaire_answer);
                console.log("หา questionnaire_code ไม่เจอในคำตอบแบบสอบถาม");
              }
            });
            // console.log(excelCols);
            // console.log(data);
            exportToExcel(questionnaire_code,data,excelCols);
          }else{
            alert("อยู่ระหว่างพัฒนา");
            console.log(questionnaire_code);
          }
        }else{
          console.log("allMember : ",allMember.length);
        }
      }else{
        console.log("หา teamCode ไม่เจอในคำตอบแบบสอบถาม");
      }
    }
  }

  useEffect(()=>{
    if(generationData && generationData.length>0){
      // console.log("generationData",generationData);
      if(groups.data){ 
        const groupsData: TGroups[] = _.cloneDeep(groups.data) as any;
        // console.log("groupsData",groupsData);
        const newData = generationData.filter((f)=>(f.teamCode!=='undefined')).map((t:any,i:number)=>{
          const group = groupsData.filter((g)=>g.teams.teamCode===t.teamCode)[0];
          const answers: Record<string,any>[] = [];
          // console.log("T",t);
          // console.log("group",group);
          if(group){
            group.questionnaire_answer.map((q)=>{
              const isGroup = q.questionnaire.questionnaire_group;
              const code = q.questionnaire.questionnaire_code;
              // console.log(q);
              // if(q.osccperson_assign.cid==="1419903332917"){
                if(!isGroup){
                  const prevTotal = answers.filter((f:any)=>f.code===code);
                  
                  if((prevTotal && prevTotal.length>0)){
                    // if(code==="PSu1"){
                    //   console.log(code,"PrevTotal",q.osccperson_assign.fname+" "+q.osccperson_assign.lname,prevTotal[0].total,(prevTotal[0].total+1));
                    // }
                    prevTotal[0].total++;
                  }else{
                    
                    answers.push({
                      code: code,
                      total:  1
                    });
                    // if(code==="PSu1"){
                    //   console.log(code,"PrevTotal First Person",q.osccperson_assign.fname+" "+q.osccperson_assign.lname, prevTotal[0]?.total,1);
                    // }
                  }
                }else{
                  answers.push({
                    code: code,
                    total: group.member.length
                  });
                }
              // }
            });
          }
          t["id"] = i+1;
          t["teamCode"] = t.teamCode||'';
          t["teamName"] = (t.teamMembers && t.teamMembers[0])?t.teamMembers[0].teamName:'';
          t["familyCount"] = (group)?group.member.length:0;
          t["groupName"]  = (group)?group.groupName:'';
          t["member1"] = (t.teamMembers && t.teamMembers[0])?t.teamMembers[0].fullname:'';
          t["member2"] = (t.teamMembers && t.teamMembers[1])?t.teamMembers[1].fullname:'';
          t["total"] = 0;
          QC.map((q:string)=>{
            const matchQN = answers.filter((f:any)=>f.code===q);
            const total = (matchQN && matchQN.length>0) ? matchQN[0].total : 0;
            t[q] = total;
            t.total+=total;
          });
          // console.log("answers",answers);
          // delete t.teamCode;
          delete t.teamMembers;
          return t;
        });
        
        
        setReportDaTa(newData);
        setLoading(false);
      }
    }
    setLoading(false);
  },[generationData, groups.data, setReportDaTa, setLoading]);
  
  const columns: GridColDef[] = [
    {
      field: 'id', 
      headerName: '#', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 50,
      flex: 0.03
    },
    { 
      field: 'member1',
      headerName: 'FA คนที่ 1', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 150,
      flex: 0.07
    },
    { 
      field: 'member2',
      headerName: 'FA คนที่ 2', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 150,
      flex: 0.07
    },
    { 
      field: 'teamName',
      headerName: 'พื้นที่ / กลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 200,
      flex: 0.13,
      renderCell: (params: GridCellParams)=> {
        return <Grid container direction="column" spacing={1} style={{lineHeight:'1rem',justifyContent:'center'}}>
          <Grid item xs={12}>ทีม : {params.row.teamName.replace('ทีมพื้นที่','')}</Grid>
          {(params.row.groupName) && <Grid item xs={12}>กลุ่ม : {params.row.groupName.replace('ทีมพื้นที่','').replace('กลุ่ม ','')}</Grid>}
        </Grid>
      }
    },
    { 
      field: 'familyCount',
      headerName: 'ครอบครัว', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 90,
      flex: 0.04
    }
  ];
  QC.forEach((q:string)=>{
    let columnTemplate: GridColDef = { 
      field: q,
      headerName: q, 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 70,
      flex: 0.03
    };
    columnTemplate["renderCell"] = (params: GridCellParams)=> {
      if(params.row[q]){
        return <Button size="small" color="primary" onClick={()=>prepareAnswerData(params.row,q)}>{params.row[q]}</Button>;
      }else{
        return params.row[q];
      }
    }
    columns.push(columnTemplate);
  });
  columns.push({ 
    field: "total",
    headerName: "รวม", 
    headerClassName:'apm-header', 
    sortable: false, 
    headerAlign:'center', 
    align:'center',
    minWidth: 70,
    flex: 0.03
  });
  
  const excelColumn: Array<string>=[
    "ลำดับ","FA คนที่ 1","FA คนที่ 2","พื้นที่","กลุ่ม","ครอบครัว",
    "PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L&M","PCRe","PSu2",
    "รวม","หมายเหตุ","วันที่สรุป"
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >รายงานสรุปการบันทึกข้อมูลในระบบ Childshield</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Autocomplete
          id="generation"
          fullWidth={false}
          freeSolo={true}
          value={generationValue}
          options={generationOptions}
          getOptionLabel={(option: any) => option.label}
          size="small"
          style={{ height: "100%" }}
          disabled={teams.isFetching}
          onChange={(_:any, value: any) => generationHandleChange(value)}
          renderInput={
            (params) => 
            <TextField {...params} 
              fullWidth
              label="ดูข้อมูลของรุ่นที่"
              variant="outlined"
              inputProps={{ ...params.inputProps, readOnly: true }}
              />
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Button fullWidth variant="contained" color="primary" style={{height:"100%"}}
              startIcon={<ArrowBackIosIcon />}
              onClick={ ()=>history.push({pathname:"/report"})}>
              กลับ
            </Button>
          </Grid>
          <Grid item xs={6} md={8}>
            <ExcelExport type="button" data={reportDaTa} column={excelColumn} title="ส่งออก Excel"/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <DataGrid
          rows={(teams.isFetching||groups.isFetching)?[]:reportDaTa}
          columns={columns}
          loading={teams.isFetching||groups.isFetching}
          headerHeight={80}
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          disableDensitySelector
          style={{minHeight:'50dvh'}}
        />
      </Grid>
    </Grid>
  );
}

export default Report1;