import { GET_ANSWER } from "../../actions";
import { AnyAction } from "redux";
import { GetAnswer } from "../../types/activity";
// import moment from 'moment';
// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const initialState:GetAnswer = {
  isFetching:false,
  count: 0,
  data: []
};

export default function getAnswer(
  state = initialState,
  action: AnyAction
): GetAnswer {
  switch (action.type) {
    case GET_ANSWER.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ANSWER.SUCCESS: {
      const caseData = action.data.rows.map((item:any)=>{
        return {
          id: item.id,
        }
      });
      return {
        ...state,
        isFetching: false,
        count: action.data.count,
        data: caseData
      };
    }
    case GET_ANSWER.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
