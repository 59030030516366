/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import axios from "axios";
import url from "url";
import qs from "qs";
import querystring from "querystring";
import jwt from "jsonwebtoken";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LineIcon from "react-lineicons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
//FaLine
const useStyles = makeStyles((theme) => ({
  button: {
    margin: 0,
    backgroundColor: '#00c300',
    color: '#fff',
    width: '100%',
    height: '40.84px',
    '&:hover': {
      backgroundColor: '#05bb05',
      color: '#fff',
    }
  },
}));

export default function LineLogin(props) {
  const classes = useStyles();
  const clientID = props.clientID,
    clientSecret = props.clientSecret,
    state = props.state,
    nonce = props.nonce,
    scope = props.scope,
    setPayload = props.setPayload,
    setIdToken = props.setIdToken,
    redirectURI = props.redirectURI,
    buttonLabel = props.buttonLabel;

  const lineLogin = () => {
    let query = querystring.stringify({
      response_type: 'code',
      client_id: clientID,
      state: state,
      scope: scope
    });
    let lineAuthoriseURL = 'https://access.line.me/oauth2/v2.1/authorize?' + query + '&redirect_uri=' + redirectURI;
    window.location.href = lineAuthoriseURL;
  };

  useEffect(function () {
    const getAccessToken = function getAccessToken(callbackURL) {
      let urlParts = url.parse(callbackURL, true);
      let query = urlParts.query;
      let hasCodeProperty = Object.prototype.hasOwnProperty.call(query, 'code');

      if (hasCodeProperty) {
        let reqBody = {
          grant_type: 'authorization_code',
          code: query.code,
          redirect_uri: redirectURI,
          client_id: clientID,
          client_secret: clientSecret
        };
        let reqConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };
        axios.post('https://api.line.me/oauth2/v2.1/token', qs.stringify(reqBody), reqConfig).then(function (res) {
          if (setPayload) setPayload(res.data);

          try {
            let decodedIdToken = jwt.verify(res.data.id_token, clientSecret, {
              algorithms: ['HS256'],
              audience: clientID.toString(),
              issuer: 'https://access.line.me',
              nonce: nonce
            });
            if (setIdToken) setIdToken(decodedIdToken);
          } catch (err) {
            console.log(err);
          }
        })["catch"](function (err) {
          console.log(err);
        });
      }
    };
    getAccessToken(window.location.href);
  }, [clientID]);

  return (
    <Button
      variant="contained"
      className={classes.button}
      startIcon={<LineIcon name="line" />}
      onClick={lineLogin}
      style={{ marginLeft: 0, textTransform: 'none' }}
    >{buttonLabel}</Button>
  )
};