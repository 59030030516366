/* eslint-disable @typescript-eslint/no-unused-vars */
import { UPDATE_GROUP } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';
import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { AllEffect, CallEffect, all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from "lodash";

const updateGroup = fetchEntity.bind(null, Actions.UpdateGroup, Api.updateGroup);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const insertAssign = fetchEntity.bind(null, Actions.InsertAssign, Api.insertAssign);
const updateAssign = fetchEntity.bind(null, Actions.UpdateAssign, Api.updateAssign);
const deleteAssign = fetchEntity.bind(null, Actions.DeleteAssign, Api.deleteAssign);
const getAnswer = fetchEntity.bind(null, Actions.GetAnswer, Api.getAnswer);
const deleteAnswer = fetchEntity.bind(null, Actions.DeleteAnswer, Api.deleteAnswer);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);
const getCases = fetchEntity.bind(null, Actions.GetCases, Api.getCases);
const getTeams = fetchEntity.bind(null, Actions.getGroupListTeams, Api.getTeams);


function* firstAction(action: any) {
  yield call(
    updateGroup, action.updateGroup.params,
    {
      onSuccess: (success: any) => {
        // console.log("updateGroup success");
        // console.log((action.history && !_.isEmpty(action.history)));
        if(action.history && !_.isEmpty(action.history)){ // เรียกใช้งานจาก informConsent
          if(!_.isEmpty(action.getCases)){
            // console.log("call actionGetCases");
            return call(actionGetCases, action);
          } else if(!_.isEmpty(action.getGroups)){
            // console.log("call actionGetGroups");
            return call(actionGetGroups, action);
          } else {
            // console.log("call push history");
            return all([
              put(push(action.history.pathname,action.history.state)),
              put(snackbarSuccess(action.updateGroup.successMessage,2000))
            ]);
          }
        }else{ // เรียกใช้งานจาก groupDialogEdit
          // return put(snackbarSuccess('updateGroup success',3000));
          return call(actionManageCases, action);
        }
      },
      onFailure: (errer:any) => {
        console.log("updateGroup errer", errer);
        return put(snackbarFailure('updateGroup failed',9000));
      }
    }
  );
}
function* actionManageCases(action: any) {
  if( !_.isEmpty(action.insertMembers) && !_.isEmpty(action.removeMembers) ){ // เอาออกและเอาเข้าพร้อมกัน
    // console.log("Insert and Remove case");
    const insertParams = action.insertMembers.params;
    const insertCalls  = insertParams.map((data:any)=>call(updateCases,data));
    const insertAction = insertCalls.map((action:any)=>action);
    const removeParams = action.removeMembers.params;
    const removeCalls  = removeParams.map((data:any)=>call(updateCases,data));
    const removeAction = removeCalls.map((action:any)=>action);
    const allAction: CallEffect<any>[] = [...removeAction, ...insertAction];
    const results: AllEffect<any>[] = yield all(allAction);
    const success = results.every((res:any) => res.response.status === 200);
    const failure = results.some((res:any)  => res.response.status !== 200);
    if(failure){
      console.log("Insert and Remove case failed",failure);
      return put(snackbarFailure('updateCasesInOut failed',3000));
    }
    if(success){
      // console.log("Insert and Remove case success",success);
      yield call(actionManageAssign, action);
    }
  } else if(!_.isEmpty(action.insertMembers) && _.isEmpty(action.removeMembers)){ // Insert case into groups
    // console.log("Update case only");
    const insertParams = action.insertMembers.params;
    const insertCalls  = insertParams.map((data:any)=>call(updateCases,data));
    const insertAction = insertCalls.map((action:any)=>action);
    // console.log("insertAction", insertAction);
    const allAction: CallEffect<any>[] = [...insertAction];
    // console.log("allAction", allAction);
    const results: AllEffect<any>[] = yield all(allAction);
    // console.log("results AllEffect", results);
    const success = results.every((res:any) => res.response.status === 200);
    const failure = results.some((res:any)  => res.response.status !== 200);
    if(failure){
      console.log("Update case failed",failure);
      return put(snackbarFailure('Update case failed',3000));
    }
    if(success){
      // console.log("Update case success",success);
      // return put(snackbarSuccess('Update case success',3000));
      yield call(actionManageAssign, action);
    }
  } else if(_.isEmpty(action.insertMembers) && !_.isEmpty(action.removeMembers)){ // Remove case from groups
    // console.log("Remove case only");
    const removeParams = action.removeMembers.params;
    const removeCalls  = removeParams.map((data:any)=>call(updateCases,data));
    const removeAction = removeCalls.map((action:any)=>action);
    // console.log("removeAction", removeAction);
    const allAction: CallEffect<any>[] = [...removeAction];
    // console.log("allAction", allAction);
    const results: AllEffect<any>[] = yield all(allAction);
    // console.log("results AllEffect", results);
    const success = results.every((res:any) => res.response.status === 200);
    const failure = results.some((res:any)  => res.response.status !== 200);
    if(failure){
      console.log("Remove case failed",failure);
      return put(snackbarFailure('Remove case failed',3000));
    }
    if(success){
      // console.log("Remove case success",success);
      // return put(snackbarSuccess('Remove case success',3000));
      yield call(actionManageAssign, action);
    }
  } else {
    // console.log("No change for osccperson");
    yield call(actionManageAssign, action);
  }
}
function* actionManageAssign(action: any) {
  // console.log("actionManageAssign -> start",action);
  if(!_.isEmpty(action.insertAssign) && !_.isEmpty(action.deleteAssign)){ // Insert And Delete Assign
    // console.log("Delete and insert osccperson_assign");
    const delAssign = action.deleteAssign.params.map((data:any)=>call(deleteAssign,data));
    const allResult = delAssign.map((delAction:any)=>delAction);
    const insAssign = call(insertAssign,action.insertAssign.data);
    allResult.push(insAssign);
    const results:Array<AllEffect<any>> = yield all(allResult);
    const success = results.every((res:any) => res.response.status===200);
    const failure = results.every((res:any) => res.response.status !== 200);
    if(failure){
      put(snackbarFailure('Insert and Delete Assign Data Failed',9000));
    }
    if(success){
      yield call(actionManageAnswer, action);
    }
  } 
  if(!_.isEmpty(action.insertAssign) && _.isEmpty(action.deleteAssign)){// Insert Assign
    // console.log("Insert osccperson_assign only",action.insertAssign.data);
    yield call(
      insertAssign, action.insertAssign.data,
      {
        onSuccess: (success: any) => {
          return call(actionGetGroups, action);
        },
        onFailure: () => put(snackbarFailure('Insert Assign Data Failed',9000))
      }
    );
  } 
  if(_.isEmpty(action.insertAssign) && !_.isEmpty(action.deleteAssign)){// Delete Assign
    // console.log("Delete osccperson_assign only");
    const deleteList = action.deleteAssign.params;
    const results:Array<AllEffect<any>> = yield all( deleteList.map((data:any)=>call(deleteAssign,data)));
    const success = results.every((res:any) => res.response.status===200);
    const failure = results.every((res:any) => res.response.status !== 200);
    if(failure){put(snackbarFailure('Delete Assign Data Failed',9000));}
    if(success){yield call(actionManageAnswer, action);}
  } 
  if(!_.isEmpty(action.updateAssign)) {
    // console.log("actionManageAssign -> update");
    const updateData = action.updateAssign.data;
    const updateCalls = updateData.map((data:any)=>call(updateAssign,data));
    const allResult = updateCalls.map((updateAction:any)=>updateAction);
    const results:Array<AllEffect<any>> = yield all(allResult);
    const success = results.every((res:any) => res.response.status===200);
    const failure = results.every((res:any) => res.response.status !== 200);
    if(failure){
      console.log("Update Assign Failed",failure);
      put(snackbarFailure('Update Assign Data Failed',9000));
    }
    if(success){
      // console.log("Update Assign success",success);
      yield call(actionGetGroups, action);
    }
  }
  if(_.isEmpty(action.insertAssign) && _.isEmpty(action.updateAssign) && _.isEmpty(action.deleteAssign)){
    // console.log("No change for osccperson_assign");
    yield call(actionGetGroups, action);
  }
}
function* actionManageAnswer(action: any) {
  if(!_.isEmpty(action.removeAnswer)){
    const answerCall = [call(getAnswer, action.removeAnswer.params)];
    const answerAction = answerCall.map((action)=>action);
    const allAction: CallEffect<any>[] = [...answerAction];
    const results: AllEffect<any>[] = yield all(allAction);
    const getResult = results.map((res:any) => (res.response.status === 200) ? res.response.data : [])[0];
    const deleteResults: Array<AllEffect<any>> = yield all( getResult.map((data:{id:string})=>call(deleteAnswer,{id: data.id})) );
    const success = deleteResults.every((res:any) => { console.log("Delete answer success",res); return res.response.status === 200});
    const failure = deleteResults.some((res:any)  => res.response.status !== 200);
    if(failure){
      console.log("Delete answer failed",failure);
      put(snackbarFailure('Delete answer failed',3000));
    }
    if(success){
      yield call(actionGetGroups, action);
    }
  }
}

function* actionGetGroups(action: any) {
  const params  = { data: action.getGroups.params };
  const message = action.updateGroup.successMessage;
  // console.log("actionGetGroups",params);
  yield call( getGroups, params,
    {
      onSuccess: (groups: any) => {  
        // console.log("getGroups success");
        if(action.history){
          return all([
            put(push(action.history.pathname,action.history.state)),
            put(snackbarSuccess(action.updateGroup.successMessage,2000))
          ]);
        }else{ // groupDialogEdit
          // console.log("getGroups success",params,groups);
          // return put(snackbarSuccess(message,2000));
          return call(actionGetTeams, { data: groups, message: message});
        }
      },
      onFailure: (error:any) => {
        console.log("getGroups error", error, params);
        return put(snackbarFailure('getGroups failed',9000));
      }
    }
  );
}
function* actionGetCases(action: any) {
  yield call(
    getCases, { data: action.getCases.params },
    {
      onSuccess: (success: any) => {    
        if(action.history){
          return all([
            put(push(action.history.path,action.history.state)),
            put(snackbarSuccess(action.updateGroup.successMessage,2000))
          ]);
        } 
        return put(snackbarSuccess(action.updateGroup.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getGroups failed',9000))
    }
  );
}
function* actionGetTeams(action: any) {
  // console.log("actionGetTeams data : ",action);
  if( action.data  && action.data.length>0 ){
    const teamCode = _.uniq(action.data.map((data:any)=>data.teams.teamCode));
    const params = { data: { type: "merge", where: { id: { inq: teamCode } }, groupData: action.data }}
    // console.log("getTeams params",params);
    yield call( getTeams, params,
      {
        onSuccess: (success: any) => {
          // console.log("getTeams onSuccess",success);
          return put(snackbarSuccess(action.message,2000))
        },
        onFailure: (error: any) => {
          console.log("getTeams error",error,params);
          return put(snackbarFailure('getTeams failed',9000))
        }
      }
    );
  }else{
    yield put(snackbarFailure('getTeams failed',9000));
  }
}

function* watchFirstRequest() {
  yield takeLatest(UPDATE_GROUP.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
