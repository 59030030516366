/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, Grid,Typography } from "@material-ui/core";
import {  useHistory } from 'react-router-dom';
import DvrIcon from '@material-ui/icons/Dvr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { deleteData, getTeams } from '../../services/api';

const Reports=()=>{
  const history = useHistory();
  // ฟังก์ชั่นลับ clear token ของสมาชิกทีมทุกคน เพื่อบังคับ login ใหม่
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const clearToken=async()=>{
  //   const localstorage = JSON.parse(localStorage.getItem('OSCC')||"");
  //   const teamData = await getTeams(localstorage.id,{data:{where:{}}});
  //   if(teamData.success && teamData.response!==null){
  //     const teamMember = teamData.response.data;
  //     const memberID: Array<any> = []
  //     teamMember.forEach((t:any)=>{
  //       t.members.forEach((m:any)=>{
  //         // if(m.type!=="Coach"){
  //           // memberID.push({id:m.id,teams:m.osccteams});
  //           memberID.push(m.id);
  //         // }
  //       })
  //     });
  //     console.log(memberID);
  //     memberID.forEach((id:string)=>{
  //       deleteData(localstorage.id,{endpoint:`teamusers/${id}/accessTokens`})
  //     });
  //   }
  // }
  
  // useEffect(() => {
  //   clearToken();
  // },[]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >รายงาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ border: 'solid 1px grey', borderRadius: 5, padding: 4, marginTop: 5 }}>
          <Grid item xs={12} sm={11} style={{ padding: 10 }}>
            1. รายงานสรุปการบันทึกข้อมูลในระบบ Childshield
            <div style={{ fontSize: 14, color: 'grey' }}></div>
          </Grid>
          {/* <Grid item xs={12} sm={1} style={{ paddingRight: 5 }}>
            <Button 
              fullWidth
              variant="contained" 
              color='primary'
              style={{height:"100%"}}
              onClick={() => {}}
              startIcon={
                <FontAwesomeIcon icon={icon({name: 'file-arrow-down', family: 'classic', style: 'solid'})} style={{ marginLeft: 11}}/>
              }
            />
          </Grid> */}
          <Grid item xs={12} sm={1} style={{ paddingRight: 5 }}>
            <Button 
              variant="contained" 
              fullWidth 
              color='primary'
              style={{height:"100%"}}
              onClick={() => { 
                history.push({ pathname: '/summary-report' }); // report-recording-summary
              }}
              startIcon={<DvrIcon style={{ marginLeft: 11}} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Reports;