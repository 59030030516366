/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { getAll } from "../../services/UniversalAPI";
import { getCurrentUser } from '../../services/auth.service';
import Permission from "../../services/Permission";
import { makeStyles } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useGlobal from "../../store";
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    // '& .MuiInputLabel-outlined': {
    //   zIndex: 1,
    //   transform: 'translate(15px, 4px) scale(1)',
    //   pointerEvents: 'none'
    // },
    // '& .MuiInputLabel-shrink': {
    //   transform: 'translate(15px, -18px) scale(0.75)',
    // },
    '& .Mui-error .MuiInputBase-input': { color: '#f44336' }
  }
}));
export default function Filter() {
  const classes = useStyles();
  const { user } = getCurrentUser();
  const [globalState,globalActions] = useGlobal();
  const { role, changwat, amphur, hospcode, casestatusRole } = Permission("dashboard");
  const [changwats, setChangwats] = useState([]);
  const [amphurs, setAmphurs] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const userChangwat = (role==="CM" && user.changwat!==undefined)?user.changwat:{changwatcode:"",changwatname:""};
  const userAmphur = (role==="CM" && user.amphur!==undefined)?user.amphur:{amphur_id:"",amphur_name:""};
  const [filterDisabled, setFilterDisabled] = useState(false);
  
  const onElementChange = (e) => {
    const name = (typeof e.target !== 'undefined') ? e.target.name : e.key;
    const value = (typeof e.target !== 'undefined') ? e.target.value : e.val;
    let val = (value!==null)?value:"";
    if(role!=="CM"){
      if(name==="changwat"){
        let newChwVal = (val!=="")?val:{changwatcode:"",changwatname:""};
        globalActions.setDashboardFilter({...globalState.dashboardFilter,[name]:newChwVal,amphur:{amphur_id:"",amphur_name:""},hospital:{hos_id:"",hos_fullname:"",hos_name:""}});
      }else if(name==="amphur"){
        let newAmpVal = (val!=="")?val:{amphur_id:"",amphur_name:""};
        globalActions.setDashboardFilter({...globalState.dashboardFilter,changwat:globalState.dashboardFilter.changwat,[name]:newAmpVal,hospital:{hos_id:"",hos_fullname:"",hos_name:""}});
      }else{
        let newHosVal = (val!=="")?val:{hos_id:"",hos_fullname:"",hos_name:""};
        globalActions.setDashboardFilter({...globalState.dashboardFilter,[name]:newHosVal});
      }
    }else{
      if(name==="hospital"){
        let newHosVal = (val!=="")?val:{hos_id:"",hos_fullname:"",hos_name:""};
        globalActions.setDashboardFilter({...globalState.dashboardFilter,changwat:userChangwat,amphur:userAmphur,hospital:newHosVal});
      }
    }
  }
  const getData = async (model, val) => {
    try {
      const params = { filter: {} };
      switch (model) {
        case "cchangwats":
          params.filter.fields = { changwatcode: true, changwatname: true };
          params.filter.where = { zonecode: "08" };
          break;
        case "amphurs":
          params.filter.fields = { amphur_id: true, amphur_name: true };
          params.filter.where = { province_id: val };
          break;
        case "hospitals":
          params.filter.fields = { hos_id: true, hos_name: true, hos_fullname: true };
          params.filter.where = { hos_ampid: val };
          break;
        default: ;
      }
      // console.log(JSON.stringify(params));
      const response = await getAll(params, model);
      if (response.status === 200) {
        switch (model) {
          case "cchangwats": 
            let newCW = [{changwatcode:"",changwatname:""}].concat(response.data);
            setChangwats(newCW); 
            break;
          case "amphurs": 
            let newAM = [{amphur_id:"",amphur_name:""}].concat(response.data);
            setAmphurs(newAM); 
            break;
          case "hospitals": 
            let newHS = [{hos_id:"",hos_fullname:"",hos_name:""}].concat(response.data);
            setHospitals(newHS); 
            break;
          default: ;
        }
      }
    } catch (error) { console.log('error', error) }
  };

  const [startEffect, setStartEffect]=useState(true);
  useEffect(() => {
    if(startEffect){
      if (changwats.length <= 0) {
        console.log("I am here");
        getData("cchangwats");
        if(["CM","Coach","Assessor"].includes(role)){
          globalActions.setDashboardFilter({
            ...globalState.dashboardFilter,
            changwat:userChangwat,
            amphur:userAmphur,
            hospital:{
              hos_fullname: "",
              hos_id: "",
              hos_name: ""
            }
          });
          // if(mounted){getData("amphurs",userChangwat.changwatcode);}
          // if(mounted){getData("hospitals",userAmphur.amphur_id);}
          setFilterDisabled(true);
        }
      }
    }
    return ()=>{setStartEffect(false);}
  }, [changwats.length]);
  
  // useEffect(() => {
  // }, [
  //   globalState.dashboardFilter.changwat,
  //   globalState.dashboardFilter.amphur,
  //   globalState.dashboardFilter.hospital
  // ]); 
  return (
    <Grid container spacing={0} style={{ marginTop: '20px' }}>
      <Grid item xs={12} style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, padding: '10px',backgroundColor:'#ffffff' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} >
            <Autocomplete
              id="changwat"
              size="small"
              classes={classes}
              fullWidth
              options={changwats}
              value={globalState.dashboardFilter.changwat}
              disabled={filterDisabled}
              getOptionSelected={(option, value) => {
                return value.changwatname === option.changwatname
              }}
              getOptionLabel={(option) => option.changwatname}
              onChange={(_, newValue) => {
                let val = (newValue!==null) ? newValue.changwatcode : "";
                // console.log({ key: 'changwat', val: newValue },val);
                onElementChange({ key: 'changwat', val: newValue });
                getData("amphurs", val);
              }}
              renderInput={(params) =>
                <TextField {...params}
                  id="changwat_texfield"
                  name="changwat"
                  label="จังหวัด"
                  variant="outlined"
                  onChange={(e) => onElementChange(e)}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              id="amphur"
              size="small"
              classes={classes}
              fullWidth
              options={amphurs}
              value={globalState.dashboardFilter.amphur}
              disabled={filterDisabled}
              getOptionSelected={(option, value) => {
                return value.amphur_name === option.amphur_name;
              }}
              getOptionLabel={(option) => option.amphur_name || ''}
              onChange={(e, newValue) => {
                // console.log(e.target.value,newValue);
                let val = (newValue!==null) ? newValue.amphur_id : "";
                onElementChange({ key: 'amphur', val: newValue });
                getData("hospitals", val);
              }}
              renderInput={(params) =>
                <TextField {...params}
                  id="amphur_texfield"
                  name="amphur"
                  label="อำเภอ"
                  variant="outlined"
                  onChange={(e) => onElementChange(e)}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              id="hospital"
              size="small"
              classes={classes}
              fullWidth
              options={hospitals}
              value={globalState.dashboardFilter.hospital}
              getOptionSelected={(option, value) => value.hos_fullname === option.hos_fullname}
              getOptionLabel={(option) => option.hos_name || ''}
              onChange={(_, newValue) => {
                onElementChange({ key: 'hospital', val: newValue });
              }}
              renderInput={(params) =>
                <TextField {...params}
                  id="hospital_texfield"
                  name="hospital"
                  label="หน่วยงาน"
                  variant="outlined"
                  onChange={(e) => onElementChange(e)}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid >
    </Grid >
  )
}