/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_QUESTIONAIRE } from "../index";

const getQuestnaire = {
  request: (payload:any) => action(GET_QUESTIONAIRE.REQUEST, {data:payload}),
  success: (response: any) => action(GET_QUESTIONAIRE.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_QUESTIONAIRE.FAILURE, { error }),
};

export default getQuestnaire;