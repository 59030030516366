import { UPDATE_OSCCPERSON } from "../../actions";
import { AnyAction } from "redux";
import { UpdateOsccperson } from "../../types/activity";

const initialState:UpdateOsccperson = {
  isFetching: false,
  data: []
};

export default function updateOsccperson(
  state = initialState,
  action: AnyAction
): UpdateOsccperson {
  switch (action.type) {
    case UPDATE_OSCCPERSON.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case UPDATE_OSCCPERSON.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case UPDATE_OSCCPERSON.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
