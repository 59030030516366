/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GroupCasesList from "./groupDialogCaseList";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, Slide, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Actions from "../../actions/activity";
import moment from "moment";
import "moment/dist/locale/th";
import FilterListIcon from '@material-ui/icons/FilterList';
import Filter from './helper/Filter';
import { getHospitalInfo, getQuestionaireType } from "../../services/api";
import _, { isEmpty } from "lodash";
import { GridRowId } from "@material-ui/data-grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransitionProps } from "@material-ui/core/transitions";
import DataGridMobile from './helper/datagridMobile';
import useDeepCompareEffect from "use-deep-compare-effect";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open:true;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editData: Record<any,any>;
}
export default function FormDialog(props:IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))||false;
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const role = globalState.auth.user.response?.user.role;
  const token = globalState.auth.user.response?.id;
  const changwat = globalState.auth.user.response?.user.changwat.changwatcode;
  const amphur = globalState.auth.user.response?.user.amphur.amphur_id;
  const department = globalState.auth.user.response?.user.department.hcode;
  const userinfo = globalState.auth.user.response?.user;
  
  const teamList = (!userinfo?.osccteams)?[]:userinfo.osccteams.map((t:any)=>({id:t.teamCode,label:t.teamName,value:{teamCode:t.teamCode,teamName:t.teamName}}));
  const casesData = globalState.activity.caseList.data;
  const groupsList = globalState.activity.groupListTeams;
  const questionaireList = globalState.activity.questionaireList.data;
  const [cases,setCases]=useState([{id:"",label:"",value: {}}]);
  const [casesList,setCasesList]=useState<any>([]);
  const [searchCase,setSearchCase]=useState<Record<string,any>>({id:"",label:"",value: {}});
  const [groupName, setGroupName] = useState<string>("");
  const [groupTeam, setGroupTeam] = useState<Record<string,any>>({});
  const [qType, setQtype] = useState<Record<string,any>[]>([]);
  const [questionnaireType, setQuestionnaireType] = useState<any>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [tambon, setTambon] = useState("");
  const [filltered, setFilltered] = useState<Record<string,any>>({});
  const [searchText, setSearchText] = useState("");
  const [loading,setLoading]=useState(false);
  const handleClose = () => props.setOpen(false);

  const riskLevel = (riskLevel: string) => { // ✅
    let risk = "";
    switch (riskLevel) {
      case "low":
        risk = "ความเสี่ยงต่ำ";
        break;
      case "medium":
        risk = "ความเสี่ยงปานปลาง";
        break;
      case "high":
        risk = "ความเสี่ยงสูง";
        break;
      case "victim":
        risk = "เหยื่อ";
        break;
      default:
        risk = "อื่นๆ";
        break;
    }
    return risk;
  };

  const getHospital = useCallback(async() => { // ✅
    const response = await getHospitalInfo(null,{hos_id:department});
    setTambon(response.response.data[0].hos_tumid);
  },[department]);

  const calculateBirthdateFromAge = (age: number)=>{
    const currentYear = moment().year();
    const birthYear = currentYear - age;
    const birthdate = moment({ year: birthYear, month: 0, day: 1 });
    return birthdate.format("YYYY-MM-DD");
  }
  
  const genParams = useCallback((filter:Record<string,any>)=>{
    let params : Record<string,any> = {and: []};
    filter=(!_.isEmpty(filltered))?filltered:filter;
    Object.keys(filter).forEach((key:string)=>{
      switch(key){
        case 'changwat' : 
          (filter[key] && filter[key]!==null) && params.and.push({amphur_id: {regexp: "^"+ filter[key]?.id }});
          break;
        case 'ampur' :
          (filter[key] && filter[key]!==null) && params.and.push({amphur_id: filter[key]?.id});
          break;
        case 'tambon' : 
          (filter[key] && filter[key]!==null) && params.and.push({tambol_id: filter[key]?.id});
          break;
        case 'village' :
          (filter[key] && filter[key]!==null) && params.and.push({villagecode: filter[key]?.id});
          break;
        case 'agency' :
          (filter[key] && filter[key]!==null) && params.and.push({hcode: filter[key]?.id});
          break;
        case 'caseStatus' :
          (filter[key] && filter[key].length>0) && params.and.push({caseStatus: {in:filter[key]}});
          break;
        case 'risklevel' :
          (filter[key] && filter[key].length>0) && params.and.push({riskLevel: {in:filter[key]}});
          break;
        case 'severityType' :
          (filter[key] && filter[key].length>0) && params.and.push({severityType: {in:filter[key]}});
          break;
        case 'age' : 
          const formDate = calculateBirthdateFromAge(filter[key][0]);
          const toDate = calculateBirthdateFromAge(filter[key][1]);
          (filter[key] && filter[key].length>0) && params.and.push({birthdate: {between:[toDate, formDate]}});
          // console.log(calculateBirthdateFromAge(filter[key][0]), calculateBirthdateFromAge(filter[key][1]));
          // (filter[key] && filter[key].length>0) && params.and.push({age: {gte:filter[key][0],lte:filter[key][1]}});
          break;
        case 'sex' :
          (filter[key] && filter[key].length>0) && params.and.push({sex: {in:filter[key]}});
          break;
      }
    });
    return params;
  },[filltered]);

  const getCases = useCallback(async(filter:Record<string,any>) => { // ✅
    let params : Record<string,any> = {};
    filter=(!_.isEmpty(filltered))?filltered:filter;
    if(filter.type && filter.type!==null && filter.type==="groupList"){
      params={groupId:filter.group_id};
    }else if( filter.type && filter.type!==null && filter.type==="searchText" ){
      params = (
        {"and":[
          {amphur_id: amphur},
          {"or":[
            {"fname":{"regexp": filter.value}},
            {"lname":{"regexp": filter.value}},
            {"cid":{"regexp": filter.value}}
          ]}
        ]}
      );
    }else{
      if(role==="CM"){
        params=genParams(filter);
        if(params.and.length===0 || params.and.amphur_id==="" ){
          params.and.push({amphur_id: amphur});
        }
      }else if(role==="AdminTeams" || role ==="Teams"){
        params=genParams(filter);
        if(params.and.length===0  || params.and.amphur_id==="" ){
          params.and.push({tambol_id: tambon});
        }
      }
    }
    const newParams = {
      where: params, 
      pagination: {
        limit: 100, 
        skip: 0
      }
    };
    // setFilltered(params);
    // if(teamCodes && teamCodes.length>0){
      // console.log("editGroup GetCases",newParams);
      timer.current = setTimeout(() => {
        dispatch(Actions.GetCases.request(newParams));
        setLoading(false); 
      },150);
    // }else{
    //   setLoading(false); 
    // }
    setLoading(false);
  },[filltered,genParams,dispatch, amphur, role, tambon]);

  const getQType = useCallback(async()=>{
    const result = await getQuestionaireType(token);
    if(result.success){
      const qTypeOptions = result.response.data.map((m:any,i:number)=>({id: i, label: m.questionnaire_type, value: m.questionnaire_type}));
      setQtype(qTypeOptions);
    }
  },[token]);

  useEffect(() => { // ✅
    let mounted = true;
    if(mounted){
      if(token) getQType();
    }
    return()=>{mounted = false};
  },[getQType]);
  
  const getEditParams = ()=>{ // ✅ หาผลต่างระหว่างเคสที่เปลี่ยนแปลง
    const prevCID = props.editData.member.map((item:any)=>item.cid);
    const currentCID = casesList.map((item:any)=>item.cid);
    const toRemove = _.difference(prevCID,currentCID);
    const toInsert = _.difference(currentCID,prevCID);

    console.log("prevCID",prevCID);
    console.log("currentCID",currentCID);
    console.log("toRemove",toRemove);
    console.log("toInsert",toInsert);

    let params = {
      toRemove: {},
      toInsert: {}
    };
    if(toRemove.length>0){
      params.toRemove = {
        where:{cid:{in:toRemove}},
        data:{ caseStatus:"new", groupId: null, osccTeamId:null }
      }
    }
    if(toInsert.length>0){
      params.toInsert = {
        where:{cid:{in:toInsert}},
        data:{ 
          caseStatus: props.editData.groupStatus, 
          groupId: props.editData.id,
          osccTeamId: [groupTeam.id||props.editData.teams.teamCode]
        }
      }
    }
    return params;
  }
  
  const addGroupCase=()=>{ // ✅ เพิ่มเคสเข้าไปในตารางรอบันทึก
    
    const tempCasesList = [...casesList];
    const newCasesList = tempCasesList.map((item:any)=>{
      delete item.caseStatus;
      return item;
    })
    delete(searchCase.value.caseStatus);
    if(!_.isEmpty(searchCase.value)){
      if(_.some(newCasesList,(v:any)=>v.id===searchCase.value.id)){
        alert("มีรายชื่อนี้แล้วในตาราง เลือกซ้ำไม่ได้ครับ");
      }else{
        newCasesList.unshift(searchCase.value);
        setCasesList(newCasesList);
        setSearchCase({id:"",label:"",value: {}});
      }
    }
  }

  const removeGroupCase=useCallback((id:GridRowId)=>{ // ✅ ลบเคสออกจากตารางรอบันทึก
    setTimeout(() => {
      setCasesList((prevRows:any)=>prevRows.filter((row:any)=>row.id !== id));
    });
  },[]);

  const saveGroupCase=()=>{ // ✅ บันทึกกลุ่ม
    if(groupName === ""){
      alert("กรุณาระบุชื่อกลุ่ม");
    }else if(_.isEmpty(groupTeam)){
      alert("กรุณาเลือกทีม");
    }else if(!questionnaireType || !questionnaireType.value){
      alert("กรุณาเลือกแบบประเมิน");
    }else if(casesList.length===0){
      alert("กรุณาเพิ่มเคสเข้ากลุ่ม");
    }else{
      // console.log(questionnaireType);
      const _questionaireGroup = questionaireList.filter((f:any)=>(f.questionnaire_group && f.questionnaire_type===questionnaireType.value)).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });
      const _questionaireSingle = questionaireList.filter((f:any)=>(!f.questionnaire_group && f.questionnaire_type===questionnaireType.value)).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });
      
      const prevData = props.editData.member;
      const prevGroupStatus = props.editData.groupStatus;
      const prevList: string[] = prevData.map((item:any)=>item.cid);
      const newList: string[] = casesList.map((item:any)=>item.cid);
      const toRemove = _.difference(prevList,newList);
      const toInsert = _.difference(newList,prevList);
      const _groupId = props.editData.id;
      const _groupName = groupName;
      const _groupTeam = groupTeam.value;
      const _member = casesList.map((c:any)=>{ 
        if(c.osccTeamId && Array.isArray(c.osccTeamId)){
          if(!c.osccTeamId.includes(_groupTeam.teamCode)){
            c.osccTeamId.push(_groupTeam.teamCode);
          }
        }else{
          c["osccTeamId"] = [_groupTeam.teamCode];
        }
        if(!_.isEmpty(props.editData.inform) ){
          c["inform"]=true;
        }

        c["groupId"] = _groupId;
        return c;
      });
      const _dupdate = moment().format("YYYY-MM-DD hh:mm");
      // console.log(props.editData);
      const prevAssign: Record<string,any>[] = props.editData.osccperson_assign.filter((f:any)=>f.type!=="group");
      const newAssign: Record<string,any>[] = casesList;
      // console.log("prevAssign",prevAssign);
      // console.log("newAssign",newAssign);
      const insertAssignGroup = {
        type: "group",
        groupId: _groupId,
        teams: _groupTeam,
        questionaire: _questionaireGroup,
        assignDatetime: moment().format("YYYY-MM-DD hh:mm")
      };
      const toRemoveAssign = _.differenceBy(prevAssign,newAssign,'cid').map((a) =>({ id: a.id }));
      const toInsertAssign: any = _.differenceBy(newAssign,prevAssign,'cid').map((a)=>({
        type: "single",
        teams: _groupTeam,
        questionaire: _questionaireSingle,
        cid: a.cid,
        fname: a.fname,
        lname: a.lname,
        sex: a.sex,
        age: a.age,
        risk: a.riskLevel,
        groupId: _groupId,
        assignDatetime: _dupdate
      }));
      const toUpdateAssign = props.editData.osccperson_assign.map((m:any)=>{
        m.questionaire = (m.type==="group") ? _questionaireGroup : _questionaireSingle
        return m;
      });
      const haveGroup = props.editData.osccperson_assign.some((s:any)=>s.type==="group");
      if(!haveGroup){
        toInsertAssign.push(insertAssignGroup);
      }
      if((prevGroupStatus!=='new' && toUpdateAssign.length>0)){
        
      }

      // console.log("toRemoveAssign",toRemoveAssign);
      // console.log("toUpdateAssign",toUpdateAssign);

      const updateParams = {
        updateGroup: {
          params: {
            id: props.editData.id,
            data: {
              groupName: _groupName,
              groupQuestionnaireType: questionnaireType.value,
              teams: _groupTeam,
              ...((_member.length>0) && {member: _member}),
              updateDatetime: _dupdate
            }
          },
          successMessage:"แก้ไขกลุ่มสำเร็จ"
        },
        ...((toRemove.length>0) && { removeMembers: { 
          params: prevData.filter((f:any)=>toRemove.includes(f.cid)).map((m:Record<string,any>)=>{
            let caseTeam: string[]|null = null;
            if(m.osccTeamId && Array.isArray(m.osccTeamId)){
              const teamArray = _.remove(m.osccTeamId, item => item === m.osccTeamId);
              caseTeam = _.isEmpty(teamArray) ? null : teamArray;
            }
            return {
              where:{ id: m.id },
              data:{ 
                caseStatus:"new", 
                groupId: null, 
                osccTeamId: caseTeam
              }
            };
          })
        }}),
        ...((toInsert.length>0) && { insertMembers: { 
          params: _member.filter((f:any)=>toInsert.includes(f.cid)).map((m:any)=>(
            {
              where:{ id: m.id },
              data:{ 
                caseStatus: props.editData.groupStatus || "new", 
                groupId: m.groupId, 
                osccTeamId: m.osccTeamId
              }
            }
          ))
        }}),
        ...((prevGroupStatus!=='new' && toRemoveAssign.length>0) && { deleteAssign: { params: toRemoveAssign } }),
        ...((prevGroupStatus!=='new' && toInsertAssign.length>0) && { insertAssign: { data: toInsertAssign } }),
        ...((prevGroupStatus!=='new' && toUpdateAssign.length>0) && { updateAssign: { data: toUpdateAssign } }),
        ...((prevGroupStatus!=='new' && toRemoveAssign.length>0) && {
          removeAnswer: {
            params: {
              where: { group_id: _groupId, assigned_id: { inq: toRemoveAssign.map((data:{id:string})=>data.id) } }
            }
          }
        }),
        getGroups: { params: { teamCodes: _groupTeam.teamCode } }
      };
      
      // console.log("questionaireList",questionaireList);
      // console.log("questionnaireType.value",questionnaireType.value);
      // console.log("_questionaire",_questionaire);
      // console.log("updateParams",updateParams);
      dispatch(Actions.UpdateGroup.request(updateParams));
    }
  }

  const allFunction = useCallback((params:any)=>{
    return {
      removeGroupCase: ()=>removeGroupCase(params.id)
    }
  },[removeGroupCase]);

  const [mobileData, setMobileData] = useState([]);
  const genDataForMobile = useCallback(()=>{
    // console.log("genDataForMobile",casesList);
    // if(casesList.length>0){
      const riskLevel = [
        {id:1, label:'ต่ำ',value:'low'},
        {id:2, label:'ปานกลาง',value:'medium'},
        {id:3, label:'สูง',value:'high'},
        {id:4, label:'เหยื่อ',value:'victim'},
        {id:5, label:'ปกติ',value:'normal'},
        {id:6, label:'อื่นๆ',value:'other'}
      ];
      const newData: any = casesList.map((item:any)=>{
        return {
          name: item.fname+" "+item.lname,
          status: "",
          data: [
            { 
              label: "เลขบัตร ปชช.",
              value: item.cid
            },
            { 
              label: "เพศ",
              value: item.sex
            },
            { 
              label: "อายุ",
              value: item.age+" ปี"
            },
            { 
              label: "ความรุนแรง",
              value: riskLevel.filter((f:any)=>f.value===item.riskLevel).map((m:any)=>m.label)[0]
            }
          ],
          allFunction: allFunction(item),
          manage:[]
        };
      });
      // console.log(newData);
      // if(newData.length>0){
        console.log("setMobileData",newData);
        setMobileData(newData)
      // }
    // }
  },[casesList]);

  const timer = useRef<NodeJS.Timeout|null>(null);
  useEffect(() => {
    if(searchText && searchText!==""){
      clearTimeout(timer.current as NodeJS.Timeout);
      timer.current = setTimeout(() => {
        getCases({type:"searchText",value:searchText});
      },1000);
    }else{
      clearTimeout(timer.current as NodeJS.Timeout);
      // timer.current = setTimeout(() => {
      //   getCases({});
      // },1000);
    }
    return () => clearTimeout(timer.current as NodeJS.Timeout);
  },[searchText]);

  useEffect(() => { // ✅ หารหัสตำบลของหน่วยบริการจาก hcode
    let mounted = true;
    if(mounted){getHospital();}
    return()=>{mounted = false};
  }, [getHospital]);

  useEffect(() => { // ✅ ถ้ารหัสตำบลมีการเปลี่ยนแปลง ค่อย getCase()
    let mounted = true;
    if(mounted){
      if(tambon && tambon!==""){getCases({});}
    }
    return()=>{mounted = false};
  },[getCases,tambon]);

  useDeepCompareEffect(()=>{ // ✅ ถ้า getCase เสร็จให้แปลง caselist เป็น options
    if(casesData.length>0){
      const optionsValue = casesData.filter((f:any)=>{// กรองเอาเฉพาะเคสที่ยังไม่มี groupId
        return ( typeof f.groupId === "undefined" || f.groupId ===null)
      }).map((item)=>{
        //.replace(item.cid.substring(3,10),"xxxxxxx")
        return {
          id:item.cid,
          label: "["+item.cid+"] "+item.fname+" "+item.lname+" | เพศ"+item.sex+" | อายุ "+item.ageYM+" ปี | "+riskLevel(item.riskLevel),
          value: item
        }
      });
      setCases(optionsValue);
    }else{
      setCases([{id:"",label:"",value: {}}]);
    }
  },[casesData]);

  useDeepCompareEffect(()=>{ // ✅ แสดงข้อมูลเดิมที่ต้องการแก้ไข
    // console.log("props.editData",props.editData);
    const tempEditData = Object.assign(props.editData);
    const teams = (!_.isEmpty(tempEditData.teams))?tempEditData.teams:{};
    setGroupName(tempEditData.groupName);
    setGroupTeam({id: teams.teamCode, label: teams.teamName, value:{teamCode: teams.teamCode, teamName: teams.teamName}});
    setQuestionnaireType(qType.filter((f:any)=>f.value===tempEditData.groupQuestionnaireType)[0]||null);
    setCasesList(tempEditData.member);
  },[props.editData,qType]);

  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลเคส มีการเปลี่ยนแปลงให้ปิด Dialog Filter
    setShowFilter(false);
  },[globalState.activity.caseList.data]);

  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลเคส มีการเปลี่ยนแปลงให้ปิด Dialog Filter
    // console.log("useDeepCompareEffect casesList change",casesList);
    // if(casesList.length>0){
      // console.log("useDeepCompareEffect casesList change",casesList);
      if(isMobile){genDataForMobile();}
    // }
  },[casesList]);

  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลกลุ่ม มีการเปลี่ยนแปลงให้ปิด Dialog
    if(groupsList.data.length>0){
      setLoading(false);
    }
  },[groupsList.data]);

  return (
    <Dialog 
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      TransitionComponent={Transition}
      open={props.open||(globalState.activity.insertGroup.isFetching)} 
      onClose={handleClose} 
      disableBackdropClick={true}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle id="form-dialog-title" disableTypography style={{paddingTop:"24px"}}><Typography variant="h4" style={{marginBottom:0}}>แก้ไขกลุ่ม</Typography></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              autoFocus
              id="groupName"
              label="ชื่อกลุ่ม"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              defaultValue={groupName}
              onChange={(e)=>setGroupName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="searchTeam"
              fullWidth
              freeSolo={true}
              options={teamList}
              value={groupTeam}
              getOptionLabel={(option) => option.label}
              size="small"
              style={{ height: "100%" }}
              onChange={(_, value) => {if(value) setGroupTeam(value)}}
              renderInput={
                (params) => 
                <TextField {...params} 
                  label="ทีม"
                  variant="outlined" 
                  inputProps={{ ...params.inputProps, readOnly: true, autoComplete: 'off' }}
                  fullWidth
                  />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="questionnaire_type"
              fullWidth
              freeSolo={true}
              options={qType}
              value={questionnaireType}
              getOptionLabel={(option) => option.label}
              size="small"
              style={{ height: "100%" }}
              onChange={(_, value) => { if(value)setQuestionnaireType(value)}}
              renderInput={
                (params) => 
                <TextField {...params} 
                  inputProps={{ ...params.inputProps, readOnly: true, autoComplete: 'off' }}
                  label="แบบประเมิน"
                  variant="outlined" 
                  fullWidth/>
              }
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Autocomplete
              id="searchCases"
              fullWidth
              freeSolo={true}
              options={cases}
              value={searchCase}
              loading={globalState.activity.caseList.isFetching}
              getOptionLabel={(option:any) => {
                // console.log(option);
                return option.label
              }}
              size="small"
              style={{height:'100%'}}
              disabled={globalState.activity.caseList.isFetching}
              onChange={(_, value:any) => setSearchCase(value)}
              onInputChange={(e:any)=>{if(e!==null){ setSearchText(e.target.value);}}}
              renderInput={(params) => 
                <TextField {...params} 
                  label={globalState.activity.caseList.isFetching?<CircularProgress size={16}/>:"ค้นหาเคส"} 
                  variant="outlined" 
                  fullWidth
                  />
              }
            />
            
          </Grid>
          <Grid item xs={6} md={2}>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary"
              startIcon={<FilterListIcon />} //SearchIcon
              style={{ height: "100%" }}
              onClick={()=>setShowFilter(true)}>
                ตัวกรอง
            </Button>
          </Grid>
          <Grid item xs={6} md={2}><Button variant="contained" color="primary" fullWidth style={{height:'100%'}} onClick={addGroupCase}>เพิ่มเข้ากลุ่ม</Button></Grid>
          <Grid item xs={12} style={{color:"red"}}>
            ** ค้นหาเคสด้วย ชื่อ หรือ สกุล หรือ เลขบัตรประชาชน อย่างใดอย่างหนึ่ง **
          </Grid>
          <Grid item xs={12}>
            {
              (isMobile) ?
                <DataGridMobile 
                  data={mobileData||[]} 
                  removeGroupCase={removeGroupCase}
                  page="/groupDialogEdit"
                /> 
                :
                <GroupCasesList casesList={casesList} removeGroupCase={removeGroupCase}/>
            }
          </Grid>
        </Grid>
        <Filter type="group" open={showFilter} setOpen={setShowFilter} search={getCases} setFiltered={setFilltered}/>
      </DialogContent>
      <DialogActions style={{padding:'12px 24px 24px'}}>
        <Button onClick={handleClose} variant="outlined" color="primary">ยกเลิก</Button>
        <ButtonLoading 
          variant="contained" 
          color="primary"
          onClick={saveGroupCase} 
          loading={loading}
          >
            บันทึก
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}
