import React from 'react';
import { TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

export default function GenTextfield(data:any){
  const useForm = useFormContext();
  const {props} = data;

  return (
    <Controller
			name={props.name || uuidv4()}
			control={useForm.control}
			rules={props.validation}
			render={({ field: { onChange, value }, fieldState: { error } }: any) => {
				return (
          <TextField 
            {...props}
            value={value || ''}
            onChange={onChange}
          />
        )
      }}
    />
  )
}