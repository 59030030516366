// import { LoginResponse } from '../../types';
import { createRequestType } from '../const';
import { action } from '../const';

export const loginAction = {
  request: (username: string, password: string) => action(LOGIN.REQUEST, { username, password }),
  success: (data: any) =>
    action(LOGIN.SUCCESS, {
      data,
    }),
  failure: (error: any) =>
    action(LOGIN.FAILURE, {
      error,
    }),
};

export const LOGIN = createRequestType('LOGIN');

export const LOGOUT = createRequestType('LOGOUT');
