/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdDeleteForever as DeleteBin,
  MdAddCircle as BtMdAddCircle,
  MdAssignment as MdAssignmentIcon,
  MdUndo as MdUndoIcon
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";

import useGlobal from "../../store";
import * as API from "../../services/UniversalAPI";
import * as SI from "../../services/SaveIntervention";
import CaseInfoBar from "./CaseInfoBar";
import ConsentServiceSlot from "../consent/ConsentServiceSlot";

import { useTranslation } from "react-i18next";
// import { isParameter } from "typescript";

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  },
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  tdQDetail: {
    verticalAlign: "top",
  },
  thead: {
    backgroundColor: "#cdedff",
  },
  trA: {
    backgroundColor: "transparent",
  },
  trB: {
    backgroundColor: "#efefef",
  },
}));

export default function CasePlan(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();

  const [globalState, globalActions] = useGlobal();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [planService, setPlanService] = useState([]);
  const [focusedPlanService, setFocusedPlanService] = useState({
    cause: "",
    startDate: null,
    endDate: null,
    planDate: new Date(),
    plannerId: userinfo.user.id,
    planAt: "",
    place: "",
    intervention: [
      "evaluation"
    ],
    serviceslotId: null,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');
  const [teamUsersData, setTeamUsersData] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [planAddActivity, setPlanAddActivity] = useState([]);
  const [openPlan, setOpenPlan] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [personData, setPersonData] = useState([]);
  const [osccPerson, setOsccPerson] = useState([]);
  // const [statePlan, setStatePlan] = useState({ personId: personData.id, osccPersonId: props.location.state.id, cause: "", planDate: "", });
  const [statePlan, setStatePlan] = useState({ personId: "", osccPersonId: props.location.state.id, cause: "", planDate: "", });
  const [stateActivity, setStateActivity] = useState({ questionName: "", questionId: "", teamName: "", teamCode: "", startDate: null, endDate: null });
  const [answererData, setAnswererData] = useState([
    { answererId: 1, answererName: 'ผู้รับบริการ' },
    { answererId: 2, answererName: 'ญาติ/ผู้ดูแล' },
    { answererId: 3, answererName: 'เจ้าหน้าที่' },
  ]);
  const [serviceSlotData, setServiceSlotData] = useState([]);
  const [selectedServiceSlot, setSelectedServiceSlot] = useState({});
  const [selectTeamValue, setSelectTeamValue] = useState('');
  const [selectAnswererValue, setSelectAnswererValue] = useState('');
  const [SSGroup, setSSGroup] = useState([]);
  const [formData, setFormData] = useState({
    serviceSlot: null,
    cause: '',
    startDate: null,
    endDate: null,
  });
  const [openConsent, setOpenConsent] = useState(false);
  const [informData, setInformData] = useState({});
  const [consentData, setConsentData] = useState({});
  const [focusedActivity, setFocusedActivity] = useState({});


  const [openMatchDialog, setOpenMatchDialog] = useState(false);
  const [relativeData, setRelativeData] = useState([
    { relativeId: 1, relativeName: 'แม่ผู้ให้กำเนิด' },
    { relativeId: 2, relativeName: 'พ่อผู้ให้กำเนิด' },
    { relativeId: 3, relativeName: 'แม่เลี้ยง/พ่อเลี้ยง' },
    { relativeId: 4, relativeName: 'พี่หรือน้อง/พี่บุญธรรมหรือน้องบุญธรรม' },
    { relativeId: 5, relativeName: 'ปู่ ตา / ย่า ยาย' },
    { relativeId: 6, relativeName: 'ทวด' },
    { relativeId: 7, relativeName: 'ลุง / ป้า / น้า / อา' },
    { relativeId: 8, relativeName: 'ญาติ' },
    { relativeId: 9, relativeName: 'พ่อแม่อุปถัมภ์' },
    { relativeId: 10, relativeName: 'อื่นๆ' }
  ]);
  const [careGiverData, setCareGiverData] = useState({
    cid : null,
    fname: null,
    lname : null,
    nameCalledByCase : null,
    relation : '',
    relationSpecify : null,
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);



  const OPTIONS_LIMIT = 500;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleChangeStateActivity = (e, d, g, lookupIdField) => {
    let idField = 'id';
    if (typeof lookupIdField !== 'undefined') {
      if (lookupIdField !== null) {
        idField = lookupIdField;
      }
    }
    let keyId = null;
    // let defaultSetting = [];
    let thisQuestionnaire = {};
    if (typeof d !== "undefined") {
      if (d) {
        d.forEach((i) => {
          if (i[g].toString() === e.target.value.toString()) {
            keyId = i[idField];
            // defaultSetting=i.defaultSetting;
            thisQuestionnaire = i;
          }
          return null;
        });
      }
    }

    let temp_stateActivity = JSON.parse(JSON.stringify(stateActivity));
    if (g === "teamName") {
      if (typeof thisQuestionnaire.teamCode !== 'undefined') {
        if (typeof thisQuestionnaire.teamName !== 'undefined') {
          setSelectTeamValue(thisQuestionnaire.teamName);
          temp_stateActivity['teamCode'] = thisQuestionnaire.teamCode;
          temp_stateActivity['teamName'] = thisQuestionnaire.teamName;
        }
      }
    }

    if (g === "answererName") {
      if (typeof thisQuestionnaire.answererId !== 'undefined') {
        if (typeof thisQuestionnaire.answererName !== 'undefined') {
          setSelectAnswererValue(thisQuestionnaire.answererName);
          temp_stateActivity['answererId'] = thisQuestionnaire.answererId;
          temp_stateActivity['answererName'] = thisQuestionnaire.answererName;
        }
      }
    }

    const name = e.target.name;
    const id = e.target.id;
    if (keyId) {
      temp_stateActivity[name] = e.target.value;
      temp_stateActivity[id] = keyId;
    } else {
      temp_stateActivity[name] = e.target.value;
    }
    setStateActivity({ ...stateActivity, ...temp_stateActivity });
  };

  const changeServiceSlot = (e) => {
    serviceSlotData.forEach(s => {
      if (s.id === e.target.value) {
        setSelectedServiceSlot(s);
        if (typeof s.resultTemplate !== 'undefined') {
          setQuestionnaireData(s.resultTemplate);
        }
        else {
          setQuestionnaireData([]);
        }
      }
    });
  }

  const handleClickAddPlan = () => {
    setOpenPlan(true);
  };

  const resetPlanOsccPerson = (e) => {
    let x = osccPerson;
    x["osccTeamId"] = null;
    setPlanAddActivity({ ...osccPerson, ...x });
    // console.log(x);
    API.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        
      }

    });
  };

  const handleClose = () => {
    setOpenPlan(false);
    setOpenActivity(false);
  };

  const clickClosePlan = () => {
    setOpenActivity(false);
    setSSGroup([]);
    setFocusedPlanService({});
    setFormData({
      serviceSlot: null,
      cause: '',
      startDate: null,
      endDate: null,
    });
  };

  const getPlanService = async (ssData, param_personData, clickPlanDetail_aps) => {
    // console.log('getPlanService--- ');
    let filter = {
      filter: {
        include: {
          relation: 'serviceslot'
        },
        where: {
          osccPersonId: props.location.state.id
        }
      }
    };
    // console.log(JSON.stringify(filter.filter));
    let response = await API.getAll(filter, "planservices");
    
    if (response.status === 200) {
      if (response.data) {
        if (response.data.length>0) {
          // setPlanService(response.data);
          // let allPlanService=response.data;
          // console.log(response.data);
          let x = response.data;
          // console.log(x.hcode);
          let filter_hcodeList = [];
          x.forEach(i => {
            if (typeof i.hcode !=='undefined') {
              filter_hcodeList.push({ hcode: i.hcode});
            }
          });
          let allSS = [];
          // let evaluation = [];
          
          let filterB = {filter: {where: {}}};
          if(filter_hcodeList.length>0){
            filterB.where = {
              // hcode: globalState.currentUser.user.department.hcode,
              or : filter_hcodeList
            }
          }
          // console.log(JSON.stringify(filterB));
          let responseB = await API.getAll(filterB, "serviceslots");
          // console.log(responseB);
          if (responseB.status === 200) {
            if (responseB.data) {
              allSS = responseB.data;
              // console.log(allSS);
              let interventionIdList = [];
              x.forEach(i => {
                i.serviceslotList.forEach(ev => {
                  if (typeof ev.interventionId !== 'undefined') {
                    if (ev.interventionId !== null && ev.interventionId !== '') {
                      interventionIdList.push(ev.interventionId);
                    }
                  }
                  // let ed = ev;
                  let questionnaireId = null;
                  let questionnaire = null;
                  let inform = null;
                  let consent = null;
                  allSS.forEach(s => {
                    if (s.id === ev.serviceslotId) {
                      // console.log(s);
                      // console.log(s.id , ev.serviceslotId, (s.id === ev.serviceslotId?'---------':''));
                      questionnaireId = s.resultTemplate[0].id;
                      questionnaire = s.resultTemplate[0];
                      inform = s.inform;
                      consent = s.consent;
                    }
                  });
                  ev['planServiceId'] = i.id;
                  ev['questionnaireId'] = questionnaireId;
                  ev['questionnaire'] = questionnaire;
                  ev['inform'] = inform;
                  ev['consent'] = consent;
                  // evaluation.push(ed);
                });
              });

              // console.log(interventionIdList);
              if (interventionIdList.length > 0) {
                let filterC_or = [];
                interventionIdList.forEach(iil => {
                  filterC_or.push({ id: iil });
                });
                let filterC = {
                  filter: {
                    where: {
                      or: filterC_or
                    }
                  }
                };
                // console.log(JSON.stringify(filterC.filter));
                let responseC = await API.getAll(filterC, "interventions");
                if (responseC.status === 200) {
                  if (responseC.data) {
                    // console.log(responseC.data);
                    responseC.data.forEach(i1 => {
                      i1.activities.homeservice.forEach(i2 => {
                        if (i2.serviceType==="oscc") {
                          // console.log(i2);
                          i2.questionnaire.forEach(i3 => {
                            x.forEach(i4 => {
                              i4.serviceslotList.forEach(i5 => {
                                // console.log(i5);
                                // console.log(i5.interventionId,'|',i1.id);
                                // console.log(i5.planServiceId,'|',i3.planServiceId);
                                // console.log(i5.sequenceId,'|',i3.sequenceId);
                                if (i5.interventionId===i1.id && i5.planServiceId===i3.planServiceId && i5.sequenceId===i3.sequenceId) {
                                  i5.inform=i3.inform;
                                  i5.consent=i3.consent;
                                  i5['interventionData']=i1;
                                  // console.log(i1);
                                  // console.log(i3);
                                }
                              });
                            });
                          });
                        }
                      });
                    });
                    // console.log(x);
                    setPlanService(x);
                  }
                }
              }
              else {
                // console.log(x);
                setPlanService(x);
              }
            }
          }

          // console.log(props.location.state.answerData);
          if (typeof clickPlanDetail_aps !== 'undefined') {
            clickPlanDetail(clickPlanDetail_aps, ssData);
          }else {
            if (typeof props.location.state.answerData != 'undefined') {
              if (Object.keys(props.location.state.answerData).length > 0) {
                let stateData = JSON.parse(JSON.stringify(props.location.state));
                let thisPlanServiceId = stateData.answerData.planServiceId;
                // console.log(thisPlanServiceId);
                delete stateData['answerData'];
                // console.log(stateData);
                history.replace('/caseplan', stateData );
                // (personData, planservice_data,param_answerData,param_userId,param_userFullName, param_hcode, param_hname)
                let temp_personData = props.location.state;
                if (typeof param_personData !== 'undefined') {
                  temp_personData = param_personData;
                }
                await SI.saveInterventions(
                  temp_personData,
                  x,
                  props.location.state.answerData,
                  globalState.currentUser.user.id,
                  globalState.currentUser.user.fullname,
                  globalState.currentUser.user.department.hcode,
                  globalState.currentUser.user.department.hos_name
                );
                x.forEach(aps => {
                  if (aps.id===thisPlanServiceId) {
                    // console.log(aps);
                    getPlanService(ssData, param_personData, aps);
                  }
                });

              }
            }
          }
        }
      }
    }
  };

  const getPerson = async () => {
    let response = await API.getAll(
      { filter: { where: { cid: props.location.state.cid } } },
      "people"
    );
    if (response.status === 200) {
      if (response.data.length > 0) {
        let d = response.data[0];
        let tambolInfo = "";
        let paramTambol = {
          filter: {
            limit: 1,
            where: { addressid: d.chwpart + d.amppart + d.tmbpart }
          }
        };
        let responseTambol = await API.getAll(paramTambol, "tambols");
        if (responseTambol.status === 200) {
          if (responseTambol.data.length > 0) {
            // console.log(responseTambol.data);
            tambolInfo = responseTambol.data[0].full_name;
          }
        }
        d['addressInfo'] = d.addrpart + ' ม.' + parseInt(d.moopart) + ' ' + tambolInfo;
        d['riskLevel'] = props.location.state['riskLevel'];
        d['severityType'] = props.location.state['severityType'];
        // console.log( props.location.state['severityType']);
        // console.log(props.location.state);
        // console.log(props.location.state['mental'], props.location.state['physical'], props.location.state['sexual']);
        // console.log(d);
        setPersonData(d);
        let x = statePlan;
        x["personId"] = response.data[0].id;
        setStatePlan({ ...statePlan, ...x });
        getServiceSlot(d);
      }
    }
  };

  const getOsccPerson = async () => {
    let response = await API.getAll(
      // { filter: { where: { personId: personData.id } } },
      { filter: { where: { id: props.location.state.id } } },
      "osccpeople"
    );
    //  console.log(JSON.stringify({ filter: { where: { osccPersonId: props.location.state.id} } }));
    // console.log(response.data[0]);
    setOsccPerson(response.data[0]);
    getPerson();
  };

  const getTeamUsers = async () => {
    let xParams = {
      filter: {
        // limit: 20,
        where: {
          and: [
            { 'amphur.amphur_id': userinfo.user.amphur.amphur_id },
            { "application": { "like": "OSCC", "options": "i" } }
          ]
        },
        order: "department.hcode DESC"
      }
    };
    let response = await API.getAll(xParams, 'teamusers', '3009');
    if (response.status === 200) {
      if (response.data) {
        setTeamUsersData(response.data);
        // console.log(response.data);
        let t = [];
        response.data.forEach(i => {
          if (typeof i.osccteams !== 'undefined') {
            i.osccteams.forEach(z => {
              let n = 0;
              t.forEach(v => {
                if (v.teamCode === z.teamCode) {
                  n = 1;
                }
              });
              let headHcode = '';
              if (parseInt(z.memberTypeId) === 1) {
                headHcode = i.department.hcode;
              }
              let teamData = { teamCode: z.teamCode, teamName: z.teamName, headHcode: headHcode, anyHcode: i.department.hcode };
              if (n === 0) {
                t.push(teamData);
              }
            });
          }
        });
        let tt = [];
        t.forEach(i => {
          if (i.headHcode === '') {
            tt.push({ teamCode: i.teamCode, teamName: i.teamName, hcode: i.headHcode });
          }
          else {
            tt.push({ teamCode: i.teamCode, teamName: i.teamName, hcode: i.anyHcode });
          }
        });
        setTeamList(tt);
      }
    }
  }

  const getServiceSlot = async (param_personData) => {
    // console.log('getServiceSlot-- ');
    // console.log(props.location.state.amphur_id);
    let ampurID = userinfo.user.amphur.amphur_id;
    if (typeof userinfo.user.coach !== 'undefined') {
      if (userinfo.user.coach === 'OSCC') {
        ampurID=props.location.state.amphur_id;
      }
    }

    let xParamsH = {
      filter: {
        where: {
          hos_ampid: ampurID
        }
      }
    };
    
    let responseH = await API.getAll(xParamsH, "hospitals");
    if (responseH.status===200) {
      if (responseH.data.length>0) {
        let hcodeList = [];
        responseH.data.forEach(i => {
          hcodeList.push({hcode:i.hos_id});
        });

        let xParams = {
          filter: {
            where: {
              and: [
                { serviceType: "OSCC" },
                { serviceDetail: { in: ['evaluation'] } },
                { serviceSlotType: process.env.REACT_APP_VERSION },
                { or : hcodeList }
              ]
            },
            order: "hcode DESC"
          }
        };
        let response = await API.getAll(xParams, "serviceslots");

        if (response.status===200) {
          if (response.data.length>0) {
            let ssData = response.data;
            setServiceSlotData(ssData);
            getPlanService(ssData, param_personData);
          }
        }

      }
    }
  };


  const planListRows = () => {
    let r = [];
    // console.log(planService);
    if (planService.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      planService.forEach((i) => {
        // console.log(i);
        ++n;
        if (n % 2 === 0) {
          rowColor = "#EEEEEE";
        }
        let isCompleted = false;
        if (typeof i.completeStatus !== 'undefined') {
          isCompleted = true;
        }
        r.push(
          <Grid key={API.genKey()} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: isDesktop?20:5, marginTop: 3, padding: 2, backgroundColor: rowColor }}>
            <Grid container direction={isDesktop ? "row" : "column-reverse"}>
              <Grid item xs={12} sm={10} style={{ marginTop: isDesktop?0:-30 }}>
                <Grid container justify="flex-start" style={{ padding: 5 }}>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {isDesktop ? "" : <b>ชื่อแผน: </b>}
                    {i.cause}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>เริ่ม: </b>}
                    {API.dateTHs(i.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>สิ้นสุด: </b>}
                    {API.dateTHs(i.endDate)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container justify="flex-end">
                  {isCompleted ? (
                    <Button
                      onClick={() => clickCompletedPlanDetail(i)}
                      variant="contained" color="primary"
                      startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "160px", maxWidth: "40px", color: "white", }}
                    />
                  ) : (
                    <Button
                      onClick={() => { clickPlanDetail(i); }}
                      variant="contained" color="primary"
                      startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }}
                    />
                  )}
                  {(!isCompleted && localStorage.getItem("focusedCaseStatus") === 'newCase') && (
                    <Button
                      onClick={() => clickSentTeam(i)}
                      variant="contained" color="primary"
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "70px", maxWidth: "40px", color: "white", }}
                    >
                      ส่งทีม
                    </Button>
                  )}
                  {(!isCompleted && localStorage.getItem("focusedCaseStatus") === 'newCase') && (
                    <Button
                      onClick={() => { setFocusedPlanService(i); setDialogSet('deletePlan'); setDialogText('ยืนยันลบแผนนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <DeleteBin style={{ marginLeft: 11, marginTop: -2 }} />
                      }
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }}
                    />
                    // <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={() => { setDialogSet('recieveCase'); setDialogText('ยืนยันรับเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}></Button>
                  )}
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      });
    }
    else {
      r.push(
        <Grid key={API.genKey()} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: isDesktop?20:5, marginTop: 3, padding: 2, textAlign: 'center' }}>
          ยังไม่มีแผนการดูแล
        </Grid>
      );
    }
    return r;
  };

  const clickDeleteActivity = (activity) => {
    let x = focusedPlanService.intervention.evaluation;

    // ลบด้วยคำสั่ง delete จะเกิด element null ใน DB 
    var x_deleted = x.filter(function (i) {
      if (i) {
        if (i.questionId !== activity.questionId) {
          return i;
        }
      }
      return null;
    });

    let intervention = focusedPlanService.intervention;
    intervention.evaluation = x_deleted;
    setFocusedPlanService({ ...focusedPlanService, intervention: intervention });
  }

  const Activitys = () => {
    let r = [];
    if (Object.keys(focusedPlanService).length > 0) {
      let act = [];
      if (typeof focusedPlanService.intervention !== 'undefined') {
        if (typeof focusedPlanService.intervention.evaluation !== 'undefined') {
          act = focusedPlanService.intervention.evaluation;
        }
      }
      if (act.length > 0) {
        let n = 0;
        let rowColor = "#FFFFFF";
        act.forEach((i) => {
          if (i) {
            ++n;
            if (n % 2 === 0) {
              rowColor = "#EEEEEE";
            }
            else {
              rowColor = "#FFFFFF";
            }
            r.push(
              <Grid key={API.genKey()} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: 20, marginTop: 3, padding: 2, backgroundColor: rowColor }} >
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {isDesktop ? "" : <b>กิจกรรม: </b>}
                    {i.questionName}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>ทีมที่รับมอบ: </b>}
                    {/* {i.teamName} */}
                    {isDesktop ? <div style={{ textAlign: 'left' }}>{i.teamName}</div> : i.teamName}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>ผู้บันทึกคำตอบ: </b>}
                    {/* {i.answererName} */}
                    {isDesktop ? <div style={{ textAlign: 'left' }}>{i.answererName}</div> : i.answererName}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? "" : <b>วันเริ่ม: </b>}
                    {/* {i.startDate} */}
                    {isDesktop ? <div style={{ textAlign: 'left' }}>{API.dateTHs(i.startDate)}</div> : API.dateTHs(i.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? "" : <b>วันสิ้นสุด: </b>}
                    {/* {i.endDate} */}
                    {isDesktop ? <div style={{ textAlign: 'left' }}>{API.dateTHs(i.endDate)}</div> : API.dateTHs(i.endDate)}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Grid container justify="flex-end">
                      {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
                        <Button onClick={() => clickDeleteActivity(i)} variant="contained" color="secondary" startIcon={<DeleteBin style={{ marginLeft: 11, marginTop: -2 }} />} style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "80px", maxWidth: "80px", color: "white", }}>
                          ลบ
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        });
      }
    }
    return r;
  };

  const clickSentTeam = (planServiceData) => {
    setOpenBackdrop(true);
    let osccTeamIdList = [];
    // if (typeof planServiceData.intervention !== 'undefined') {
    //   if (typeof planServiceData.intervention.evaluation !== 'undefined') {
    //     planServiceData.intervention.evaluation.forEach(i => {
    //       if (i) {
    //         if (osccTeamIdList.indexOf(i.teamCode) < 0) {
    //           osccTeamIdList.push(i.teamCode);
    //         }
    //       }
    //     });
    //   }
    // }

    // console.log(planServiceData);

    if (typeof planServiceData.serviceslotList !== 'undefined') {
      planServiceData.serviceslotList.forEach(i => {
        // console.log(i);
        if (i) {
          if (osccTeamIdList.indexOf(i.teamCode) < 0) {
            osccTeamIdList.push(i.teamCode);
          }
        }
      });
    }

    let x = osccPerson;
    x['caseStatus'] = 'assignedTeam';
    x['osccTeamId'] = osccTeamIdList;
    console.log(x.id,x);
    API.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.goBack();
        }, 100);
      }
    });
  }


  const clickCancelSentTeam = (planServiceData) => {
    // คงไม่มีกรณีที่ CM หลายคนมารุม assign เคสเดียวกัน หรอกเนอะ
    setOpenBackdrop(true);
    let x = osccPerson;
    x['caseStatus'] = 'new';
    x['osccTeamId'] = null;
    API.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.goBack();
        }, 100);
      }
    });
  }

  const confirmResetAnswer = async () => {
    setOpenBackdrop(true);
    let responseI = await API.get(focusedActivity.interventionId, "interventions", '3009');
    if (responseI.status === 200) {
      if (responseI.data) {
        let x = responseI.data;
        let z = x.activities.homeservice;
        let temp_z = [];
        z.forEach(Zi => {
          if (parseInt(Zi.sequenceId)!==parseInt(focusedActivity.sequenceId)) {
            temp_z.push(Zi);
          }
        });
        x.activities.homeservice=temp_z;

        let responseG = await API.update(focusedActivity.interventionId, x, "interventions", '3009');
        // let responseG = await API.remove(focusedActivity.interventionId, "interventions", '3009');
        if (responseG.status === 200) {
          if (responseG.data) {
            // console.log(responseG.data);
            // console.log(evaluationData);
            // console.log(planService);
            let thisPlan = {};
            planService.forEach(i => {
              if (i.id === focusedActivity.planServiceId) {
                // console.log(i);
                thisPlan = i;
              }
            });
            if (Object.keys(thisPlan).length>0) {
              thisPlan.serviceslotList.forEach(i => {
                // console.log(i.sequenceId, focusedActivity.sequenceId);
                if (i.sequenceId===focusedActivity.sequenceId) {
                  // console.log(i);
                  // console.log(focusedActivity);
                  delete i['interventionData'];
                  delete i['interventionDate'];
                  delete i['interventionId'];
                }
              });
            }
            // console.log(thisPlan);
            let responseU = await API.update(thisPlan.id, thisPlan, "planservices", '3009');
            if (responseU.status === 200) {
              if (responseU.data) {
                // console.log(responseU.data);
                setOpenBackdrop(false);
                setOpenDialog(false);
                getPlanService();
              }
            }
          }
        }
      }
    }
  }

  const confirmRemoveCase = () => {
    setOpenBackdrop(true);
    let x = osccPerson;
    x['caseStatusBeforeRemove'] = x['caseStatus'];
    x['caseStatus'] = 'removed';
    x['osccTeamId'] = null;
    API.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.goBack();
        }, 100);
      }
    });
  }

  const confirmSentCM = async () => {
    // console.log('confirmSentCM ---');
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    x.caseStatus = 'sentCM';
    // console.log(x);
    let responseUpdate = await API.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        setTimeout(() => {
          setOpenBackdrop(false);
          // history.goBack();
          history.push({ pathname: '/cases', state: { status: localStorage.getItem("focusedCaseStatus") } });
        }, 100);
      }
    }
  }

  const clickAddPlan = (planServiceData) => {
    setFocusedPlanService({
      cause: "",
      startDate: null,
      endDate: null,
      planDate: new Date(),
      plannerId: userinfo.user.id,
      planAt: "",
      place: "",
      intervention: [
        "evaluation"
      ],
      serviceslotId: null,
    });
    setSelectTeamValue('');
    setSelectAnswererValue('');
    setOpenActivity(true);
  }

  const clickCompletedPlanDetail = () => {
    console.log('clickCompletedPlanDetail');
  }

  const clickPlanDetail = (xxx, ssData) => {
    // console.log('clickPlanDetail', xxx, ssData);
    let x = JSON.parse(JSON.stringify(xxx));
    if (typeof x.startDate === 'undefined') {
      // x.startDate='2021-05-21';
      x.startDate = null;
    }
    if (typeof x.endDate === 'undefined') {
      x.endDate = null;
    }
    // console.log(x);
    setFocusedPlanService(x);
    // console.log(serviceSlotData);
    let temp_ssData = serviceSlotData;
    if (typeof ssData !== 'undefined') {
      temp_ssData = ssData;
    }
    // console.log(temp_ssData);
    temp_ssData.forEach(i => {
      // console.log(i.id, x.serviceslotId);
      if (i.id === x.serviceslotId) {
        setFormData({
          ...formData, ...{
            // ['planServiceId']: x.id,
            // ['serviceSlot']: i,
            // ['cause']: x.cause,
            // ['startDate']: x.startDate,
            // ['endDate']: x.endDate,
            planServiceId: x.id,
            serviceSlot: i,
            cause: x.cause,
            startDate: x.startDate,
            endDate: x.endDate,
          }
        });
        displaySlotList(i, x.startDate, x);
      }
    });
    setOpenActivity(true);
  }

  const clickSavePlan = () => {
    setOpenBackdrop(true);
    let s = 'create';
    if (typeof focusedPlanService.id !== 'undefined') {
      if (focusedPlanService.id !== null && focusedPlanService.id !== '') {
        s = 'update';
      }
    }
    let x = focusedPlanService;
    if (x.serviceslotList.length === 1 && x.serviceslotId === x.serviceslotList[0].serviceslotId) {
      x.serviceslotList[0].startDate = x.startDate;
      x.serviceslotList[0].endDate = x.endDate;
      x.serviceslotList[0].serviceslotName = formData.serviceSlot.serviceName;
    }

    if (typeof x['serviceslot'] !=='undefined') { 
      delete x['serviceslot'];
    }
    // console.log(x);

    if (s === 'create') {
      x['osccPersonId'] = props.location.state.id;
      // console.log(x);
      API.create(x, "planservices").then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          setTimeout(() => {
            getPlanService();
            handleClose();
            setOpenBackdrop(false);
          }, 100);
        }
      });
    }
    if (s === 'update') {
      API.update(x.id, x, "planservices").then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          getPlanService();
          setTimeout(() => {
            handleClose();
            setOpenBackdrop(false);
          }, 100);
        }
      });
    }
  }

  const changeFocusedPlanService = (e) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    setFocusedPlanService({ ...focusedPlanService, ...{ [e.target.name]: e.target.value } });
  }

  const confirmDeletePlan = () => {
    setOpenDialog(false);
    setOpenBackdrop(true);
    API.remove(focusedPlanService.id, "planservices").then(
      (response) => {
        // console.log(response);
        if (response.status === 200) {
          setOpenBackdrop(false);
          getPlanService();
          resetPlanOsccPerson();
        }
      }
    );
  }

  const displaySlotList = (evalue, sd, xplan) => {
    // console.log(evalue);
    let startDate = null;
    if (typeof sd !== 'undefined') {
      startDate = sd;
    }
    else {
      startDate = formData.startDate;
    }

    let temp_plan={};
    if (typeof xplan !=='undefined') {
      temp_plan=xplan;
    }
    else {
      temp_plan=JSON.parse(JSON.stringify(focusedPlanService));
    }

    if (typeof evalue.serviceslotList !== 'undefined') {
      // console.log('-------------------------------------A');
      let multiServiceSlot = [];
      // console.log(evalue.serviceslotList);
      let hcode='';
      evalue.serviceslotList.forEach(c => {
        serviceSlotData.forEach(d => {
          if (c.serviceslotId === d.id) {
            console.log(d);
            hcode=d.hcode;
            let slotStartDate = new Date(startDate);
            slotStartDate.setDate(slotStartDate.getDate() + c.startDays - 1);
            let slotEndDate = new Date(startDate);
            slotEndDate.setDate(slotEndDate.getDate() + c.endDays - 1);
            let thisSlot = {
              sequenceId: c.sequenceId,
              serviceslotId: d.id,
              serviceslotName: d.serviceName,
              teamName: d.resultTemplate[0].osccteam.teamName,
              teamCode: d.resultTemplate[0].osccteam.teamCode,
              answererId: d.resultTemplate[0].answerer.answererId,
              answererName: d.resultTemplate[0].answerer.answererName,
              startDate: slotStartDate.getFullYear() + '-' + addZero(slotStartDate.getMonth(), 2) + '-' + addZero(slotStartDate.getDate(), 2),
              endDate: slotEndDate.getFullYear() + '-' + addZero(slotEndDate.getMonth(), 2) + '-' + addZero(slotEndDate.getDate(), 2)
            };
            multiServiceSlot.push(thisSlot);
          }
        });
      });
      setSSGroup(multiServiceSlot);
      // setFocusedPlanService({ ...temp_plan, ...{ ['serviceslotId']: evalue.id, ['serviceslotList']: multiServiceSlot } });
      setFocusedPlanService({ ...temp_plan, ...{ hcode: hcode, serviceslotId: evalue.id, serviceslotList: multiServiceSlot } });
    } else {
      if (typeof evalue.resultTemplate !== 'undefined') {
        // console.log('-------------------------------------B1');
        // console.log(temp_plan);
        setSSGroup([]);
        // setFocusedPlanService({ ...temp_plan, ...{ ['serviceslotId']: evalue.id } });
        setFocusedPlanService({ ...temp_plan, ...{ serviceslotId: evalue.id } });
        let singleSlot = evalue.resultTemplate[0];
        // console.log(singleSlot);
        let thisServiceSlot = {
          serviceslotId: evalue.id,
          teamName: "",
          teamCode: "",
          answererId: "",
          answererName: ""
        }
        if (typeof singleSlot.osccteam !== 'undefined') {
          if (typeof singleSlot.osccteam.teamCode !== 'undefined') {
            if (typeof singleSlot.osccteam.teamName !== 'undefined') {
              // console.log('-------------------------------------B2');
              setSelectTeamValue(singleSlot.osccteam.teamName);
              thisServiceSlot['teamCode'] = singleSlot.osccteam.teamCode;
              thisServiceSlot['teamName'] = singleSlot.osccteam.teamName;
              // console.log(temp_stateActivity);
            }
          }
        }
        if (typeof singleSlot.answerer !== 'undefined') {
          if (typeof singleSlot.answerer.answererId !== 'undefined') {
            if (typeof singleSlot.answerer.answererName !== 'undefined') {
              // console.log('-------------------------------------B3');
              setSelectAnswererValue(singleSlot.answerer.answererName);
              thisServiceSlot['answererId'] = singleSlot.answerer.answererId;
              thisServiceSlot['answererName'] = singleSlot.answerer.answererName;
            }
          }
        }
        // setFocusedPlanService({ ...temp_plan, ...{ ['serviceslotId']: evalue.id, ['serviceslotList']: [thisServiceSlot] } });
        setFocusedPlanService({ ...temp_plan, ...{ serviceslotId: evalue.id, serviceslotList: [thisServiceSlot] } });
      }
    }
  }

  const ATCChange = (e) => {
    // console.log(focusedPlanService);
    if (typeof e.value !== 'undefined') {
      if (e.value) {
        if (e.name === 'serviceSlot') {
          // console.log('----------------------------ATCChange');
          displaySlotList(e.value);
          // setFormData({ ...formData, ...{ ['serviceSlot']: e.value } });
          setFormData({ ...formData, ...{ serviceSlot: e.value } });
        }
      }
    }
  }

  const addZero = (x, n) => {
    let l = n - x.toString().length;
    let r = x.toString();
    for (var i = 0; i < l; i = i + 1) {
      r = '0' + r;
    }
    return r;
  }

  const goQuestionnaire = async (informData, consentData, thisActivity) => {
    // ********* ถ้า notConsent ไม่ต้องทำแบบสอบถาม แต่บันทึก ผล consent และกระดาษคำตอบเปล่า ลง invertention ไปเลย
    // ********* ถ้า ยอมรับ consent ก็ redirect ไปทำแบบสอบถามเลย
    // console.log(thisActivity);
    let isNotConsent=false;
    if (typeof thisActivity === 'undefined') {
      if (consentData.result==="notConsent") { 
        isNotConsent=true;
      }
      else {
        isNotConsent=false;
      }
    }
    else {
      isNotConsent=false;
    }

    if (isNotConsent) {
      let a = JSON.parse(JSON.stringify(focusedActivity));
      let answerData_blank={};
      answerData_blank['answerer']={ answererId: a.answererId, answererName: a.answererName };
      answerData_blank['consent']=consentData;
      answerData_blank['id']=a.questionnaireId;
      answerData_blank['inform']=informData;
      answerData_blank['oscctem']={ teamCode: a.teamCode, teamName: a.teamName };
      answerData_blank['planServiceId']=a.planServiceId;
      answerData_blank['questionnaire_code']=a.questionnaire.questionnaire_code;
      answerData_blank['questionnaire_name']=a.questionnaire.questionnaire_name;
      answerData_blank['questionnaire_type']=a.questionnaire.questionnaire_type;
      answerData_blank['questionsList']=a.questionnaire.questionsList;
      answerData_blank['sequenceId']=a.sequenceId;
      answerData_blank['sumScore']=0;
      // console.log(props.location.state);
      // console.log(planService);
      // console.log(answerData_blank);
      // console.log(globalState.currentUser.user.id);
      // console.log(globalState.currentUser.user.fullname);
      // console.log(globalState.currentUser.user.department.hcode);
      // console.log(globalState.currentUser.user.department.hos_name);

      // console.log(personData);

      await SI.saveInterventions(
        // props.location.state, 
        personData,
        planService,
        answerData_blank,
        globalState.currentUser.user.id,
        globalState.currentUser.user.fullname,
        globalState.currentUser.user.department.hcode,
        globalState.currentUser.user.department.hos_name
      );
      getPlanService();

      // planService.forEach(aps => {
      //   if (aps.id===answerData_blank.planServiceId) {
      //     // console.log(aps);
      //     clickPlanDetail(aps);
      //   }
      // });
    }
    else {
      let i = JSON.parse(JSON.stringify(focusedActivity));
      if (typeof thisActivity !== 'undefined') {
        i = thisActivity;
      }
      let questionData = {
        returnAnswerTo: '/caseplan', 
        veiwType: props.location.state.caseStatus==="assignedOfficer"||props.location.state.caseStatus==="sentCM"?'readonly':'recordable', 
        id: i.questionnaireId,
        questionnaire: i.questionnaire,
        planServiceId: i.planServiceId,
        interventionId: (typeof i.interventionId === 'undefined'?null:(i.interventionId === ''?null:i.interventionId)),
        inform: informData,
        consent: consentData,
        sequenceId: i.sequenceId
      };
      // console.log(questionData);
      history.push({
        pathname: '/questionnaireview',
        state: {
          questionData: questionData,
          caseData: props.location.state,
          personData: personData
        }
      });
    }
  }

  // const viewQuestionnaire = (i,p) => {
  //   // console.log('viewQuestionnaire');
  //   // console.log(i);
  //   let qqq={};
  //   // console.log(serviceSlotData);
  //   serviceSlotData.forEach(s => {
  //     if (s.id===i.serviceslotId) {
  //       qqq=s;
  //     }
  //   });
  //   let questionData = { 
  //     returnAnswerTo: '/caseplan', 
  //     veiwType: props.location.state.caseStatus==="assignedOfficer"||props.location.state.caseStatus==="sentCM"?'readonly':'recordable', 
  //     id: qqq.resultTemplate[0].id, 
  //     questionnaire: qqq.resultTemplate[0],
  //     planServiceId: formData.planServiceId,
  //     interventionId: (typeof i.interventionId === 'undefined'?null:(i.interventionId === ''?null:i.interventionId)),
  //     sequenceId: i.sequenceId
  //   };
  //   // console.log(questionData);
  //   history.push({
  //     pathname: '/questionnaireview',
  //     state: {
  //       questionData: questionData,
  //       caseData: props.location.state,
  //     }
  //   });
  // }

  const ServiceSlotInfo = () => {
    // console.log('ServiceSlotInfo--- ');
    let r = [];
    let x = null;
    planService.forEach(i => {
      if (i.id === formData.planServiceId) {
        x = i;
      }
    });
    if (x) {
      // console.log(x);
      if (typeof x.serviceslotList !== 'undefined') {
        if (x.serviceslotList.length > 0) {
          let n = 0;
          let rowColor = "#FFFFFF";
          let ss = x.serviceslotList;
          ss.sort(function (a, b) {
            // b-a เรียงมากไปน้อย
            // a-b เรียงน้อยไปมาก
            let dateA = a.startDate;
            let dateB = b.startDate;
            dateA = (typeof dateA === 'undefined'?'0000-00-00':dateA);
            dateB = (typeof dateB === 'undefined'?'0000-00-00':dateB);
            dateA = (dateA === null|dateA===''?'0000-00-00':dateA);
            dateB = (dateB === null|dateB===''?'0000-00-00':dateB);
            let ax = parseInt((new Date(dateA).getTime()).toString());
            let bx = parseInt((new Date(dateB).getTime()).toString());
            ax = (isNaN(ax) ? 0 : ax);
            bx = (isNaN(bx) ? 0 : bx);
            return ax - bx;
            // return bx - ax;
          });
          // console.log(ss);
          ss.forEach(i => {
          // x.serviceslotList.forEach(i => {
            // console.log(i);
            n = n + 1;

            // let workStatus="กำลังดำเนินการ";
            // let workStatusColor="#FF7A00";
            // if (typeof i.interventionId !== 'undefined') {
            //   workStatus="ดำเนินการแล้ว";
            //   workStatusColor="#47C10D";
            // }

            let acceptConsent = false;
            if (typeof i.consent !== 'undefined') {
              if (i.consent) {
                if (typeof i.consent.result !== 'undefined') {
                  if (i.consent.result === 'consented') {
                    acceptConsent = true;
                  } 
                } 
              }
            }
  
            let workStatus = "กำลังดำเนินการ";
            let workStatusColor = "#FF7A00";
            if (typeof i.interventionId !== 'undefined') {
              if (i.interventionId !== '' && i.interventionId !== null) {
                workStatus = "ดำเนินการแล้ว";
                workStatusColor = "#47C10D";
                if (acceptConsent===false) {
                  workStatus = "ไม่ยอมรับConsent";
                  workStatusColor = "red";
                }
              }
            }

            if (n % 2 === 0) {
              rowColor = "#EEEEEE";
            }
            else {
              rowColor = "#FFFFFF";
            }
            r.push(
              <Grid container key={API.genKey()} style={{ marginTop: 5, borderRadius: isDesktop?20:5, border: 'solid 1px #cccccc', background: rowColor, padding: 5 }} direction={isDesktop ? "row" : "column-reverse"}>
                <Grid item xs={12} sm={11} style={{ marginTop: isDesktop?0:-30 }}>
                  <Grid container>
                    <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>ลำดับ: </b>}
                      {n}
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>กิจกรรม: </b>}
                      {i.serviceslotName}
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>ทีมที่รับมอบ: </b>}
                      {i.teamName}
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>ผู้ตอบคำถาม: </b>}
                      {i.answererName}
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>เริ่ม-สิ้นสุด: </b>}
                      {API.dateTHs(i.startDate)} - {API.dateTHs(i.endDate)}
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                      {!isDesktop&&<b>สถานะ: </b>}
                      <div style={{ backgroundColor: workStatusColor, color: '#FFFFFF', borderRadius: 15, display: 'inline-block', paddingLeft: 10, paddingRight: 10, height: 29 }}>{workStatus}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1} style={{ textAlign: "center" }}>
                  <Grid container justify="flex-end">
                    {/* <Button
                      onClick={() => viewQuestionnaire(i, focusedPlanService)}
                      variant="contained" color="primary"
                      startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }}
                    /> */}
                    {(workStatus === "กำลังดำเนินการ" || workStatus === "ไม่ยอมรับConsent") ? (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                        style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                        onClick={() => {
                          // console.log(i);
                          // console.log(i.inform);
                          // console.log(i.consent);
                          setInformData(i.inform);
                          setConsentData(i.consent);
                          setOpenConsent(true);
                          setFocusedActivity(i);
                        }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            // console.log(i.inform);
                            // console.log(i.consent);
                            setInformData(i.inform);
                            setConsentData(i.consent); 
                            // คอมโพเน้น ConsentServiceSlot จะเช็คถ้ามี consent.result จะแสดงหน้าต่าง consent ในโหลดแก้ไข
                            setOpenConsent(true);
                            setFocusedActivity(i);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            // console.log(i.inform);
                            // console.log(i.consent);
                            setFocusedActivity(i);
                            goQuestionnaire(i.inform, i.consent, i);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<MdUndoIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            setDialogSet('resetAnswer');
                            setDialogText('ต้องการลบคำตอบที่เคยบันทึกไว้ ใช่หรือไม่!?');
                            setOpenDialog(true);
                            setFocusedActivity(i);
                            // goQuestionnaire(i.inform, i.consent, i);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          });
        }
      }
    }
    if (isDesktop) {
      return (
        <Grid item xs={12} sm={12} style={{ marginTop: 3 }}>
          {/* <Grid container justify="flex-start" style={{ borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}> &nbsp;&nbsp; ลำดับ</Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>กิจกรรม</Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>ทีมที่รับมอบ</Grid>
            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>ผู้ตอบคำถาม</Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>เริ่ม-สิ้นสุด</Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>สถานะ</Grid>
            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}><br /></Grid>
          </Grid> */}
          {r}
        </Grid>
      );
    }
    else {
      return (
        <Grid item xs={12} sm={12} style={{ marginTop: 3 }}>
          {/* <Grid container justify="flex-start" style={{ borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}> &nbsp;&nbsp; รายการกิจกรรม</Grid>
          </Grid> */}
          {r}
        </Grid>
      );
    }
  }

  const SSGroupRows = () => {
    let r = [];
    let n = 0;
    let rowColor = "#FFFFFF";
    SSGroup.forEach(i => {
      n = n + 1;
      if (n % 2 === 0) {
        rowColor = "#EEEEEE";
      }
      else {
        rowColor = "#FFFFFF";
      }
      r.push(
        <Grid key={API.genKey()} item xs={12} sm={12} style={{ marginTop: 5, padding: 5, borderRadius: 5, border: 'solid 1px #cccccc', background: rowColor }}>
          <Grid container>
            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>
              {!isDesktop?<b>ลำดับ: </b>:''}
              {n}
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
              {!isDesktop?<b>กิจกรรม: </b>:''}
              {i.serviceslotName}
            </Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
              {!isDesktop?<b>ทีมที่รับมอบ: </b>:''}
              {i.teamName}
            </Grid>
            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
              {!isDesktop?<b>ผู้บันทึกคำตอบ: </b>:''}
              {i.answererName}
            </Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>
              {!isDesktop?<b>วันเริ่ม-วันสิ้นสุด: </b>:''}
              {API.dateTHs(i.startDate)} - {API.dateTHs(i.endDate)}
            </Grid>
          </Grid>
        </Grid>
      );
    });
    return <Grid key={API.genKey()} item xs={12} sm={12}>{r}</Grid>;
  }


  const clickSaveCareGiver = async () => {
    // console.log(careGiverData);
    // console.log(props.location.state);
    setOpenBackdrop(true);
    let data = {
      careGiver : careGiverData
    };
    let x = props.location.state;

    API.patch(x.id, data, "osccpeople").then((responseU) => {
      if (responseU.status === 200) {
        // console.log(responseU.data);
        setOpenBackdrop(false);

        x['careGiver'] = careGiverData;
        history.replace('/caseplan', x);
      }
    });
  }

  const changeCareGiverData = (e) => {
    if (e.target.name==='cid') {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNums.length <= 13) {
        setCareGiverData({...careGiverData,...{cid:onlyNums}});
        if (onlyNums.length === 13) {
          setDisableSaveButton(false);
        }
        else {
          setDisableSaveButton(true);
        }
      }
    }
    else {
      setCareGiverData({...careGiverData,...{[e.target.name]:e.target.value}});
      if (careGiverData.cid.length === 13) {
        setDisableSaveButton(false);
      }
      else {
        setDisableSaveButton(true);
      }
    }
  }

  useEffect(() => {
    getOsccPerson();
    getTeamUsers();
    setSSGroup([]);
    if (typeof props.location.state != 'undefined') {
      if (typeof props.location.state.careGiver != 'undefined') {
        setCareGiverData({
          cid : props.location.state.careGiver.cid||'',
          fname: props.location.state.careGiver.fname||'',
          lname : props.location.state.careGiver.lname||'',
          nameCalledByCase : props.location.state.careGiver.nameCalledByCase||'',
          relation : props.location.state.careGiver.relation||'',
          relationSpecify : props.location.state.careGiver.relationSpecify||'',
        });
      }
    }
    // eslint-disable-next-line 
  }, []);

  return (
    <div style={{ marginBottom: 100 }}>

        <Dialog disableBackdropClick={true} className={classes.backdrop} open={openBackdrop}>
          <DialogContent>
            <CircularProgress color="inherit" />
          </DialogContent>
        </Dialog>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}>

        <Grid container style={{ marginTop: -10, marginBottom: 0 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
          <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <h4>จัดการแผน</h4>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container justify="flex-end">
              <ButtonGroup size={isDesktop?'large':'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
                <Button color="primary" 
                  style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => {
                    // history.goBack();
                    history.push({ pathname: "/cases", state: { status: localStorage.getItem("focusedCaseStatus") }, }); 
                  }}
                >
                  กลับ
                </Button>
                <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => { 
                    history.push({ pathname: "/caseemr", state: props.location.state, }); 
                  }} 
                >
                  EMR
                </Button>

                <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => { 
                    setOpenMatchDialog(true); 
                  }}
                >
                  ผู้ดูแล
                </Button>

                {localStorage.getItem("focusedCaseStatus") === 'onWork' && (
                  <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} onClick={() => { setDialogSet('sentCM'); setDialogText('ยืนยันส่งเคสนี้ไปรอสรุป ใช่หรือไม่!?'); setOpenDialog(true); }} >
                    รอสรุป
                  </Button>
                )}

                <Button color="secondary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} onClick={() => { setDialogSet('removeCase'); setDialogText('ยืนยัน นำเคสนี้ออกจากระบบ ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  นำออก
                </Button>

                {localStorage.getItem("focusedCaseStatus") === 'onWork' && (
                  <Button color="secondary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} onClick={clickCancelSentTeam} >
                    ยกเลิก
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>

        {Object.keys(personData).length > 0 && (
          <CaseInfoBar data={personData} />
        )}

        <Grid container style={{ marginBottom: 10 }}>
          {/* TABLE HEAD */}
          {isDesktop ? (
            <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
              <Grid container style={{ marginBottom: 10, borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                <Grid item xs={12} sm={10}>
                  <Grid container justify="flex-start">
                    <Grid item xs={12} sm={1} style={{ padding: 4 }}> &nbsp;&nbsp; ลำดับ</Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 4 }}>รายการแผนการดูแล</Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 4 }}>เริ่ม</Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 4 }}>สิ้นสุด</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container direction={isDesktop ? "row" : "column-reverse"} justify="flex-end" >
                    {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
                      <Button
                        onClick={() => clickAddPlan()}
                        variant="contained" color="primary"
                        startIcon={<BtMdAddCircle style={{ marginLeft: 11, marginTop: -2 }} />}
                        style={{ marginRight: 12, marginBottom: 5, marginTop: 5, height: 25, borderRadius: 20, minWidth: "160px", maxWidth: "160px", color: "white" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ):(
            <Grid item xs={12} sm={12}>
              <Grid container style={{ marginTop: 20, borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                <Grid item xs={10}>
                  &nbsp;&nbsp;รายการแผนการดูแล
                </Grid>
                <Grid item xs={2}>
                  {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
                    <Button
                      onClick={() => clickAddPlan()}
                      variant="contained" color="primary"
                      startIcon={<BtMdAddCircle style={{ marginLeft: 11, marginTop: -2 }} />}
                      style={{ margin: 2, height: 25, borderRadius: 20, minWidth: "50px", maxWidth: "50px", color: "white" }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* TABLE ROWS */}
          {planListRows()}

        </Grid>

        <Dialog disableBackdropClick={true} className={classes.dialog} fullWidth={true} maxWidth={"lg"} open={openActivity} onClose={handleClose}>
          <DialogTitle disableTypography style={{ paddingBottom: 0, marginBottom: 0}}>
            <Grid container>

              {localStorage.getItem("focusedCaseStatus") === 'newCase' ? (
                <Grid item xs={12} sm={12}>
                  <h4 style={{ marginBottom: 10 }} >
                    แผนการดูแล
                  </h4>
                </Grid>
              ):(
                <>
                  <Grid item xs={12} sm={12}>
                    <b>ชื่อแผน : </b> {formData.cause}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <b>วันที่เริ่ม : </b> {API.dateTHs(formData.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <b>วันที่สิ้นสุด : </b> {API.dateTHs(formData.endDate)}
                  </Grid>

                  {/* TABLE HEAD */}
                  {SSGroup.length > 0 && (
                    isDesktop?(
                      <Grid container style={{ marginTop: 10, margin: 0, borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                        <Grid item xs={12} sm={11} style={{ padding: 0 }}>
                          <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>&nbsp;&nbsp;ลำดับ</Grid>
                            <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>รายการกิจกรรม</Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>ทีมที่รับมอบ</Grid>
                            <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>ผู้ตอบ</Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>วันเริ่ม-วันสิ้นสุด</Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>สถานะ</Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                      </Grid>
                    ):(
                      <Grid item xs={12} sm={12} style={{ marginTop: 10, padding: 5, borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                        &nbsp;&nbsp;รายการกิจกรรม
                      </Grid>
                    )
                  )}
                </>
              )}
            </Grid>
          </DialogTitle>

          <DialogContent>
            <form className={classes.form} noValidate>
              <Grid container style={{ marginBottom: 10 }}>

                {/* INPUT แผน */}
                <Grid item xs={12} sm={12} style={{ marginBottom: 10 }}>
                  {localStorage.getItem("focusedCaseStatus") === 'newCase' ? (
                    <Grid container spacing={1} justify="flex-start">
                      <Grid item xs={12} sm={8}>
                        <TextField
                          size="small"
                          label="ชื่อแผน"
                          variant="outlined"
                          fullWidth={true}
                          inputProps={{ name: "cause", style: { textAlign: 'left' } }}
                          // value={focusedPlanService.cause}
                          value={formData.cause}
                          onChange={changeFocusedPlanService}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <DatePicker
                          size="small"
                          autoOk
                          inputVariant="outlined"
                          label="วันที่เริ่ม"
                          format="DD/MM/YYYY"
                          emptyLabel="วัน/เดือน/ปี"
                          placeholder="วัน/เดือน/ปี"
                          value={formData.startDate}
                          rightArrowIcon={<KeyboardArrowRight />}
                          onChange={(date) => changeFocusedPlanService({
                            target: {
                              name: 'startDate',
                              value: moment(date).format('YYYY-MM-DD')
                            }
                          })}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <DatePicker
                          size="small"
                          autoOk
                          inputVariant="outlined"
                          label="วันที่สิ้นสุด"
                          format="DD/MM/YYYY"
                          emptyLabel="วัน/เดือน/ปี"
                          placeholder="วัน/เดือน/ปี"
                          value={formData.endDate}
                          onChange={(date) => changeFocusedPlanService({
                            target: {
                              name: 'endDate',
                              value: moment(date).format('YYYY-MM-DD')
                            }
                          })}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={1} justify="flex-start">
                          <Grid item xs={12} sm={8}>
                            <Autocomplete
                              filterOptions={filterOptions}
                              id="serviceslot"
                              size="small"
                              fullWidth
                              options={serviceSlotData}
                              value={formData.serviceSlot}
                              getOptionSelected={(option, value) => value.serviceLocation+' > '+value.serviceName === option.serviceLocation+' > '+option.serviceName}
                              getOptionLabel={(option) => option.serviceLocation+' > '+option.serviceName || ''}
                              onChange={(_, newValue) => {
                                ATCChange({ name: 'serviceSlot', value: newValue });
                              }}
                              ListboxProps={{ style: { maxHeight: '200px' } }}
                              renderInput={(params) =>
                                <TextField {...params}
                                  id="changwat_texfield"
                                  name="changwat"
                                  label="Service Slot"
                                  variant="outlined"
                                  onChange={(e) => ATCChange(e)}
                                  style={{ textAlign: 'left' }}
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={6} sm={2}>
                            <Select fullWidth={true} variant="outlined" native style={{ height: 40, textAlign: 'left' }}
                              onChange={(e) => handleChangeStateActivity(e, teamList, "teamName", "teamCode")}
                              inputProps={{ name: "teamName", id: "teamCode", }}
                              value={selectTeamValue}
                              // style={{ height: 40 }}
                            >
                              <option>-เลือกทีม-</option>
                              {teamList.map((team) => (
                                <option key={team.teamCode} value={team.teamName}>
                                  {team.teamName ?? team.teamCode}
                                </option>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={6} sm={2}>
                            <Select fullWidth={true} variant="outlined" native style={{ height: 40, textAlign: 'left' }}
                              onChange={(e) => handleChangeStateActivity(e, answererData, "answererName", "answererId")}
                              inputProps={{ name: "answererName", id: "answererId", }}
                              value={selectAnswererValue}
                              // style={{ height: 40 }}
                            >
                              <option>-ผู้ตอบ-</option>
                              {answererData.map((a) => (
                                <option key={a.answererId} value={a.answererName}>
                                  {a.answererName}
                                </option>
                              ))}
                            </Select>
                          </Grid>

                          {/* TABLE HEAD */}
                          {SSGroup.length > 0 && (
                            isDesktop?(
                              <Grid container style={{ marginTop: 10, margin: 0, borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                                <Grid item xs={12} sm={11} style={{ padding: 0 }}>
                                  <Grid container spacing={2} justify="flex-start">
                                    <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>&nbsp;&nbsp;ลำดับ</Grid>
                                    <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>รายการกิจกรรม</Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>ทีมที่รับมอบ</Grid>
                                    <Grid item xs={12} sm={1} style={{ textAlign: "left" }}>ผู้ตอบ</Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>วันเริ่ม-วันสิ้นสุด</Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>สถานะ</Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={1}></Grid>
                              </Grid>
                            ):(
                              <Grid item xs={12} sm={12} style={{ marginTop: 10, padding: 5, borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                                &nbsp;&nbsp;รายการกิจกรรม
                              </Grid>
                            )
                          )}

                          {SSGroup.length > 0 && SSGroupRows()}

                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container style={{ marginTop: 0 }}>
                      <Grid item xs={12} sm={12}>
                        {ServiceSlotInfo()}
                      </Grid>
                    </Grid>
                  )}
                </Grid>

              </Grid>
            </form>
          </DialogContent>
          <DialogActions style={{ padding: 10 }}>
            {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
              <Button variant="outlined" onClick={clickSavePlan} color="primary" >
                บันทึก
              </Button>
            )}
            <Button variant="outlined" onClick={clickClosePlan} color="primary">
              ปิด
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableBackdropClick={true} open={openDialog}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component={'div'}>
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {dialogSet === 'resetAnswer' && (
              <Button variant="contained" color="primary" onClick={confirmResetAnswer}>
                ยืนยัน
              </Button>
            )}
            {dialogSet === 'sentCM' && (
              <Button variant="contained" color="primary" onClick={confirmSentCM}>
                ยืนยัน
              </Button>
            )}
            {dialogSet === 'deletePlan' && (
              <Button variant="contained" color="primary" onClick={confirmDeletePlan}>
                ยืนยัน
              </Button>
            )}
            {dialogSet === 'removeCase' && (
              <Button variant="contained" color="primary" onClick={confirmRemoveCase}>
                ยืนยัน
              </Button>
            )}
            <Button variant="contained" autoFocus
              onClick={() => setOpenDialog(false)}
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>

        <ConsentServiceSlot dialogOpen={openConsent} setDialogOpen={setOpenConsent} userTypeId={''} informData={informData} consentData={consentData} t={t} goQuestionnaire={goQuestionnaire} activityData={focusedActivity} />

      </MuiPickersUtilsProvider>

      <Dialog open={openMatchDialog} fullWidth={true} maxWidth={'md'}>
        <DialogTitle style={{ paddingBottom: 0 }}>บันทึกข้อมูลผู้ดูแล</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>

            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                บันทึกข้อมูลผู้ดูแลของผู้ที่มีความเสี่ยงรายนี้ เพื่อให้ผู้ดูแลสามารถตอบแบบสอบถามในระบบได้ด้วยตนเอง หลังจากผู้ดูแลลงทะเบียนและได้รับการอนุมัติ Username โดย เจ้าหน้าที่ (FA/รพ./รพ.สต.)
              </Grid>

              <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#038000', color: '#FFFFFF', paddingLeft: 15 }}>
                    ข้อมูลผู้ดูแล
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="เลขบัตรประจำตัวประชาชน"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "cid", style: { textAlign: 'left' } }}
                          value={careGiverData.cid||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อ"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "fname", style: { textAlign: 'left' } }}
                          value={careGiverData.fname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="สกุล"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "lname", style: { textAlign: 'left' } }}
                          value={careGiverData.lname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อที่เด็กเรียก"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "nameCalledByCase", style: { textAlign: 'left' } }}
                          value={careGiverData.nameCalledByCase||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                    </Grid>
                    <Grid container>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Select fullWidth={true} variant="outlined" native
                          onChange={(e) => changeCareGiverData(e)}
                          inputProps={{ name: "relation" }}
                          // value={matchRelativeTypeId}
                          value={careGiverData.relation}
                        >
                          <option value={''}>-เลือกความสัมพันธ์-</option>
                          {relativeData.map((u) => (
                            <option key={u.relativeId} value={u.relativeId}>
                              {u.relativeName}
                            </option>
                          ))}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ระบุความสัมพันธ์"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "relationSpecify", style: { textAlign: 'left' } }}
                          value={careGiverData.relationSpecify||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={disableSaveButton} 
            onClick={clickSaveCareGiver} ล
          >
            บันทึก
          </Button>
          <Button variant="contained" autoFocus
            onClick={() => setOpenMatchDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}