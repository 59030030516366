import React from 'react';
import { TextField } from "@material-ui/core";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";

interface IProps {
  data: {
    key: number;
    name: any;
    placeholder?: string;
    answersList?: Array<Record<string,any>>;
    disable: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  }
}

export default function GenTextfield(props: IProps){
  const { name, disable, uForm } = props.data;
  return <TextField 
      key={name}
      variant="outlined" 
      size="small" 
      disabled={disable}
      placeholder={props.data.placeholder}
      fullWidth 
      {...uForm.register(name)}
      />
}