import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Buffer } from 'buffer';
import axios, { AxiosResponse } from 'axios';

function ThaidCallback(props:any) {
  const [data,setData]=useState({});
  const allParams: any = useMemo(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    let allParams: Record<string,any> = {};
    urlParams.forEach((value, key) => {
      allParams[key]=value;
    });
    return allParams;
  },[]);
  
  const tokenRequest = useCallback(async(params:any)=>{
    if(allParams.code && allParams.code!==""){
      try {
        const url = (process.env.NODE_ENV==="development")? "https://cloud1-npm.moph.go.th/thaid/accessToken" : "https://imauth.bora.dopa.go.th/api/v2/oauth2/token/";
        const headers: any = {
          "Content-Type": (process.env.NODE_ENV==="development") ? "application/json" : "application/x-www-form-urlencoded"
        };
        if(process.env.NODE_ENV!=="development"){
          headers["Authorization"] = params.authorization
          delete params.authorization;
        }
        const axiosInstance = axios.create({headers: headers});
        axiosInstance.interceptors.response.use(
          response => response,
          error => {
            const statusCode = error.response?.status;
            if (statusCode === 403) {
              return Promise.resolve({status: false, statusCode:statusCode, message: error.response.data.error});
            }
            throw error;
          }
        );
        const axiosResponse: AxiosResponse = await axiosInstance.post(url, params);
        if(axiosResponse.status===201){
          delete axiosResponse.data?.data?.access_token;
          delete axiosResponse.data?.data?.refresh_token;
          setData(axiosResponse.data);
        }else{
          setData(axiosResponse);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }else{
      console.log(allParams);
    }
  },[allParams]);
  
  useEffect(() => {
    if(allParams.code && allParams.code!==""){
      const thaidParams = {
        client_id: (process.env.NODE_ENV==="development")?process.env.REACT_APP_DEV_THAID_CLIENT_ID:process.env.REACT_APP_PROD_THAID_CLIENT_ID,
        client_secret : (process.env.NODE_ENV==="development")?process.env.REACT_APP_DEV_THAID_CLIENT_SECRET:process.env.REACT_APP_PROD_THAID_CLIENT_SECRET,
        redirect_uri: (process.env.NODE_ENV==="development")?process.env.REACT_APP_DEV_THAID_CALLBACK:process.env.REACT_APP_PROD_THAID_CALLBACK
      }
      const params = {
        grant_type: "authorization_code",
        authorization: "Basic "+Buffer.from(thaidParams.client_id+":"+thaidParams.client_secret).toString('base64'),
        redirect_uri: thaidParams.redirect_uri,
        code: allParams.code
      }
      tokenRequest(params);
    }
  }, [allParams,tokenRequest]);

  return (
    <pre style={{whiteSpace:"pre-wrap",wordWrap:"break-word"}}>{JSON.stringify(data, null, 2)}</pre>
  );
}

export default ThaidCallback;