/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdDeleteForever as DeleteBin,
  MdAddCircle as BtMdAddCircle,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";

import useGlobal from "../../store";
import * as Api from "../../services/UniversalAPI";

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  },
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  tdQDetail: {
    verticalAlign: "top",
  },
  thead: {
    backgroundColor: "#cdedff",
  },
  trA: {
    backgroundColor: "transparent",
  },
  trB: {
    backgroundColor: "#efefef",
  },
}));

export default function CasePlan(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();
  const [globalState, globalActions] = useGlobal();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [osccPersonList, setOsccPersonList] = useState([]);
  const [focusedOsccPerson, setFocusedOsccPerson] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');


  const confirmReimportCase = () => {
    setOpenBackdrop(true);
    let x = focusedOsccPerson;
    x['caseStatus'] = 'new';
    Api.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          setOpenDialog(false);
          getOsccPersonList();
        }, 500);
      }
    });
  }

  const mkRows = () => {
    let r = [];
    if (osccPersonList.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      osccPersonList.forEach((i) => {
        // console.log(i);
        ++n;
        if (n % 2 === 0) {
          rowColor = "#EEEEEE";
        }
        let isCompleted = false;
        if (typeof i.completeStatus !== 'undefined') {
          isCompleted = true;
        }
        r.push(
          <Grid key={n} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: 20, marginTop: 3, padding: 2, backgroundColor: rowColor }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={1}>
                {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                {n}
              </Grid>
              <Grid item xs={12} sm={2}>
                {isDesktop ? "" : <b>ชื่อ-สกุล: </b>}
                {i.fname} {i.lname}
              </Grid>
              <Grid item xs={12} sm={1}>
                {isDesktop ? "" : <b>เพศ: </b>}
                {i.sex}
              </Grid>
              <Grid item xs={12} sm={1}>
                {isDesktop ? "" : <b>อายุ: </b>}
                {i.age}
              </Grid>
              <Grid item xs={12} sm={2}>
                {isDesktop ? "" : <b>CID: </b>}
                {i.cid}
              </Grid>
              <Grid item xs={12} sm={2}>
                {isDesktop ? "" : <b>ความรุนแรง: </b>}
                {i.severityType}
              </Grid>
              <Grid item xs={12} sm={1}>
                {isDesktop ? "" : <b>ระดับ: </b>}
                {i.riskLevel}
              </Grid>
              <Grid item xs={12} sm={2}>
                {/* <Grid container justify="flex-end" style={{border: "1px solid"}}> */}
                <Grid container justify="flex-end">
                  <Button
                    onClick={() => { setFocusedOsccPerson(i); setDialogSet('reimportCase'); setDialogText('ยืนยัน นำเคสนี้กลับเข้าระบบ ใช่หรือไม่!?'); setOpenDialog(true); }}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "160px", maxWidth: "160px", color: "white", }}
                  >
                    นำเคสกลับเข้าระบบ
                  </Button>
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      });
    }
    return r;
  };

  const getOsccPersonList = async () => {
    let param = { filter: { where: { caseStatus: 'removed' } } };
    Api.getAll(param, "osccpeople").then((response) => {
      if (response.status === 200) {
        // console.log(response.data);
        setOsccPersonList(response.data);
      }
    });
  };

  useEffect(() => {
    getOsccPersonList();
    // eslint-disable-next-line 
  }, []);

  return (
    <div style={{ marginBottom: 100 }}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}>
        <Dialog className={classes.backdrop} open={openBackdrop}>
          <DialogContent>
            <CircularProgress color="inherit" />
          </DialogContent>
        </Dialog>

        <Grid container style={{ marginBottom: 10 }}>
          {/* TABLE HEAD */}
          {isDesktop && (
            <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12} sm={12}>
                  <Grid container justify="flex-start" style={{ borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                    <Grid item xs={12} sm={1} style={{ padding: 4 }}> &nbsp;&nbsp; ลำดับ</Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 4 }}>ชื่อ-สกุล</Grid>
                    <Grid item xs={12} sm={1} style={{ padding: 4 }}>เพศ</Grid>
                    <Grid item xs={12} sm={1} style={{ padding: 4 }}>อายุ</Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 4 }}>CID</Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 4 }}>ความรุนแรง</Grid>
                    <Grid item xs={12} sm={1} style={{ padding: 4 }}>ระดับ</Grid>
                    <Grid item xs={12} sm={2}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* TABLE ROWS */}
          {mkRows()}
        </Grid>

        <Dialog open={openDialog}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component={'div'}>
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {dialogSet === 'reimportCase' && (
              <Button variant="contained" color="primary" onClick={confirmReimportCase}>
                ยืนยัน
              </Button>
            )}
            <Button variant="contained" autoFocus
              onClick={() => setOpenDialog(false)}
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>

      </MuiPickersUtilsProvider>
    </div>
  );
}