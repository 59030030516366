/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { INSERT_ASSIGN } from "../index";

const insertAssign = {
  request: (payload:any) => action(INSERT_ASSIGN.REQUEST, payload),
  success: (response: any) => action(INSERT_ASSIGN.SUCCESS, { data: response }),
  failure: (error: any) => action(INSERT_ASSIGN.FAILURE, { error }),
};

export default insertAssign;