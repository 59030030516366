/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from '../const';
import { INSERT_ANSWER } from '../index';

const insertAnswer = {
  request: (payload: any) => action(INSERT_ANSWER.REQUEST, { data: payload }),
  success: (response: any) => action(INSERT_ANSWER.SUCCESS, { data: response }),
  failure: (error: any) => action(INSERT_ANSWER.FAILURE, { error }),
};

export default insertAnswer;
