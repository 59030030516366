import React, { createContext, Dispatch, useEffect, useRef, useState } from 'react';

interface IProps {
  searchText: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
}

let SearchContext = createContext<Record<string,any>>({ searchText:"" });
const SearchProvider = (props:IProps) => {
  const {searchText,setLoading} = props;
  // const timer = useRef<NodeJS.Timeout|null>(null);
  // const [search, setSearch] = useState("");
  // useEffect(() => {
  //   timer.current = setTimeout(() => {
  //     setSearch(searchText||"");
  //   },1000);
  //   return () => clearTimeout(timer.current as NodeJS.Timeout);
  // },[searchText]);

  return (
    <SearchContext.Provider value={{ searchText,setLoading }}>
      {props.children}
    </SearchContext.Provider>
  );
}


interface DialogProps {
  data: Record<string,any>;
  dialogState: {
    showDialog: boolean;
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  };
  children: any;
}
let DialogContext = createContext<Record<string,any>>({ data:{}, open:false });
const DialogProvider = (props:DialogProps) => {
  const {data, dialogState:dialog} = props;
  return (
    <DialogContext.Provider value={{ 
      data: data, 
      open: dialog.showDialog, 
      close: ()=>dialog.setShowDialog(false) }}
    >
      {props.children}
    </DialogContext.Provider>
  );
}

export { 
  SearchContext, 
  SearchProvider,
  DialogContext,
  DialogProvider
};