
import changwat from "./changwat";
import ampur from "./ampur";
import tambon from "./tambon";
import village from "./village";
import agency from "./agency";
import setFillter from "./setFillter"
export default{
  changwat,
  ampur,
  tambon,
  village,
  agency,
  setFillter
}