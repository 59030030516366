import { GridRowData } from "@material-ui/data-grid";
import { callGet, callPost, callDelete, callPatch } from "../../../services/request";
import { getToken } from "../../../services/api/getToken";
const token = getToken();

export const getTeams = async (payload: any={}) => {
	let params: any = {
	  access_token: token,
	  filter: {
			order: "dupdate desc",
	  },
	};
	
	if(payload.where && payload.where!==null){
	  params.filter["where"] = payload.where;
	}
  
	if (payload.pagination) {
	  params.filter["limit"] = payload.pagination.limit;
	  params.filter["skip"] = payload.pagination.skip;
	}

	const response = await callGet(`osccteams`, token , params);
	return { 
		data : response.response.data as readonly GridRowData[], 
		totalPage: response.response.headers["x-total-count"] as number
	};
};

export const getUsers = async (payload: any={}) => {
	let params: any = {
		access_token: token,
		filter: {
			fields: {
				id: true,
				fullname: true,
				cid: true,
				changwat: true,
				amphur: true,
				tambon: true,
				department: true,
				osccteams: true,
			},
		},
	};
	if(payload.where){
		params.filter["where"] = payload.where;
	}
	if (payload.pagination) {
	  params.filter["limit"] = payload.pagination.limit;
	  params.filter["skip"] = payload.pagination.skip;
	}
	const response = await callGet(`teamusers`, token , params);
	return { 
		data : response.response.data as readonly GridRowData[], 
		totalPage: response.response.headers["x-total-count"] as number
	};
};