import React from "react";
import { Line } from "react-chartjs-2";
// https://www.chartjs.org/docs/latest/configuration/elements.html#line-configuration
const options={
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
    },
    y: {
      beginAtZero: true,
      max:60,
      grid: {
        display: true,
        drawBorder: false
      },
      ticks: {
        padding: 10,
        labelOffset: 0,
        // callback(value, index) {
        //    if (index % 2 !== 0) return '';
        //    return value;
        // },
      },
    },
  },
  plugins:{
    title: {
      display: true,
      text: 'ระดับความเสี่ยง',
      font: { size: 20 },
      padding: { bottom: 20 }
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: true,
      labels: {
        padding: 10
      }
    }
  },
  aspectRatio: function (context) {
    var width = context.chart.width;
    return (width < 400) ? 3 / 4 : 4 / 3;
  },
}
const data = {
  labels: ["ปี 2563", "ปี 2564", "ปี 2565"],
  datasets: [
    {
      label: "เสี่ยงต่ำ",
      data: [10, 12, 20],
      fill: false,
      tension:0.3,
      pointRadius: 0,
      borderColor: "#0077b6",
      backgroundColor: '#0077b6'
    },
    {
      label: "เสี่ยงปานกลาง",
      data: [20, 25, 40],
      fill: false,
      tension:0.3,
      pointRadius: 0,
      borderColor: "#fb8500",
      backgroundColor: '#fb8500'
    },
    {
      label: "เสี่ยงสูง",
      data: [35, 40, 50],
      fill: false,
      tension:0.3,
      pointRadius: 0,
      borderColor: "#ccc",
      backgroundColor: '#ccc'
    }
  ]
};

export default function LineChart() {
  return (<Line data={data} options={options} />)
}