// import { LoginResponse } from '../../types';
import { createRequestType } from '../const';
import { action } from '../const';

export const testAction = {
  request: () => action(TEST.REQUEST),
  success: (data: any) =>
    action(TEST.SUCCESS, {
      data,
    }),
  failure: (error: any) =>
    action(TEST.FAILURE, {
      error,
    }),
};

export const TEST = createRequestType('TEST');
