import { COUNT_SEVERITY } from "../../actions";
import { AnyAction } from "redux";
import { CountSeverity } from "../../types/dashboard";

const initialState:CountSeverity = {
  isFetching: false,
  data: {
    mental:0,
    physical:0,
    sexual:0
  }
};

export default function countCaseStatus(
  state = initialState,
  action: AnyAction
): CountSeverity {
  switch (action.type) {
    case COUNT_SEVERITY.REQUEST:{
      return {
        ...state,
        isFetching: true,
        data: {
          mental:0,
          physical:0,
          sexual:0
        }
      };
    }
    case COUNT_SEVERITY.SUCCESS: {
      console.log("COUNT_SEVERITY",action);
      return {
        ...state,
        isFetching: false,
        data: {
          mental:0,
          physical:0,
          sexual:0
        }
      };
    }
    case COUNT_SEVERITY.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {
          mental:0,
          physical:0,
          sexual:0
        }
      };
    default:
      return state;
  }
}
