/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from '../const';
import { UPDATE_ANSWER } from '../index';

const updateAnswer = {
  request: (payload: any) => action(UPDATE_ANSWER.REQUEST, { data: payload }),
  success: (response: any) => action(UPDATE_ANSWER.SUCCESS, { data: response }),
  failure: (error: any) => action(UPDATE_ANSWER.FAILURE, { error }),
};

export default updateAnswer;
