import { GET_QUESTIONAIRE } from "../../actions";
import { AnyAction } from "redux";
import { Questionaire } from "../../types/activity";
import { constant } from "lodash";
// import moment from 'moment';
// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const initialState:Questionaire = {
  isFetching:false,
  isSuccess:false,
  count: 0,
  data: []
};

export default function getQuestionaire(
  state = initialState,
  action: AnyAction
): Questionaire {
  switch (action.type) {
    case GET_QUESTIONAIRE.REQUEST:{
      // console.log("GET_QUESTIONAIRE.REQUEST");
      return {
        ...state,
        isFetching: true,
        isSuccess:false
      };
    }
    case GET_QUESTIONAIRE.SUCCESS: {
      // console.log("GET_QUESTIONAIRE.SUCCESS",action.data);
      const newData = action.data.rows.filter((f:any)=>{
        if(f.questionnaire_answer){
          if(f.questionnaire_answer.length===0 && f.disabled){
            action.data.count--;
            return false;
          }
        }
        return true;
      });
      // console.log(newData);
      return {
        ...state,
        isFetching: false,
        isSuccess:true,
        count: action.data.count,
        data: newData
      };
    }
    case GET_QUESTIONAIRE.FAILURE: {
      // console.log("GET_QUESTIONAIRE.FAILURE",action.data);
      return {
        ...state,
        isFetching: false,
        isSuccess:false,
        data: [],
      };
    }
    default:
      return state;
  }
}
