import { DELETE_GROUP } from "../../actions";
import { AnyAction } from "redux";
import { DeleteGroup } from "../../types/activity";

const initialState:DeleteGroup = {
  isFetching: false,
  data: {}
};

export default function getGroupList(
  state = initialState,
  action: AnyAction
): DeleteGroup {
  switch (action.type) {
    case DELETE_GROUP.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case DELETE_GROUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case DELETE_GROUP.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
