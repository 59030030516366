/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { COUNT_NEW } from "../index";

const countCaseStatus = {
  request: (payload:Record<string,any>) => action(COUNT_NEW.REQUEST, payload),
  success: (response: any) => action(COUNT_NEW.SUCCESS, { data: response }),
  failure: (error: any) => action(COUNT_NEW.FAILURE, { error }) ,
};

export default countCaseStatus;