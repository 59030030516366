import { GET_TEAMSUSERS, GET_HOSPITALINFO } from "../../actions";
import { AnyAction } from "redux";
import { TeamsUsersData } from "../../types/teams";
import _ from "lodash";

const initialState:TeamsUsersData = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getTeamsUsers(
  state = initialState,
  action: AnyAction
): TeamsUsersData {
  switch (action.type) {
    case GET_TEAMSUSERS.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case GET_TEAMSUSERS.SUCCESS: {
      const newData = action.data.rows.map((item:any)=>{
        item["role"]=item.RoleMapping[0]?.Role.name||"";
        delete item.RoleMapping;
        return item
      });
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: newData
      };
    }
    case GET_HOSPITALINFO.SUCCESS: {
      const hosinfo = action.data.rows;
      const userList = state.data;
      if(hosinfo && hosinfo!==null &&hosinfo.length>0){
        userList.forEach((user:any)=>{
          const tambonInfo= hosinfo.filter((hos:any)=>user.department.hcode===hos.hos_id)[0];
          if(!_.isEmpty(tambonInfo)){
            user["tambon"]= {
              tambon_id: tambonInfo.tambol.addressid,
              tambon_name: tambonInfo.tambol.name
            }
          }
        });
      }
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: userList
      };
    }
    case GET_TEAMSUSERS.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false
      };
    default:
      return state;
  }
}
