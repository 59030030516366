/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as UAPI from "../../services/UniversalAPI";
import UCard from "../../services/universal/UniversalCard";
import { MdRemoveRedEye as MdRemoveRedEyeIcon } from 'react-icons/md';
import { setFocusedCaseStatus } from "../../store/actions";
import { v1 as uuid } from 'uuid';
const useStyles = makeStyles({
  linkDateServ: {
    cursor: 'pointer',
    '&:hover': {
      background: "#cdf1ff",
    },
  },
});

export default function CaseEMR(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [data, setData] = useState([]);
  const [personData, setPersonData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitleText, setDialogTitleText] = useState('');
  const [focusedLabDetail, setFocusedLabDetail] = useState([]);

  
  const collection = {
    serviceInfo: {
      fields: {
        vstdate: { show: true, title: 'วันที่บริการ', data_type: 'date', format: 'thai_short' },
        vsttime: { show: true, title: 'เวลา', data_type: 'string' },
        hcode: { show: true, title: 'รหัสหน่วยบริการ', data_type: 'string' },
        hname: { show: true, title: 'ชื่อหน่วยบริการ', data_type: 'string' },
      }
    },
    assessmentVitalSign: {
      fields: {
        bw: { show: true, title: 'น้ำหนัก', data_type: 'integer' },
        height: { show: true, title: 'ส่วนสูง', data_type: 'integer' },
        bmi: { show: true, title: 'BMI', data_type: 'float' },
        waist: { show: true, title: 'รอบเอว', data_type: 'integer' },
        bps: { show: true, title: 'BPS', data_type: 'integer' },
        bpd: { show: true, title: 'BPD', data_type: 'integer' },
        pulse: { show: true, title: 'ชีพจร', data_type: 'integer' },
        rr: { show: true, title: 'อัตราการหายใจ', data_type: 'integer' },
        temperature: { show: true, title: 'อุณหภูมิ', data_type: 'integer' },
      }
    },
    assessmentVitalSignA: {
      fields: {
        bw: { show: true, title: 'น้ำหนัก', data_type: 'integer' },
        height: { show: true, title: 'ส่วนสูง', data_type: 'integer' },
        bmi: { show: true, title: 'BMI', data_type: 'float' },
        waist: { show: true, title: 'รอบเอว', data_type: 'integer' },
      }
    },
    assessmentVitalSignB: {
      fields: {
        bps: { show: true, title: 'BPS', data_type: 'integer' },
        bpd: { show: true, title: 'BPD', data_type: 'integer' },
        pulse: { show: true, title: 'ชีพจร', data_type: 'integer' },
        rr: { show: true, title: 'อัตราการหายใจ', data_type: 'integer' },
        temperature: { show: true, title: 'อุณหภูมิ', data_type: 'integer' },
      }
    },
    assessmentCC: {
      fields: {
        cc: { show: true, title: 'Chief Complain', data_type: 'string' },
      }
    },
    assessmentSymptom: {
      fields: {
        symptom: { show: true, title: 'อาการ', data_type: 'string' },
      }
    },
    assessmentHpi: {
      fields: {
        hpi: { show: true, title: 'HPI', data_type: 'string' },
      }
    },
    assessmentPmh: {
      fields: {
        pmh: { show: true, title: 'PMH', data_type: 'string' },
      }
    },
    assessmentDiagnosis: {
      fields: {
        diag_name: { show: true, title: 'diagnosis', data_type: 'string' },
      }
    },
    assessmentTreatment: {
      fields: {
        drug_name: { show: true, title: 'drug name', data_type: 'string' },
        // doctor_name: { show: true, title: 'doctor name', data_type: 'string' },
      }
    },
    general_info_A: {
      fields: {
        cid: { show: true, title: 'CID', data_type: 'string', format: null, input_type: 'textbox', icon: 'MdDateRange', validation: 'number', value_length_type: 'fix', value_length_count: 13, value_length_min: null, value_length_max: null },
        fname: { show: true, title: 'ชื่อ', data_type: 'string', format: null, input_type: 'textbox', icon: 'MdDateRange', validation: 'string', value_length_type: 'range', value_length_count: null, value_length_min: 3, value_length_max: 5 },
        lname: { show: true, title: 'สกุล', data_type: 'string', format: null, input_type: 'textbox', icon: 'MdDateRange' },
        sex_name: { show: true, title: 'เพศ', data_type: 'string' },
        birthday: { show: true, title: 'วันเกิด', data_type: 'date', format: 'thai_short', input_type: 'datepicker' },
      }
    },
    general_info_B: {
      fields: {
        addressInfo: { show: true, title: 'ที่อยู่', data_type: 'string', format: null },
      }
    },
    general_info_C: {
      fields: {
        fathername: { show: true, title: 'ชื่อพ่อ' },
        fatherlname: { show: true, title: 'สกุลพ่อ' },
        mothername: { show: true, title: 'ชื่อแม่' },
        motherlname: { show: true, title: 'สกุลแม่' },
      }
    },
    general_info_D: {
      fields: {
        informname: { show: true, title: 'ผู้ที่สามารถติดต่อได้' },
        informaddr: { show: true, title: 'ที่อยู่ของผู้ที่สามารถติดต่อได้' },
      }
    },    
  };

  const getPersonData = async (p) => {
    if (typeof p.cid !=='undefined') {
      if (p.cid!== null && p.cid!=='') {

        let xParams = {
          filter: {
            limit: 1,
            where: {
              cid : p.cid
            }
          }
        };

        let response = await UAPI.getAll(xParams, 'people');
        if (response.status === 200) {
          if (response.data) {
            let d = response.data[0];
            let tambolInfo="";
            if(d){
              let paramTambol = { filter: { 
                limit: 1,
                where: { addressid: d.chwpart+d.amppart+d.tmbpart } 
              }};
              let responseTambol = await UAPI.getAll(paramTambol,"tambols");
              if (responseTambol.status === 200) {
                if (responseTambol.data.length>0) {
                  // console.log(responseTambol.data);
                  tambolInfo=responseTambol.data[0].full_name;
                }
              }
              d.cid = d.cid.replace(d.cid.substring(3,10),"xxxxxxx")
              d['addressInfo']=d.addrpart+' ม.'+parseInt(d.moopart)+' '+tambolInfo;
              d['riskLevel']=props.location.state['riskLevel'];
              d['severityType']=props.location.state['severityType'];
              // console.log(d);
              setPersonData(d);
            }
          }
        }

      }
    }
  }

  const getData = async () => {
    let xParams = {
      filter: {
        order: ['vstdate DESC'],
        limit: 20,
        where: {
          // personId: props.data.id
          // cid : props.data.cid
          cid : props.location.state.cid
        }
      }
    };

    let response = await UAPI.getAll(xParams, 'interventions');
    if (response.status === 200) {
      if (response.data) {
        setData(response.data);
        //  console.log(response.data);
      }
    }
  }

  const mkVisitList = () => {
    let dateList=[];
    // console.log(data);
    if (data.length>0) {
      data.forEach(i => {
        // console.log(i);
        let dateItem=[];

        let visitInfo=[];
        visitInfo.push(
          <Grid key={genKey()} item xs={12} sm={12}>
            <UCard structure={collection.serviceInfo} display_style="inline" data={i} style={{margin: 2 }} />
          </Grid>
        );
        if (typeof i.activities !== 'undefined') {
          if (typeof i.activities.assessment !== 'undefined') {
            visitInfo.push(
              <Grid key={genKey()} item xs={12} sm={12}>
                <UCard structure={collection.assessmentVitalSign} display_style="inline" data={i.activities.assessment[0]} style={{margin: 2}} />
              </Grid>
            );
            visitInfo.push(
              <Grid key={genKey()} item xs={12} sm={12}>
                <UCard structure={collection.assessmentCC} display_style="inline" data={i.activities.assessment[0]} style={{margin: 2}} />
              </Grid>
            );
          }
        }
        dateItem.push(
          <Grid key={genKey()} container style={{ backgroundColor: '#FFFFFF', padding: 2, borderRadius: 10}}>
            {visitInfo}
          </Grid>
        );
        
        if (typeof i.activities !== 'undefined') {
          if (typeof i.activities.diagnosis !== 'undefined') {
            let diagList = [];
            i.activities.diagnosis.forEach(x => {
              let n = ['0','1','2','3','4','5','6','7','8','9'];
              if (!n.includes(x.icd10.substr(0,1))) {
                if (isDesktop) {
                  diagList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 15, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start">
                        <Grid item xs={12} sm={1}> &nbsp;&nbsp; {x.diagtype}</Grid>
                        <Grid item xs={12} sm={1}>{x.icd10}</Grid>
                        <Grid item xs={12} sm={10}>{x.diag_name}</Grid>
                      </Grid>
                    </Grid>
                  );
                }
                else {
                  diagList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 7, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start" style={{padding: 5}}>
                        <Grid item xs={12} sm={12}>
                          <b>[{x.diagtype}] {x.icd10} : </b>{x.diag_name}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              }
            });
            if (diagList.length>0) {
              if (isDesktop) {
                diagList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#00a8ff', color: '#FFFFFF', marginTop: 2 }}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={1}> &nbsp;&nbsp; DiagType</Grid>
                      <Grid item xs={12} sm={1}>ICD10</Grid>
                      <Grid item xs={12} sm={10}>วินิจฉัย</Grid>
                    </Grid>
                  </Grid>
                );
              }
              else {
                diagList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#00a8ff', color: '#FFFFFF', marginTop: 2 }}>
                    &nbsp;&nbsp; การวินิจฉัย
                  </Grid>
                );
              }
            }
            dateItem.push(
              <Grid key={genKey()} container>
                {diagList}
              </Grid>
            );
          }
        }
        
        if (typeof i.activities !== 'undefined') {
          if (typeof i.activities.treatment !== 'undefined') {
            let drugList = [];
            i.activities.treatment.forEach(x => {
              let u = '';
              if (typeof x.shortlist !== 'undefined') { if (x.shortlist !== null && x.shortlist.trim() !== '') {
                u = x.shortlist;
              }}
              if (typeof x.sp_use_name !== 'undefined') { if (x.sp_use_name !== null && x.sp_use_name.trim() !== '') {
                u = x.sp_use_name;
              }}
              if (typeof x.drugusage_name !== 'undefined') { if (x.drugusage_name !== null && x.drugusage_name.trim() !== '') {
                u = x.drugusage_name;
              }}
              if (x.sub_type==='1') {
                if (isDesktop) {
                  drugList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 15, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start">
                        <Grid item xs={12} sm={9}>
                          <div style={{ paddingLeft: 10 }}>{x.drug_name} {x.strength}</div>
                          <div style={{ paddingLeft: 10, marginTop: -5, fontSize: 13, color: '#76879A' }}>{u}</div>
                        </Grid>
                        <Grid item xs={12} sm={1}>{x.qty}</Grid>
                        <Grid item xs={12} sm={2}>{x.units}</Grid>
                      </Grid>
                    </Grid>
                  );
                }
                else {
                  drugList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 7, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start" style={{ padding: 5}}>
                        <Grid item xs={12} sm={12}>
                          <b>ชื่อยา : </b>{x.drug_name} {x.strength}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <b>วิธีใช้ : </b>{u}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <b>จำนวน : </b>{x.qty} {x.units}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              }
            });
            if (drugList.length>0) {
              if (isDesktop) {
                drugList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#00D42F', color: '#FFFFFF', marginTop: 2 }}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={9}> &nbsp;&nbsp; ชื่อยา/วิธีใช้</Grid>
                      <Grid item xs={12} sm={1}>จำนวน</Grid>
                      <Grid item xs={12} sm={2}>หน่วย</Grid>
                    </Grid>
                  </Grid>
                );
              }
              else {
                drugList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#00D42F', color: '#FFFFFF', marginTop: 2 }}>
                    &nbsp;&nbsp; รายการยา
                  </Grid>
                );
              }
            }
            dateItem.push(
              <Grid key={genKey()} container>
                {drugList}
              </Grid>
            );
          }
        }

        if (typeof i.activities !== 'undefined') {
          if (typeof i.activities.homeservice !== 'undefined') {
            let homeServiceList = [];
            i.activities.homeservice.forEach(x => {
              let n = ['oscc'];
              if (n.includes(x.serviceType)) {
                x.questionnaire.forEach(h => {
                  if (isDesktop) {
                    homeServiceList.push(
                      <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 15, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                        <Grid container justify="flex-start">
                          <Grid item xs={12} sm={1}> &nbsp;&nbsp; {x.serviceType}</Grid>
                          <Grid item xs={12} sm={10}>{h.questionnaire_name}</Grid>
                          <Grid item xs={12} sm={1}>{h.sumScore}</Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                  else {
                    homeServiceList.push(
                      <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 7, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                        <Grid container justify="flex-start" style={{padding: 5}}>
                          <Grid item xs={12} sm={12}>
                            <b>ประเภท : </b>{x.serviceType}</Grid>
                          <Grid item xs={12} sm={12}>
                            <b>กิจกรรม : </b>{h.questionnaire_name}
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <b>คะแนน : </b>{h.sumScore}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                });
              }
            });
            if (homeServiceList.length>0) {
              if (isDesktop) {
                homeServiceList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#F8D14A', color: '#000000', marginTop: 2 }}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={1}> &nbsp;&nbsp; ประเภท</Grid>
                      <Grid item xs={12} sm={10}>Home Service</Grid>
                      <Grid item xs={12} sm={1}>คะแนน</Grid>
                    </Grid>
                  </Grid>
                );
              }
              else {
                homeServiceList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#F8D14A', color: '#000000', marginTop: 2 }}> 
                    &nbsp;&nbsp; Home Service
                  </Grid>
                );
              }
            }
            dateItem.push(
              <Grid key={genKey()} container>
                {homeServiceList}
              </Grid>
            );
          }
        }
        
        if (typeof i.activities !== 'undefined') {
          if (typeof i.activities.laboratory !== 'undefined') {
            // console.log(i.activities.laboratory);
            let labHeadList = [];
            let laboratory = i.activities.laboratory;
            let labHead=[];
            let labItemGroup=[];
            if (laboratory.length>0) {
              laboratory.forEach(i => {
                if (typeof i.form_name !== 'undefined') {
                  if (typeof labHead[i.lab_order_number] === 'undefined') {
                    labHead[i.lab_order_number]={lab_order_number:i.lab_order_number, form_name:i.form_name};
                  }
                }
                if (typeof labItemGroup[i.lab_order_number] === 'undefined') {
                  labItemGroup[i.lab_order_number]=[];
                  labItemGroup[i.lab_order_number].push(i);
                }
                else {
                  labItemGroup[i.lab_order_number].push(i);
                }
              });

              labHead.forEach(i => {
                if (isDesktop) {
                  labHeadList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start">
                        <Grid item xs={12} sm={9}> &nbsp;&nbsp; {i.form_name}</Grid>
                        <Grid item xs={12} sm={3} style={{ textAlign: 'right', paddingRight: 5 }}>
                          <Button
                            onClick={() => {
                              // console.log(labItemGroup[i.lab_order_number]);
                              setFocusedLabDetail(labItemGroup[i.lab_order_number]);
                              setDialogTitleText(i.form_name);
                              setOpenDialog(true);
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: 0 }} />}
                            style={{ marginTop: -6, marginLeft: 5, height: 20, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                else {
                  labHeadList.push(
                    <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 7, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
                      <Grid container justify="flex-start">
                        <Grid item xs={9} sm={9}> &nbsp;&nbsp; {i.form_name}</Grid>
                        <Grid item xs={3} sm={3} style={{ textAlign: 'right', paddingRight: 5 }}>
                          <Button
                            onClick={() => {
                              // console.log(labItemGroup[i.lab_order_number]);
                              setFocusedLabDetail(labItemGroup[i.lab_order_number]);
                              setDialogTitleText(i.form_name);
                              setOpenDialog(true);
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: 0 }} />}
                            style={{ marginTop: -6, marginLeft: 5, height: 20, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              });

              if (labHeadList.length>0) {
                labHeadList.unshift(
                  <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#FF7CBB', color: '#FFFFFF', marginTop: 2 }}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={12}> &nbsp;&nbsp; รายการแลป</Grid>
                      {/* <Grid item xs={12} sm={1}>ผล</Grid>
                      <Grid item xs={12} sm={1}>หน่วย</Grid>
                      <Grid item xs={12} sm={2}>ค่าปกติ</Grid> */}
                    </Grid>
                  </Grid>
                );
              }
            }

            dateItem.push(
              <Grid key={genKey()} container>
                {labHeadList}
              </Grid>
            );
          }
        }

        dateList.push(
          <div key={genKey()} style={{border: 'solid 3px #555555', borderRadius: 5, padding: 5, marginTop: 10, backgroundColor: '#EBEBEB' }}>
            {dateItem}
          </div>
        );
      });
    }
    return dateList;
  }

  const mkLabList = () => {
    let labList = [];
    // console.log(focusedLabDetail);
    focusedLabDetail.forEach(i => {
      if (isDesktop) {
        labList.push(
          <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 15, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={6}> &nbsp;&nbsp; {i.lab_items_name}</Grid>
              <Grid item xs={12} sm={2}>{i.lab_order_result}</Grid>
              <Grid item xs={12} sm={2}>{i.lab_items_unit}</Grid>
              <Grid item xs={12} sm={2}>{i.lab_items_normal_value}</Grid>
            </Grid>
          </Grid>
        );
      }
      else {
        labList.push(
          <Grid key={genKey()} item xs={12} ms={12} style={{ border: 'solid 1px #CFCFCF', borderRadius: 7, backgroundColor: '#FFFFFF', color: '#000000', marginTop: 2 }}>
            <Grid container justify="flex-start" style={{padding: 5}}>
              <Grid item xs={12} sm={12}>
                <b>ชื่อแลป : </b>{i.lab_items_name}
              </Grid>
              <Grid item xs={12} sm={12}>
                <b>ผลแลป : </b>{i.lab_order_result} {i.lab_items_unit}
              </Grid>
              <Grid item xs={12} sm={12}>
                <b>ค่าปกติ : </b>{i.lab_items_normal_value} 
              </Grid>
            </Grid>
          </Grid>
        );
      }
    });
    return labList;
  }

  const dialogContentInfo = () => {
    return (
      <div>
        {/* {JSON.stringify(focusedLabDetail)} */}
        <Grid container>
          <Grid item xs={12} ms={12} style={{ border: 'solid 0px #666666', borderRadius: 15, backgroundColor: '#FF7CBB', color: '#FFFFFF', marginTop: 2 }}>
            {isDesktop===true?(
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={6}> &nbsp;&nbsp; แลป</Grid>
                <Grid item xs={12} sm={2}>ผล</Grid>
                <Grid item xs={12} sm={2}>หน่วย</Grid>
                <Grid item xs={12} sm={2}>ค่าปกติ</Grid>
              </Grid>
            ):(
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={12}> &nbsp;&nbsp; รายการแลป</Grid>
              </Grid>
            )}
          </Grid>
          {mkLabList()}
        </Grid>
      </div>
    );
  }

  const genKey = () => {
    return uuid();
    // return Math.floor(Math.random() * 1000000) + 1;
  }

  useEffect(() => {
    if (typeof props.location.state.cid !== 'undefined') {
      if (typeof props.location.state.cid !== 'undefined') {
        // console.log(props.data);
        getData();
      }
    }
    if (typeof props.location.state !== 'undefined') {
      let x = props.location.state;
      // console.log(x);
      // x['sex_name']=x['sex']==='1'?'ชาย':'หญิง';
      // x['death_name']=x['death']==='N'?'ยังมีชีวิตอยู่':'เสียชีวิตแล้ว';
      // x['riskLevel_name']=x['riskLevel']==='2'?'Medium Risk':'Low Risk';
      getPersonData(x);
    }
  }, [props]); 


  return (
    <div style={{marginBottom:100}}>

      <Grid container style={{ marginTop: -10, marginBottom: 10 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
      {/* <Grid container style={{ marginTop: -15, marginBottom: 10 }} direction={isDesktop ? "row" : "column-reverse"} > */}
        <Grid item xs={12} sm={6}>
          <h4>ประวัติการรักษา (EMR)</h4>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justify="flex-end">
            <ButtonGroup size={isDesktop?'large':'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
              <Button color="primary" 
              style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
              onClick={() => { history.goBack(); }} 
              >
                กลับ
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      {Object.keys(personData).length>0 && (
        <Grid container style={{ padding: 0 }}>
          <Grid item xs={12} sm={12} style={{ paddingBottom: 2 }}>
            <UCard structure={collection.general_info_A} display_style="inline" data={personData} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingBottom: 2 }}>
            <UCard structure={collection.general_info_B} display_style="inline" data={personData} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingBottom: 2 }}>
            <UCard structure={collection.general_info_C} display_style="inline" data={personData} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingBottom: 2 }}>
            <UCard structure={collection.general_info_D} display_style="inline" data={personData} />
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} sm={12} container style={{ background: '#555555', color: '#FFFFFF', borderRadius: 5,marginTop: 20 }}>
        &nbsp;&nbsp; ประวัติการรับบริการ (ในพื้นที่เขตสุขภาพที่ 8)
      </Grid>

      {data.length>0 && 
        mkVisitList()
      }

      <Dialog open={openDialog} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle style={{ paddingBottom: 0, marginBottom: 0, border: 'solid 0px red'}}>
          {dialogTitleText}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, marginTop: 0}}>
          <DialogContentText component={'div'}>
            {focusedLabDetail.length>0&&(
              dialogContentInfo()
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={()=> setOpenDialog(false) } >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}
