/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UDataTable from "../../services/universal/UniversalDataTable";
import * as UAPI from "../../services/UniversalAPI";
import { getCurrentUser } from "../../services/auth.service";
import { useHistory } from 'react-router-dom';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdDeleteForever as DeleteBin,
  MdAddCircle as BtMdAddCircle,
} from "react-icons/md";

import {
  Button,
  TextField,
  CircularProgress,
  Backdrop,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tdQDetail: {
    verticalAlign: 'top',
  },
  thead: {
    backgroundColor: '#cdedff'
  },
  trA: {
    backgroundColor: 'transparent'
  },
  trB: {
    backgroundColor: '#efefef'
  }
}));

export default function Teams(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();
  const classes = useStyles();
  const history = useHistory();

  const [teamUsersData, setTeamUsersData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDialogTeam, setOpenDialogTeam] = useState(false);
  const [focusedTeam, setFocusedTeam] = useState({});
  const [memberList, setMemberList] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMemberType, setSelectedMemberType] = useState({});
  const [teamList, setTeamList] = useState([]);

  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


  const getTeamUsers = async () => {
    // console.log('getTeamUsers---------');
    let xParams = {
      filter: {
        // limit: 20,
        where: { 
          and: [
            { 'amphur.amphur_id': userinfo.user.amphur.amphur_id },
            {"application":{"like":"OSCC","options":"i"}}
          ]
        },
        order: "department.hcode DESC"
      }
    };
    let response = await UAPI.getAll(xParams, 'teamusers', '3009');
    if (response.status === 200) {
      if (response.data) {
        setTeamUsersData(response.data);
        // console.log(response.data);
        let t=[];
        response.data.forEach(i => {
          if (typeof i.osccteams !== 'undefined') {
            i.osccteams.forEach(z => {
              let n=0;
              t.forEach(v => {
                if (v.teamCode===z.teamCode) {
                  n=1;
                }
              });
              if (n===0) {
                t.push({ teamCode: z.teamCode, teamName: z.teamName});
              }
            });
          }
        });
        // console.log(t);
        setTeamList(t);
      }
    }
  }
  
  const clickAddTeam = () => {
    setFocusedTeam({});
    setMemberList([]);
    setOpenDialogTeam(true);
  }

  const clickCloseDialogTeam = () => {
    setOpenDialogTeam(false);
    setFocusedTeam({});
    setMemberList([]);
  };

  const clickEditTeamData = (t) => {
    let x = JSON.parse(JSON.stringify(teamUsersData));
    let members = [];
    x.forEach(m => {
      if (typeof m.osccteams !== 'undefined') {
        m.osccteams.forEach(z => {
          if (z.teamCode===t.teamCode) {
            m.memberTypeId=z.memberTypeId;
            m.memberTypeName=z.memberTypeName;
            members.push(m);
          }
        });
      }
    });
    setMemberList(members);
    setFocusedTeam(t); 
    setOpenDialogTeam(true);
  }

  const mkTeamList = () => {
    let r = [];
    if (teamList.length>0) {
      // console.log(memberList);
      let n = 0;
      let rowColor = "";
      teamList.forEach((i) => {
        if (i) {
          ++n;
          if (n % 2 === 0) {
            rowColor = "#EEEEEE";
          }
          else {
            rowColor = "#FFFFFF";
          }
          r.push(
            <Grid key={n} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: 20, marginTop: 3, padding: 2, backgroundColor: rowColor }} >
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={1}>
                  {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                  {n}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {isDesktop ? "" : <b>ชื่อทีม: </b>}
                  {i.teamName}
                </Grid>
                {/* <Grid item xs={12} sm={2}>
                  {isDesktop ? "" : <b>สมาชิก: </b>}
                  {i.countMember}
                </Grid> */}
                <Grid item xs={12} sm={2}>
                  <Grid container justify="flex-end">
                    <Button variant="contained" color="secondary" startIcon={ <DeleteBin style={{ marginLeft: 11, marginTop: -2 }} /> } style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }} 
                      onClick={() => { setFocusedTeam(i); setDialogSet('deleteTeam'); setDialogText('ยืนยันลบทีมนี้ ใช่หรือไม่!?'); setOpenConfirmDialog(true); }}
                    />
                    <Button variant="contained" color="primary" startIcon={ <MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} /> } style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }}
                      onClick={() => { clickEditTeamData(i); }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      });
    }
    return r;
  };

  const mkMemberList = () => {
    let r = [];
    if (memberList.length>0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      memberList.forEach((i) => {
        if (i) {
          if (typeof i.removed_item === 'undefined') {
            ++n;
            if (n % 2 === 0) {
              rowColor = "#EEEEEE";
            }
            else {
              rowColor = "#FFFFFF";
            }
            r.push(
              <Grid key={n} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: 20, marginTop: 3, padding: 2, backgroundColor: rowColor }} >
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {isDesktop ? "" : <b>ชื่อสมาชิก: </b>}
                    {i.fullname}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {isDesktop ? "" : <b>หน่วยบริการ: </b>}
                    {i.department.hos_name}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>ประเภท: </b>}
                    {i.memberTypeName}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Grid container justify="flex-end">
                      <Button variant="contained" color="secondary" startIcon={ <DeleteBin style={{ marginLeft: 11, marginTop: -2 }} /> } style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "80px", maxWidth: "80px", color: "white", }}
                      onClick={() => clickDeleteMember(i)}
                      >
                        ลบ
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        }
      });
    }
    return r;
  };

  const clickSaveTeam = async () => {
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(memberList));

    if (typeof focusedTeam.teamCode !== 'undefined') {
      // console.log('แก้ทีมเก่า');
      x.forEach(m => {
        // console.log(m.removed_item);
        let t = {};
        t.teamCode=focusedTeam.teamCode;
        t.teamName=focusedTeam.teamName;
        t.memberTypeId=m.memberTypeId;
        t.memberTypeName=m.memberTypeName;
        delete m.memberTypeId;
        delete m.memberTypeName;
        let exists_osccteams=0;
        if (typeof m.osccteams !== 'undefined') {
          if (m.osccteams.length>0) {
            exists_osccteams=1;
          }
        }
        if (exists_osccteams===1) {
          let ta = m.osccteams;
          let ta_cleaned = [];
          ta.forEach(ccc => {
            if (ccc.teamCode!==t.teamCode) {
              ta_cleaned.push(ccc);
            }
          });
          if (typeof m.removed_item === 'undefined') { // เป็น removed_item เป็น undefined แสดงว่าไม่ถูกลบออกจากทีม ก็เพิ่มเข้าทีมเลย
            ta_cleaned.push(t);
          }
          m.osccteams=ta_cleaned;
        }
        else { // ไม่เคยอยู่ใน team ไหนมาก่อน
          let ta=[];
          if (typeof m.removed_item === 'undefined') { // เป็น removed_item เป็น undefined แสดงว่าไม่ถูกลบออกจากทีม ก็เพิ่มเข้าทีมเลย
            ta.push(t);
          }
          m.osccteams=ta;
        }
      });
    }
    else {
      // console.log('สร้างทีมใหม่');
      let teamCode=UAPI.generateTeamCode();
      // console.log(x);
      x.forEach(m => {
        let t = {};
        t.teamCode=teamCode;
        t.teamName=focusedTeam.teamName;
        t.memberTypeId=m.memberTypeId;
        t.memberTypeName=m.memberTypeName;
        delete m.memberTypeId;
        delete m.memberTypeName;
        let exists_osccteams=0;
        if (typeof m.osccteams !== 'undefined') {
          if (m.osccteams.length>0) {
            exists_osccteams=1;
          }
        }
        if (exists_osccteams===1) {
          let ta = m.osccteams;
          ta.push(t);
          m.osccteams=ta;
        }
        else {
          let ta=[];
          ta.push(t);
          m.osccteams=ta;
        }
      });
    }
    await x.forEach(m => {
      let p = JSON.parse(JSON.stringify(m));
      delete p['removed_item'];
      delete p['deleted'];
      // console.log(p);
      let r = patchTeamUser(p);
      m=r;
    });

  }

  const patchTeamUser = async (xxx) => {
    let mmm = JSON.parse(JSON.stringify(xxx));
    setOpenDialogTeam(false);
    setFocusedTeam({});
    setMemberList([]);

    let r= {};
    // console.log(mmm);
    let response = await UAPI.patch(mmm.id, mmm, 'teamusers', '3009');
    if (response.status === 200) {
      if (response.data) {
        r=response.data;
        getTeamUsers();
      }
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 500);
    return r;
  }

  const clickDeleteMember = (m) => {
    let x = JSON.parse(JSON.stringify(memberList));
    x.forEach(i => {
      if (i.id===m.id) {
        i.removed_item=true;
        if (typeof i.osccteams !== 'undefined') {
          let team_cleaned=[];
          i.osccteams.forEach(t => {
            if (t.teamCode!==focusedTeam.teamCode) {
              team_cleaned.push(t);
            }
          });
          i.osccteams=team_cleaned;
        }
      }
    });
    setMemberList(x);
  }

  const clickAddMember = () => {
    if (Object.keys(selectedMember).length>0 && Object.keys(selectedMemberType).length>0) {
      // console.log('IN----------',selectedMember);
      // console.log(memberList);
      let z=JSON.parse(JSON.stringify(selectedMember));
      delete z['removed_item'];
      // console.log(z);
      let y=0;
      memberList.forEach(i => {
        // console.log(i.id===z.id, i.id, z.id);
        if (i.id===z.id) {
          y=1;
        }
      });
      if (y===0) {
        let x = z;
        x['memberTypeId']=selectedMemberType.memberTypeId;
        x['memberTypeName']=selectedMemberType.memberTypeName;
        setMemberList([...memberList,...[x]]);  
      }
    }
  }

  const changeInputMember = (e) => {
    let userId = e.target.value;
    teamUsersData.forEach(i => {
      if (i.id === userId) {
        // console.log(i);
        setSelectedMember(i);
      }
    });
  }

  const changeInputMemberType = (e) => {
    // console.log(e.target.value);
    if (e.target.value !== '') {
      let n = '';
      switch (e.target.value) {
        case '1':
          n = 'หัวหน้าทีม';
          break;
        case '2':
          n = 'สมาชิก';
          break;
        default:
          break;
      }
      setSelectedMemberType({ memberTypeId: e.target.value, memberTypeName: n });
    }
  }

  const changeTeamData = (e) => {
    let x = focusedTeam;
    x[e.target.name]=e.target.value;
    setFocusedTeam({...focusedTeam,...x});
  }

  const confirmedDeleteTeam = async () => {
    setOpenConfirmDialog(false);
    setOpenBackdrop(true);
    let d=[];
    let tm = JSON.parse(JSON.stringify(teamUsersData));
    tm.forEach(m => {
      if (typeof m.osccteams !== 'undefined') {
        let t_removed=[];
        let m_affected=0;
        m.osccteams.forEach(t => {
          if (t.teamCode!==focusedTeam.teamCode) {
            t_removed.push(t);
          }
          if (t.teamCode===focusedTeam.teamCode) {
            m_affected=1;
          }
        });
        if (m_affected===1) {
          m.osccteams=t_removed;
          d.push(m);
        }
      }
    });
    // console.log(d);

    await d.forEach(m => {
      let p = JSON.parse(JSON.stringify(m));
      let r = patchTeamUser(p);
      m=r;
    });

    // setOpenDialogTeam(false);
    // setFocusedTeam({});
    // setMemberList([]);
    // setTeamList([]);
    // getTeamUsers();
    // setTimeout(() => {
    //   setOpenBackdrop(false);
    // }, 1000);
  }
  
  useEffect(() => {
    getTeamUsers();
  }, []); 

  return (
    <div style={{ marginBottom: 100 }}>

    <Backdrop className={classes.backdrop} open={openBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>

    <Grid container>

      <Grid item xs={12} sm={12}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <h4>
              จัดการทีม
            </h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container justify="flex-end">
              {/* <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={() => { history.goBack() }}>
                กลับ
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} style={{ border: "solid 0px #666666", borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF", marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12} sm={1}>
            &nbsp;&nbsp; ลำดับ
          </Grid>
          <Grid item xs={12} sm={9}>
            ชื่อทีม
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            สมาชิก
          </Grid> */}
          <Grid item xs={12} sm={2} style={{padding: 0}}>
            <Grid container justify="flex-end">
              <Button
                onClick={() => clickAddTeam()}
                variant="contained" color="primary"
                startIcon={ <BtMdAddCircle style={{ marginLeft: 11, marginTop: -2 }} /> }
                style={{ marginRight: 2, marginBottom: 2, marginTop: 2, height: 25, borderRadius: 20, minWidth: "90px", maxWidth: "90px", color: "white" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {mkTeamList()}

    </Grid>

    <Dialog fullWidth={true} maxWidth={"md"} open={openDialogTeam}>
      <DialogTitle>จัดการทีม</DialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-basic"
              onChange={changeTeamData}
              value={focusedTeam['teamName']||''}
              label="ชื่อทีม"
              variant="outlined"
              fullWidth={true}
              inputProps={{ name: "teamName", }}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={{ border: "solid 0px #666666", borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF", marginTop: 10 }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={1} style={{ padding: 4 }}> &nbsp;&nbsp; ลำดับ</Grid>
              <Grid item xs={12} sm={4} style={{ padding: 4 }}>ชื่อสมาชิก</Grid>
              <Grid item xs={12} sm={3} style={{ padding: 4 }}>หน่วยบริการ</Grid>
              <Grid item xs={12} sm={2} style={{ padding: 4 }}>ประเภท</Grid>
              <Grid item xs={12} sm={2}><br /></Grid>
            </Grid>
          </Grid>

          {mkMemberList()}

          <Grid item xs={12} sm={12} style={{ marginTop: 20, borderTop: "solid 1px #666666" }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={1} style={{ padding: 4 }}></Grid>

              <Grid item xs={12} sm={6} style={{ padding: 4 }}>
                <Select fullWidth={true} variant="outlined" native 
                  onChange={changeInputMember} 
                  inputProps={{ name: "questionName", id: "questionId", }} 
                >
                  <option value={''}>-เลือกสมาชิก-</option>
                  {teamUsersData.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.department.hos_fullname} : {u.fullname}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={3} style={{ padding: 4 }}>
                <Select fullWidth={true} variant="outlined" native inputProps={{ name: "teamName", id: "teamId", }} 
                onChange={changeInputMemberType} 
                >
                  <option value={''}>-เลือกประเภทสมาชิก-</option>
                  <option value={1}>หัวหน้าทีม</option>
                  <option value={2}>สมาชิก</option>
                </Select>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Grid container direction={isDesktop ? "row" : "column-reverse"} justify="flex-end">
                  <Button variant="contained" color="primary" startIcon={<BtMdAddCircle style={{ marginLeft: 0, marginTop: -2 }} />} style={{ marginRight: 10, marginTop: 5, color: "white", }} onClick={() => clickAddMember()} >
                    เพิ่มสมาชิก
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined"  color="primary" onClick={clickSaveTeam} >
          บันทึก
        </Button>
        <Button variant="outlined" onClick={clickCloseDialogTeam}>
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
    
    <Dialog open={openConfirmDialog}>
      <DialogContent>
        <DialogContentText component={'div'}>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogSet==='deleteTeam'&&(
          <Button variant="contained" color="primary" onClick={confirmedDeleteTeam}>
            ยืนยัน
          </Button>
        )}
        <Button variant="contained" autoFocus
          onClick={()=> setOpenConfirmDialog(false) }
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>

  </div>
  )
}
