/* eslint-disable react-hooks/exhaustive-deps */
import React,{ Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Box,  Button, Chip, createStyles, makeStyles, styled, Theme, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridToolbar, GridFilterModel, GridRowData } from "@material-ui/data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import CustomDialog from '../../components/custom_components/Dialog';
import Actions from "../../actions/activity";
import CaseStatusByRole from './helper/caseStatusByRole';
import ButtonsByRole from './helper/actionByRole';
import "moment/dist/locale/th";
import { SelectBox } from '../../types/activity';
// import ButtonLoading from '../../components/custom_components/ButtonLoading';
import InformConsent from './informConsent';
import Conclusion from './conclusionDialog';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DataGridMobile from './helper/datagridMobile';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tooltip: {
      fontSize: "1em",
      lineHeight: "1.5em",
      padding: "1rem"
    },
    tooltipBtn:{
      width: "100%",
      height: "100%",
      margin: "0 !important"
    },
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);

interface IProps {
  loading:boolean;
  groupData: Record<string, any>;
  filltered: Record<string, any>;
  getCases: (filter: Record<string, any>) => Promise<void>;
  currentPage: number;
  pageSize: number;
  handlePageChange: (page: number, details?: any) => void;
}

const StyledChip = styled(Chip)((props:any) => ({
  color: props.fontcolor,
  borderColor: props.bordercolor,
  backgroundColor: props.bgcolor,
}));

const CasesList:FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response.user;
  const role = userinfo.role;
  const caseStatus: Array<SelectBox> = CaseStatusByRole(role as string);
  const questionaireList = globalState.activity.questionaireList.data;
  const casesList = globalState.activity.caseList;
  const [showInformConsent, setShowInformConsent] = useState(false);
  const [caseData, setCaseData] = useState<GridRowData>({});
  const [conclusionData, setConclusionData] = useState<Record<string,any>>({});
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({items: [{ columnField: 'cid', value: '', operatorValue: 'equals' }]});
  const [locationState, setLocationState] = useState({});
  const [showConclusionDialog, setShowConclusionDialog] = useState(false);

  const emr = (cid:string) => {
    if (cid && cid!=="") {
      history.push({ pathname: '/caseemr', state: { cid : cid } });
    }
  };


  const makeQuestionnair=(data:GridRowData)=>{
    if(data.inform){ // ถ้าเคสหรือกลุ่มทำ inform แล้ว
      history.push({ 
        pathname: '/questionnaire_list', 
        state: {
          group: false, 
          data: data,
          groupData: props.groupData,
          filttered: props.filltered
        }
      });
    }else{ // ถ้าเคสหรือกลุ่ม ยังไม่ทำ inform
      setShowInformConsent(true);
    }
  }
  const allFunction = useCallback((params:any)=>{
    // console.log(params);
    return {
      emr: ()=>emr(params.cid),
      makeQuestionnairOrganization: ()=>{
        setLocationState({group:true,isOrganization:true,data:params});
        setCaseData(params);
        makeQuestionnair(params)
      },
      makeQuestionnairAssessor: ()=>{
        setLocationState({group:false,isAssessor:true,data:params});
        setCaseData(params);
        makeQuestionnair(params)
      }, 
      makeQuestionnairCoach: ()=>{
        setLocationState({group:false,isCoach:true,data:params});
        setCaseData(params);
        makeQuestionnair(params)
      }, 
      makeQuestionnairCM: ()=>{
        setCaseData(params);
        setLocationState({group:false, data:params});
        makeQuestionnair(params);
      },
      makeQuestionnairTeams: ()=>{
        setCaseData(params);
        setLocationState({group:false, data:params});
        makeQuestionnair(params)
      },
      conclusion: ()=>{
        if(params.conclusion){setConclusionData(params.conclusion[0])}
        setCaseData(params);
        setShowConclusionDialog(true);
      }
    }
  },[]);
  const [mobileData, setMobileData] = useState([]);
  const genDataForMobile = useCallback(()=>{
    if(casesList.data.length>0){
      // console.log("genDataForMobile",casesList.data);
      const riskLevel = [
        {id:1, label:'ต่ำ',value:'low'},
        {id:2, label:'ปานกลาง',value:'medium'},
        {id:3, label:'สูง',value:'high'},
        {id:4, label:'เหยื่อ',value:'victim'},
        {id:5, label:'ปกติ',value:'normal'},
        {id:6, label:'อื่นๆ',value:'other'}
      ];
      const newData: any = casesList.data.map((item:any)=>{
        return {
          name: item.fname+" "+item.lname,
          status: item.caseStatus,
          data: [
            { 
              label: "เลขบัตร ปชช.",
              value: item.cid
            },
            { 
              label: "เพศ",
              value: item.sex
            },
            { 
              label: "อายุ",
              value: item.ageYM
            },
            { 
              label: "ความรุนแรง",
              value: riskLevel.filter((f:any)=>f.value===item.riskLevel).map((m:any)=>m.label)[0]
            }
          ],
          allFunction: allFunction(item),
          manage:[]
        };
      });
      // console.log(newData);
      if(newData.length>0){setMobileData(newData)}
    }
  },[casesList.data]);
  const [startEffect,setStartEffect]=useState(true);
  useEffect(() => { // ถ้า refresh หน้าเว็บ แล้วไม่มีข้อมูลแบบสอบถาม ให้ไป get api มาใหม่
    // console.log(questionaireList);
    if(startEffect){
      if (questionaireList.length === 0) {
        const param = {
          where:{
            questionnaire_type: {neq: "PLH1"},
            questionnaire_group: false
          }
        }
        // console.log("GetQuestionnaire");
        dispatch(Actions.GetQuestionnaire.request(param));
      }
    }
    return ()=>{setStartEffect(false);}
  },[dispatch, questionaireList]);

  useDeepCompareEffect(() => { // ถ้าข้อมูลเคส มีการเปลี่ยนแปลงให้ปิด Dialog
    // setShowModalAssign(false);
    // setShowModalCancelAssign(false);
    // setShowModalSendCM(false);
    setShowConclusionDialog(false);
    if(globalState.activity.caseList.data.length>0){
      setShowInformConsent(false);
      genDataForMobile();
    }
  },[globalState.activity.caseList.data, globalState.activity.groupList.data]);
  
  const columns: GridColDef[] = [
    { 
      field: 'cid', 
      headerName: 'เลชบัตรประชาชน', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12,
      // renderCell:(params: GridCellParams)=> {
      //   return params.row.cid.replace(params.row.cid.substring(3,10),"xxxxxxx");
      // }
    },
    { 
      field: 'fname', 
      headerName: 'ชื่อ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'lname', 
      headerName: 'สกุล', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'sex', 
      headerName: 'เพศ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'ageYM', 
      headerName: 'อายุ (ปี)', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'riskLevel', 
      headerName: 'ระดับความรุนแรง', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1
    },
    { 
      field: 'status',
      headerName: 'สถานะ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07,
      renderCell:(params: GridCellParams)=> {
        if(caseStatus && caseStatus.length>0){
          let returnChip;
          caseStatus.forEach((item:any,key:number)=>{
            if(params.row.caseStatus===item.id){
              returnChip = <StyledChip
                key={key}
                label={item.label}
                variant={(item.variant)?item.variant:"default"}
                fontcolor={(item.fontColor)?item.fontColor:"black"}
                bordercolor={(item.variant==="outlined")?item.fontColor:"black"}
                bgcolor={(item.bgcolor)?item.bgcolor:"primary"}
                />;
            }
          });
          return returnChip;
        }else{
          return params.row.caseStatus;
        }
      }
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        const f= allFunction(params.row);
        return (
          <ButtonsByRole role={role||"" as string} manage={[]} caseStatus={params.row.caseStatus} handleClick={f}/>
        )
      }
    }
  ];

  return (
    <Box style={{marginTop:3,width: '100%'}}>
      {
        (isMobile) ?
          <DataGridMobile 
            loading={props.loading} 
            data={(props.loading)?[]:mobileData} 
            setShowInformConsent={setShowInformConsent} 
            setShowConclusionDialog={setShowConclusionDialog}
            page=""
          /> 
          : 
          <DataGrid
            rows={(props.loading)?[]:casesList.data}
            className={classes.dataGrid}
            columns={columns}
            loading={props.loading}
            autoHeight
            pagination
            paginationMode="server"
            page={props.currentPage}
            pageSize={props.pageSize}
            rowsPerPageOptions={[props.pageSize]}
            rowCount={globalState.activity.caseList.count}
            onPageChange={(page:number)=>props.handlePageChange(page)}
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            disableDensitySelector
            filterMode='client'
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
          />
      }
      {/* <CustomDialog 
        content={<Box style={{}}>คุณแน่ใจว่าต้องการมอบหมายเคสรายนี้</Box>}
        state={{open:showModalAssign, setOpen:setShowModalAssign}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>assignCase("assigned")}
                    loading={loading}>
                    ยืนยันมอบหมาย
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalAssign(false)}>ปิด</Button>
        }}
        /> */}
      {/* <CustomDialog 
        content={<Box style={{color:"red"}}>คุณแน่ใจว่าต้องการยกเลิกการมอบหมายเคสรายนี้</Box>}
        state={{open:showModalCancelAssign, setOpen:setShowModalCancelAssign}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>assignCase("new")} 
                    loading={loading}>
                    ยืนยันการยกเลิก
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalCancelAssign(false)}>ปิด</Button>
        }}
        /> */}
      {/* <CustomDialog 
        content={<Box>คุณแน่ใจว่าต้องการส่งเคสให้ CM สรุปผล</Box>}
        state={{open:showModalSendCM, setOpen:setShowModalSendCM}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>updateCaseStatus("waitConclusion")} 
                    loading={loading}>
                    ยืนยันการส่ง CM
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalSendCM(false)}>ปิด</Button>
        }}
        /> */}
      {(showInformConsent)&&<InformConsent 
        setOpen={setShowInformConsent} 
        caseData={caseData}
        filltered={props.filltered}
        state={locationState}
      />}
      {
        (showConclusionDialog && !_.isEmpty(caseData))&&
          <Conclusion 
            setOpen={setShowConclusionDialog} 
            caseData={caseData}
            conclusion={conclusionData}
            filltered={props.filltered}
            currentPage={props.currentPage}
            pageSize={props.pageSize}
            getCases={props.getCases}
          />
      }
    </Box>
  );
}

export default CasesList;