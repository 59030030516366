/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { API_URL, PORT, SYSTEM_TOKEN } from '../../services/auth-header';
import { sendEmail } from '../../services/auth.service';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
// import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import logo from '../../images/password-edit4.png';
import validation from "../../services/validation";
import { useDialog } from '../../services/dialog/ModalProvider';
// import useGlobal from "../../store";
const useStyles = makeStyles(theme => ({
  root: {
    '& ._RU-K2': {
      margin: '0',
      width: '100%',
      backgroundSize: 'contain'
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    }
  }
}));
const ResetPassword = (props) => {
  // const [globalState, globalActions] = useGlobal();
  const { tokenParam } = useParams();
  const { setDialog } = useDialog();
  const classes = useStyles();
  const form = useRef();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [validator, setValidator] = useState({
    formElements: {
      email: {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: 'กรุณากรอกอีเมลล์ที่คุณลงทะเบียนไว้' }
      }
    },
    formValid: false
  });
  const baseUrl = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_BASENAME_DEV : process.env.REACT_APP_BASENAME_PROD;

  const onHandleChange = (e) => {
    let result = validation(validator, e);
    if (typeof result != 'undefined') {
      const name = (typeof e.target !== 'undefined') ? e.target.name : e.key;
      const value = (typeof e.target !== 'undefined') ? e.target.value : e.val;
      switch (name) {
        case 'email': setEmail(value); break;
        default: break;
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  };

  const handleSubmit = async (e, res) => {
    e.preventDefault();
    setLoading(true);
    onHandleChange({ key: "email", val: email });

    if (validator.formValid) {
      try {
        const url = API_URL + ":" + PORT + "/api/teamusers";
        const params = {
          headers: { Authorization: SYSTEM_TOKEN },
          params: {
            filter: {
              fields: { id: true, lineRegister: true },
              where: { email: email }
            }
          }
        };
        let userInfo = { id: "", email: email, token: "", link: "", lineRegister: false }

        // Get User Id
        await axios.get(url, params).then((response) => {
          if (response.status === 200) {
            userInfo.id = response.data[0].id;
            if (typeof response.data[0].lineRegister !== 'undefined') {
              userInfo.lineRegister = response.data[0].lineRegister;
            }
            userInfo.token = uuidv5("ResetPassword" + userInfo.id + "@", uuidv4());
          } else {
            setDialog({
              title: '',
              titleStyle: {},
              content: 'ไม่พบอีเมลล์นี้ในระบบ',
              contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
              button: {
                confirm: { enable: false, text: '', redirect: '' },
                cancel: { enable: true, text: 'ปิด' },
              }
            });
          }
        }).catch(function (error) {
          if (error.response) {
            res = { status: error.response.status, message: error.response.data.error.message }
          }
        });
        if (!userInfo.lineRegister) {
          // Gen Temporary Token
          let urlGenToken = API_URL + ":" + PORT + "/api/teamusers/" + userInfo.id + "/accessTokens?access_token=" + SYSTEM_TOKEN;
          let headers = { headers: { Authorization: SYSTEM_TOKEN } };
          let datas = {
            id: userInfo.token,
            ttl: 300, // 5 Minute
            userId: userInfo.id
          };
          await axios.post(urlGenToken, datas, headers).then((response) => {
            if (response.status === 200) {
              userInfo.link = baseUrl + "/resetpassword/" + userInfo.id + "/" + response.data.id;
              // console.log(userInfo.link);
              // redirect.push({ pathname: "/resetpassword", token:response.data.id });
            }
          }).catch(function (error) {
            if (error.response) {
              res = { status: error.response.status, message: error.response.data.error.message }
            }
          });

          // Send Email
          let params = {
            to: email,
            subject: 'ChildShield : กำหนดรหัสผ่านใหม่',
            text: '',
            html: '<span style="font-weight:bold;">ChildShield</span><br>ระบบสารสนเทศเพื่อการเฝ้าระวัง ติดตาม และป้องกันเด็กเสี่ยงที่ถูกกระทำรุนแรง<hr><br>คุณได้ร้องขอการกำหนดรหัสผ่านใหม่ กรุณาคลิกลิงค์ด้านล่างเพื่อดำเนินการต่อ<br><br><a href="' + userInfo.link + '" target="_blank">กำหนดรหัสผ่านใหม่</a>'
          };
          let sendEmailResult = await sendEmail(params);
          if (sendEmailResult.status === 200) {
            setDialog({
              title: '',
              titleStyle: {},
              content: {
                line1: 'ระบบได้ส่งลิงค์การกำหนดรหัสผ่าน',
                line2: 'ให้คุณทางอีเมลล์เรียบร้อยแล้ว',
                line3: 'กรุณาตรวจสอบอีเมลล์และทำตามคำแนะนำ'
              },
              contentStyle: { width: '400px', textAlign: 'center', color: 'green' },
              button: {
                confirm: { enable: false, text: '', redirect: '' },
                cancel: { enable: true, text: 'ปิด' },
              }
            });
            setLoading(false);
            setEmail("");
          }
        } else {
          setDialog({
            title: '',
            titleStyle: {},
            content: 'อีเมลล์นี้ถูกลงทะเบียนโดยระบบไลน์ ไม่สามารถเปลี่ยนรหัสผ่านได้',
            contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
            button: {
              confirm: { enable: false, text: '', redirect: '' },
              cancel: { enable: true, text: 'ปิด' },
            }
          });
          setLoading(false);
        }

      } catch (err) {
        console.log("เปลี่ยนรหัสผ่านไม่สำเร็จ", err);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img src={logo} alt="profile-img" className="password-change-logo" />
        <Form className={classes.root} onSubmit={handleSubmit} ref={form}>
          <div className="form-group" style={{ marginTop: '20px', color: '#3e3e3e' }}>
            <span>คุณจะได้รับลิงค์การตั้งค่ารหัสผ่านใหม่ทางอีเมลล์ของคุณ</span>
          </div>
          <div className="form-group" style={{ marginTop: '20px' }}>
            <TextField
              id="email"
              name="email"
              label="อีเมลล์ที่คุณลงทะเบียนไว้"
              type="text"
              size="small"
              variant="outlined"
              value={email}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.email.error.status}
              helperText={
                (validator.formElements.email.error.status) && validator.formElements.email.error.message
              }
            />
          </div>
          <div className="form-group" style={{ marginTop: '0px' }}>
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '5px' }}></span>
              )}
              <span>ส่งลิงค์ตั้งค่ารหัสผ่านใหม่</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
