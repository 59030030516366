/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import "moment/locale/th";
import { useTranslation } from "react-i18next";
import * as UAPI from "../../services/UniversalAPI";
import CaseFilter from "../../services/caseFilter";

// DATATABLE + EXCEL ของ DevExtreme
// import DataGrid, { Column, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// import { exportDataGrid } from 'devextreme/excel_exporter';
import './dx.light.css';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  }
}));

export default function Report(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [disabledExecuteReportButton, setDisabledExecuteReportButton] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [XLSX, setXLSX] = useState({
    filename: 'PLH_SUMMARY',
    sheetname: 'SHEET1',
    header1: 'ข้อมูล Summary ของเด็ก(กลุ่มเสี่ยง)',
    header2: '',
    footer1: '',
    footer2: ''
  });

  const executeReport = async () => {
    setOpenBackdrop(true);
    const addressName = (x, d) => {
      let r = { changwatname: '', ampurname: '', tambonname: '' };
      if (typeof x !== 'undefined') {
        if (typeof d !== 'undefined') {
          if (d) {
            if (d.length > 0) {
              d.forEach(i => {
                if (i.addressid.toString() === x.toString()) {
                  let z = i.full_name;
                  let zz = z.split(' ');
                  r = { changwatname: zz[2].replace('จ.', ''), ampurname: zz[1].replace('อ.', ''), tambonname: zz[0].replace('ต.', '') };
                }
              });
            }
          }
        }
      }
      return r;
    }

    let villagecode = '';
    let moopart = null;
    let tmbpart = null;
    let amppart = null;
    let chwpart = null;
    let tambolData = [];
    if (typeof filterData.changwat !== 'undefined') {
      if (filterData.changwat) {
        if (typeof filterData.changwat.changwatcode !== 'undefined') {
          if (filterData.changwat.changwatcode && filterData.changwat.changwatcode !== '') {
            villagecode = filterData.changwat.changwatcode;
            chwpart = villagecode.substr(0, 2);
            if (typeof filterData.ampur !== 'undefined') {
              if (filterData.ampur) {
                if (typeof filterData.ampur.amphur_id !== 'undefined') {
                  villagecode = filterData.ampur.amphur_id;
                  amppart = villagecode.substr(2, 2);
                  if (typeof filterData.tambon !== 'undefined') {
                    if (filterData.tambon) {
                      if (typeof filterData.tambon.addressid !== 'undefined') {
                        villagecode = filterData.tambon.addressid;
                        tmbpart = villagecode.substr(4, 2);
                        if (typeof filterData.moo !== 'undefined') {
                          if (filterData.moo) {
                            if (typeof filterData.moo.villagecode !== 'undefined') {
                              villagecode = filterData.moo.villagecode;
                              moopart = villagecode.substr(6, 2);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    let opData = [];
    if (chwpart) {
      let filter_osccperson = {
        filter: {
          where: {
            villagecode: { like: villagecode + '.*' }
          },
          include: {
            relation: 'planservice',
            scope: {
              include: {
                relation: 'person',
                scope: {
                  include: {
                    relation: 'intervention',
                    scope: {
                      where: {
                        "activities.homeservice.questionnaire.questionnaire_code": { gt: "" }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      // console.log(JSON.stringify(filter_osccperson.filter));
      let responseOP = await UAPI.getAll(filter_osccperson, 'osccpeople', null);
      if (responseOP.status === 200) {
        if (responseOP.data) {
          if (responseOP.data.length > 0) {
            // console.log(responseOP.data);
            opData = responseOP.data;
          }
        }
      }
    }
    // console.log(opData);

    let chwpartList = [];
    opData.forEach(i => {
      if (typeof i.villagecode !== 'undefined') {
        if (i.villagecode) {
          if (i.villagecode.substr(0,2) !== '') {
            if (chwpartList.indexOf(i.villagecode.substr(0,2)) < 0) {
              chwpartList.push(i.villagecode.substr(0,2));
            }
          }
        }
      }
    });
    // console.log(chwpartList);

    if (chwpartList.length > 0) {
      let filter_chwpart_or = [];
      chwpartList.forEach(cw => {
        filter_chwpart_or.push({ chwpart: cw });
      });
      let filter_chwpart = {
        filter: {
          where: {
            or: filter_chwpart_or
          }
        }
      };
      // console.log(JSON.stringify(filter_chwpart.filter));
      let responseT = await UAPI.getAll(filter_chwpart, 'tambols', null);
      if (responseT.status === 200) {
        if (responseT.data) {
          if (responseT.data.length > 0) {
            tambolData = responseT.data;
          }
        }
      }
    }
    // console.log(tambolData);

    let planData = [];
    opData.forEach(i1 => {
      if (typeof i1.planservice !== 'undefined') {
        if (i1.planservice) {
          if (i1.planservice.length > 0) {
            i1.planservice.forEach(i2 => {
              if (i2) {
                if (Object.keys(i2).length > 0) {
                  let thisCase = {};
                  let addressInfo = addressName(i1.villagecode.substr(0, 6), tambolData);
                  thisCase.cid = i1.cid;
                  thisCase.fname = i1.fname;
                  thisCase.lname = i1.lname;
                  thisCase.changwatcode = i1.villagecode.substr(0, 2);
                  thisCase.changwatname = addressInfo.changwatname;
                  thisCase.ampurcode = i1.villagecode.substr(2, 2);
                  thisCase.ampurname = addressInfo.ampurname;
                  thisCase.tamboncode = i1.villagecode.substr(4, 2);
                  thisCase.tambonname = addressInfo.tambonname;
                  thisCase.moocode = i1.villagecode.substr(6, 2);
                  thisCase.planserviceId = i2.id;
                  thisCase.planserviceCause = i2.cause;
                  let careGiverFullName = '';
                  if (typeof i1.careGiver !== 'undefined') {
                    if (i1.careGiver) {
                      if (typeof i1.careGiver.fname !== 'undefined') {
                        careGiverFullName = i1.careGiver.fname;
                      }
                      if (typeof i1.careGiver.lname !== 'undefined') {
                        careGiverFullName = careGiverFullName+' '+i1.careGiver.lname;
                      }
                    }
                  }
                  thisCase.careGiverFullName=careGiverFullName;
                  planData.push(thisCase);
                }
              }
            });
          }
        }
      }
    });

    planData.forEach(i1 => {
      let thisPlanQN = [];
      if (typeof i1.planserviceId !== 'undefined') {
        if (i1.planserviceId) {
          if (i1.planserviceId !== '') {
            opData.forEach(i2 => {
              if (typeof i2.planservice !== 'undefined') {
                if (i2.planservice) {
                  if (i2.planservice.length>0) {
                    i2.planservice.forEach(i3 => {
                      if (typeof i3.person !== 'undefined') {
                        if (i3.person) {
                          if (typeof i3.person.intervention !== 'undefined') {
                            if (i3.person.intervention) {
                              if (i3.person.intervention.length>0) {
                                let itvt = i3.person.intervention;
                                itvt.forEach(i4 => {
                                  if (typeof i4.activities !== 'undefined') {
                                    if (i4.activities) {
                                      if (typeof i4.activities.homeservice !== 'undefined') {
                                        if (i4.activities.homeservice) {
                                          if (i4.activities.homeservice.length>0) {
                                            let hs = i4.activities.homeservice;
                                            hs.forEach(i5 => {
                                              if (i5) {
                                                if (typeof i5.serviceType !== 'undefined') {
                                                  if (i5.serviceType) {
                                                    if (i5.serviceType.toLowerCase()==='oscc') {
                                                      if (typeof i5.questionnaire !== 'undefined') {
                                                        if (i5.questionnaire) {
                                                          if (i5.questionnaire.length>0) {
                                                            let qn = i5.questionnaire;
                                                            qn.forEach(i6 => {
                                                              if (i6) {
                                                                if (typeof i6.planServiceId !== 'undefined') {
                                                                  if (i6.planServiceId) {
                                                                    if (i6.planServiceId !== '') {
                                                                      if (i6.planServiceId === i1.planserviceId) {
                                                                        let thisQN = {};
                                                                        thisQN.sequenceId = i6.sequenceId;
                                                                        thisQN.questionnaire_code = i6.questionnaire_code;
                                                                        thisQN.date = i6.date;
                                                                        thisQN.analysisVersion = i6.analysisVersion;
                                                                        let consent = 'notConsent';
                                                                        if (typeof i6.consent !== 'undefined') {
                                                                          if (i6.consent) {
                                                                            if (typeof i6.consent.result !== 'undefined') {
                                                                              if (i6.consent.result === 'consented') {
                                                                                consent = 'consented';
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        thisQN.consent = consent;
                                                                        let teamName = '';
                                                                        if (typeof i6.osccteam !== 'undefined') {
                                                                          if (i6.osccteam) {
                                                                            if (typeof i6.osccteam.teamName !== 'undefined') {
                                                                              if (i6.osccteam.teamName) {
                                                                                if (i6.osccteam.teamName!=='') {
                                                                                  teamName = i6.osccteam.teamName;
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        thisQN.teamName = teamName;
                                                                        thisPlanQN.push(thisQN);
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            });
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            });
                                          }
                                        }
                                      }
                                    }
                                  }
                                });
                              }
                            }
                          }
                        }
                      }
                    });
                  }
                }
              }
            });
          }
        }
      }
      i1.questionnaire=thisPlanQN;
    });
    // console.log(planData);

    let PLHlist = [];
    let f = {
      filter : {
        where: {
          key: "bluePrintServiceSlotOSCCPLH8wks1st"
        }
      }
    };
    let responseS = await UAPI.getAll(f, 'systemsettings', null);
    if (responseS.status === 200) {
      if (responseS.data) {
        if (responseS.data.length > 0) {
          PLHlist = responseS.data[0].value.serviceslotList;
        }
      }
    }
    // console.log(PLHlist);

    planData.forEach(i1 => {
      if (typeof i1.questionnaire !== 'undefined') {
        let casePLH = [];
        let teamList = [];
        PLHlist.forEach(i2 => {
          let thisPLH = { sequenceId: i2.sequenceId, questionnaire_code: i2.questionnaire_code, week: i2.week, date: '' };
          if (i1.questionnaire) {
            if (i1.questionnaire.length>0) {
              i1.questionnaire.forEach(i3 => {
                if (i2.sequenceId===i3.sequenceId) {
                  thisPLH.date = i3.date;
                  if (i3.consent==='notConsent') {
                    thisPLH.date = 'Not Consent';
                  }
                }
                if (teamList.indexOf(i3.teamName)<0) {
                  teamList.push(i3.teamName);
                }
              });
            }
            casePLH.push(thisPLH);
          }
        });
        i1.teamList=teamList.join(',');
        i1.plh=casePLH;
      }

    });
    // console.log(planData);

    setTableData(planData);
  }


  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(XLSX.sheetname);

    // exportDataGrid({
    //   component: e.component,
    //   worksheet,
    //   topLeftCell: { row: 4, column: 1 },
    // }).then((cellRange) => {
    //   // header
    //   const headerRow = worksheet.getRow(1);
    //   // headerRow.height = 30;
    //   // worksheet.mergeCells(2, 1, 2, 8);
    //   headerRow.getCell(1).value = XLSX.header1;
    //   // headerRow.getCell(1).font = { name: 'TH SarabunPSK', size: 16 };
    //   headerRow.getCell(1).font = { name: 'Tahoma', size: 14 };
    //   headerRow.getCell(1).alignment = { horizontal: 'left' };

    //   if (XLSX.header2 !== '') {
    //     const headerRow2 = worksheet.getRow(2);
    //     // headerRow2.height = 30;
    //     headerRow2.getCell(1).value = XLSX.header2;
    //     // headerRow2.getCell(1).font = { name: 'TH SarabunPSK', size: 16 };
    //     headerRow2.getCell(1).font = { name: 'Tahoma', size: 14 };
    //     headerRow2.getCell(1).alignment = { horizontal: 'left' };
    //   }

    //   // footer
    //   // const footerRowIndex = cellRange.to.row + 2;
    //   // const footerRow = worksheet.getRow(footerRowIndex);
    //   // worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);
    //   // footerRow.getCell(1).value = 'www.wikipedia.org';
    //   // footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
    //   // footerRow.getCell(1).alignment = { horizontal: 'right' };
    // }).then(() => {
    //   let date = new Date();
    //   var sd = date.getFullYear() + ('00' + (date.getMonth() + 1)).slice(-2) + ('00' + date.getDate()).slice(-2);
    //   workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), XLSX.filename +'_'+sd+ '.xlsx');
    //   });
    // });
    e.cancel = true;
  }

  const onContentReady = () => {
    setTimeout(() => {
      setOpenBackdrop(false);      
    }, 1000);
  }

  const ReportTable = useMemo(() => {
    let data = [];
    let columnList = [];
    let rowList = [];

    if (tableData.length > 0) {
      data = tableData;
      // สร้าง HEADER
      columnList.push(
        <Column key={UAPI.genKey()} dataField='order_no' caption='ลำดับ' minWidth={50} />
      );
      columnList.push(
        <Column key={UAPI.genKey()} caption='ข้อมูลกลุ่มเสี่ยง'>
          <Column dataField='case_Fname' caption='ชื่อ' minWidth={100} />
          <Column dataField='case_Lname' caption='สกุล' minWidth={100} />
          <Column dataField='case_changwat' caption='จังหวัด' minWidth={100} />
          <Column dataField='case_ampur' caption='อำเภอ' minWidth={100} />
          <Column dataField='case_tambon' caption='ตำบล' minWidth={100} />
          <Column dataField='case_moo' caption='หมู่' minWidth={50} />
        </Column>
      );
      columnList.push(
        <Column key={UAPI.genKey()} dataField='careGiver_fullName' caption='ผู้ดูแล' minWidth={100} />
      );
      columnList.push(
        <Column key={UAPI.genKey()} dataField='plan_name' caption='แผนการดูแล' minWidth={200} />
      );
      columnList.push(
        <Column key={UAPI.genKey()} dataField='team_teamName' caption='ทีมเจ้าหน้าที่' minWidth={150} />
      );
      let weekList = [];
      data[0].plh.forEach(i => {
        if (typeof i.questionnaire_code !== 'undefined') {
          if (typeof i.week !== 'undefined') {
            if (weekList.indexOf(i.week) < 0) {
              weekList.push(i.week);
            }
          }
        }
      });

      let weekColumn = [];
      weekList.forEach(w => {
        weekColumn.push({ week: w, column: [] });
      });

      data[0].plh.forEach(i => {
        if (typeof i.questionnaire_code !== 'undefined') {
          weekColumn.forEach(w => {
            if (parseInt(w.week) === parseInt(i.week)) {
              w.column.push(i);
            }
          });
        }
      });
      // console.log(weekColumn);

      let colNum = 0;
      weekColumn.forEach(w => {
        if (typeof w.week !== 'undefined') {
          if (w.week >= 0) {
            if (typeof w.column !== 'undefined') {
              if (w.column) {
                if (w.column.length > 0) {
                  let subColumn = [];
                  w.column.forEach(c => {
                    ++colNum;
                    subColumn.push(
                      <Column
                        key={UAPI.genKey()}
                        dataField={colNum.toString()}
                        caption={c.questionnaire_code}
                      />
                    );
                  });
                  columnList.push(
                    <Column key={UAPI.genKey()} caption={'สัปดาห์ที่ ' + w.week.toString()}>
                      {subColumn}
                    </Column>
                  );
                }
              }
            }
          }
        }
      });
      // สร้าง HEADER - จบ

      // สร้าง ROW
      let keyID = 0;
      data.forEach(i1 => {
        let thisRow = {};
        ++keyID;
        thisRow.ID = keyID;
        thisRow['order_no'] = keyID.toString();
        thisRow['case_Fname'] = i1.fname;
        thisRow['case_Lname'] = i1.lname;
        thisRow['case_changwat'] = i1.changwatname;
        thisRow['case_ampur'] = i1.ampurname;
        thisRow['case_tambon'] = i1.tambonname;
        thisRow['case_moo'] = parseInt(i1.moocode).toString();
        thisRow['careGiver_fullName'] = i1.careGiverFullName;
        thisRow['team_teamName'] = i1.teamList;
        thisRow['record_date'] = i1.date;
        thisRow['record_userFullName'] = i1.userFullName;
        thisRow['plan_name'] = i1.planserviceCause;
        
        let colNum = 0;
        if (typeof i1.plh !== 'undefined') {
          i1.plh.forEach(i2 => {
            if (typeof i2.questionnaire_code !== 'undefined') {
              ++colNum;
              let cellData = '';
              if (typeof i2.date !== 'undefined') {
                cellData = i2.date;
              }
              thisRow[colNum.toString()] = cellData;
            }
          });
        }
        rowList.push(thisRow);
      });
      // สร้าง ROW - จบ
      // console.log(rowList);

      return (<></>
        // <DataGrid
        //   dataSource={rowList}
        //   keyExpr="ID"
        //   showBorders={true}
        //   onExporting={onExporting}
        //   onContentReady={onContentReady}
        //   columnMinWidth={100}
        // >
        //   <Export enabled={true} />
        //   {columnList}
        // </DataGrid>
      );
    }
    else {
      setTimeout(() => {
        setOpenBackdrop(false);        
      }, 1000);
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          ไม่พบข้อมูล
        </div>
      );
    }
    // eslint-disable-next-line 
  },[tableData]);


  useEffect(() => {
    let hasAreaCODE = false;
    if (Object.keys(filterData).length > 0) {
      if (typeof filterData.changwat !== 'undefined') {
        if (filterData.changwat) {
          if (typeof filterData.changwat.changwatcode !== 'undefined') {
            hasAreaCODE = true;
          }
        }
      }
    }
    if (hasAreaCODE) {
      setDisabledExecuteReportButton(false);
    }
    else {
      setDisabledExecuteReportButton(true);
    }
    setTableData([]);
    // eslint-disable-next-line 
  }, [filterData]); 

  return (
    <div style={{ marginBottom: 100 }}>

      <Dialog disableBackdropClick={true} className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>

      <Grid container style={{ marginTop: -10, marginBottom: 0 }} justify="center">
        <Grid item xs={12} sm={12}>
          <h4>รายงาน : ข้อมูล Summary ของเด็ก(กลุ่มเสี่ยง)</h4>
        </Grid>
        <Grid item xs={12} sm={10} style={{ padding: 0, margin: 0, marginTop: 5 }}>
          <CaseFilter setFilterData={setFilterData} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ padding: 0, margin: 0, marginTop: 5 }}>
          <Button color="primary" variant="contained" fullWidth
            style={{ marginTop: 5 }}
            onClick={() => executeReport()}
            disabled={disabledExecuteReportButton}
          >
            ประมวลผล
          </Button>
        </Grid>

      </Grid>

      <div style={{ padding: 5, border: 'solid 1px #B5B5B5', borderRadius: 4, marginTop: 10, background: '#FFFFFF' }}>
        {ReportTable}
      </div>

    </div>
  );
}