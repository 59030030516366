import { INSERT_OSCCPERSON } from "../../actions";
import { AnyAction } from "redux";
import { InsertOsccperson } from "../../types/activity";

const initialState:InsertOsccperson = {
  isFetching: false,
  data: []
};

export default function insertOsccperson(
  state = initialState,
  action: AnyAction
): InsertOsccperson {
  switch (action.type) {
    case INSERT_OSCCPERSON.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case INSERT_OSCCPERSON.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case INSERT_OSCCPERSON.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
