/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useCallback } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../services/auth.service";
import useGlobal from "../../store";

const Logout = () => {
  const [globalState, globalActions] = useGlobal();
  const redirect = useHistory();
  useEffect(() => {
    const handleLogout = async () => {
      try {
        logout();
        globalActions.resetGlobalState();
        localStorage.removeItem("OSCC");
        redirect.push("/login");
      } catch (error) {
        console.log('Logout failed:', error);
      }
    };
    if(globalState.loginStatus===true){
      handleLogout();
    }
  }, [globalActions, globalState.loginStatus, redirect]);

  return <></>;
};
export default Logout;