/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_HOSPITALINFO } from "../index";

const getHospitalInfo = {
  request: (payload: any) => action(GET_HOSPITALINFO.REQUEST, payload),
  success: (response: any) => action(GET_HOSPITALINFO.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_HOSPITALINFO.FAILURE, { error }),
};

export default getHospitalInfo;