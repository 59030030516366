/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { DELETE_INTERVENTION } from "../index";

const deleteIntervention = {
  request: (payload:any) => action(DELETE_INTERVENTION.REQUEST, payload),
  success: (response: any) => action(DELETE_INTERVENTION.SUCCESS, { data: response }),
  failure: (error: any) => action(DELETE_INTERVENTION.FAILURE, { error }),
};

export default deleteIntervention;