/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect,useState,useCallback, useRef, MutableRefObject} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/teams';
import { Button, Divider, Drawer, Grid,Icon,TextField,Typography } from "@material-ui/core";
import {  useHistory, useLocation } from 'react-router-dom';
import ServiceSlotList from './serviceslot_list';
import ServiceSlotManage from './dialog';

interface IProps {}
type TProps = IProps;

const ServiceSlot=()=>{
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
	const [dialogOpen, setDialogOpen]=useState(false);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tambon, setTambon] = useState("");
  const [filltered, setFilltered] = useState<Record<string,any>>({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >จัดการงาน (Service Slot)</Typography>
        <Typography variant="h6" style={{marginBottom:"1.5rem"}}>เป็นการมอบหมายงานให้แก่ผู้ใช้งาน ซึ่งผู้ใช้งานอาจได้รับงานในหลายรุ่น แต่ละรุ่นอาจได้รับสิทธิ์หลายประเภท</Typography>
        {/* <Divider /> */}
      </Grid>
      
      <Grid item xs={12}>
        <Grid container spacing={3} style={{display:'flex',justifyContent:'space-between'}}>
          <Grid item xs={4}>
            <TextField
              id="name"
              label="ค้นหา"
              type="text"
              size='small'
              variant='outlined'
              value={searchText}
              fullWidth
              onChange={(e)=>{
                setSearchText(e.target.value);
              }}
              style={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button 
              fullWidth 
              size="medium" 
              variant="contained" 
              color="primary" 
              style={{ height:"40px", margin: 0  }}
              onClick={()=>setDialogOpen(true)}
              >
              มอบหมายงาน
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ServiceSlotList />
      </Grid>
			{(dialogOpen)&&<ServiceSlotManage title="สร้างงาน" open={dialogOpen} setOpen={setDialogOpen}/>}
    </Grid>
  );
}

export default ServiceSlot;