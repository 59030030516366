/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";
import * as AST from "../../services/AutoSetting";
import { useTranslation } from "react-i18next";
import DvrIcon from '@material-ui/icons/Dvr';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  }
}));

export default function Report(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');

  const [openDialogMS, setOpenDialogMS] = useState(false);
  const [dialogTextMS, setDialogTextMS] = useState('');

  // const [disabledButtonUpdateSS, setDisabledButtonUpdateSS] = useState(true);

  const confirmedUpdateSS = async () => {
    setOpenBackdrop(true);
    let runFromSystemSetting = true;
    let x = await AST.AutoSetting(getCurrentUser(), null, runFromSystemSetting); 
    // console.log(x); 
    if (x === true) {
      setOpenDialog(false);
      setDialogTextMS('อัพเดตเสร็จแล้ว');
      setOpenDialogMS(true);
    }
    setTimeout(() => {
      setOpenDialog(false);
      if (x===false) {
        setDialogTextMS('อัพเดตเสร็จแล้ว');
        setOpenDialogMS(true);
      }
    }, 30000);
  }

  useEffect(() => {
    // if (typeof userinfo.user.role !== 'undefined') {
    //   if (userinfo.user.role) {
    //     if (["CM","AdminTeams","Teams"].indexOf(userinfo.user.role)>-1) {
    //       setDisabledButtonUpdateSS(false);
    //     }
    //   }
    // }
    // eslint-disable-next-line 
  }, []);

  return (
    <div style={{ marginBottom: 100 }}>

      <Dialog disableBackdropClick={true} className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}>

        <Grid container style={{ marginTop: -10, marginBottom: 0 }} justify="center">
          <Grid item xs={12} sm={12} style={{ marginBottom: 10 }}>
            <h4>รายงาน</h4>
            <div style={{ marginTop: -10, color: "grey" }}>
              คำตอบของแบบสอบถามที่ไม่ยอมรับ Consent จะไม่ถูกนำมาประมวลผลรายงาน
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>

            <Grid container style={{ border: 'solid 1px grey', borderRadius: 5, padding: 4, marginTop: 5 }}>
              <Grid item xs={12} sm={10} style={{ paddingLeft: 10 }}>
                1. คำตอบแยกรายแบบสอบถาม
                <div style={{ fontSize: 14, color: 'grey' }}>
                  ตารางแสดงข้อมูลคำตอบตามรายชื่อกลุ่มเสี่ยง เลือกดูตามชุดแบบสอบถาม
                </div>
              </Grid>
              <Grid item xs={12} sm={2} style={{ paddingRight: 5 }}>
                <Button variant="contained" fullWidth color='primary'
                  // disabled={disabledButtonUpdateSS}
                  onClick={() => {
                    history.push({ pathname: '/reportQuestionnaireAnswer' });
                  }}
                  startIcon={<DvrIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                />
              </Grid>
            </Grid>

            <Grid container style={{ border: 'solid 1px grey', borderRadius: 5, padding: 4, marginTop: 5 }}>
              <Grid item xs={12} sm={10} style={{ paddingLeft: 10 }}>
                2. ข้อมูล Summary ของเด็ก(กลุ่มเสี่ยง)
                <div style={{ fontSize: 14, color: 'grey' }}>
                  ตารางแสดงรายการข้อมูลทั่วไปของเด็ก(กลุ่มเสี่ยง), ข้อมูลผู้ปกครอง, ทีมดูแล, สถานะการทำแบบสอบถาม 
                </div>
              </Grid>
              <Grid item xs={12} sm={2} style={{ paddingRight: 5 }}>
                <Button variant="contained" fullWidth color='primary'
                  // disabled={true}
                  onClick={() => {
                    history.push({ pathname: '/reportCaseSummary' });
                  }}
                  startIcon={<DvrIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </MuiPickersUtilsProvider>

    </div>
  );
}