/* eslint-disable @typescript-eslint/no-unused-vars */
import { DELETE_TOKEN } from '../../actions';
import Actions from '../../actions/teams';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

const deleteToken = fetchEntity.bind(null, Actions.deleteToken, api.deleteToken);

function* callFirstAction(action: any) {
  yield call(
    deleteToken,action.deleteToken.params,
    {
      onSuccess: (success: any) => {
        console.log("DELETE TOKEN",success);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('deleteTeams FAILURE',4000));
      },
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(DELETE_TOKEN.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
