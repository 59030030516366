
import countNew from "./countNew";
import countAssigned from "./countAssigned";
import countInprogress from "./countInprogress";
import countWaitconclusion from "./countWaitconclusion";
import countFinish from "./countFinish";
import countRenew from "./countRenew";
import countSeverity from "./countSeverity";
export default{
  countNew,
  countAssigned,
  countInprogress,
  countWaitconclusion,
  countFinish,
  countRenew,
  countSeverity
}