import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm /*WrappedFieldProps, InjectedFormProps*/ } from 'redux-form';
import { Dispatch } from 'redux';
import { compose } from 'recompose';
import { RootState } from '../../reducers';
import { LoginResponseData } from '../../types';
import { testAction } from '../../actions/test';

import { snackbarFailure, snackbarSuccess, snackbarWarning } from '../../actions/notification';
import { firstAction } from '../../actions/example';
import { push } from 'connected-react-router';

interface IProps {
  user: LoginResponseData;
  testAction: () => void;
  successAction: (msg: string) => void;
  warningAction: (msg: string) => void;
  failureAction: (msg: string) => void;

  stepAction: (data: any) => void;
  redirectTest: (url: string) => void;
}
interface EXProps {}

type TProps = IProps & EXProps;

class Example extends Component<TProps> {
  componentDidMount(): void {
    const { testAction } = this.props;

    testAction();
  }

  showSuccess = () => {
    const { successAction } = this.props;

    successAction('SUCCESS MESSAGE !!!');
  };
  showWarning = () => {
    const { warningAction } = this.props;

    warningAction('WARNING MESSAGE !!!');
  };
  showError = () => {
    const { failureAction } = this.props;

    failureAction('FAILURE MESSAGE !!!');
  };

  onClickFirst = () => {
    const { stepAction } = this.props;

    stepAction('ABC From First');
  };

  onRedirectClick = () => {
    const { redirectTest } = this.props;

    redirectTest('/xxxx');
  };

  render() {
    const { user } = this.props;
    return (
      <div>
        <div>Example: {user.user.fullname}</div>
        <div>
          <button onClick={this.showSuccess}> Success</button>
          <button onClick={this.showWarning}> WARNING</button>
          <button onClick={this.showError}> ERROR</button>
          <button onClick={this.onClickFirst}> FIRST ACTION</button>
          <button onClick={this.onRedirectClick}> REDIRECTZ</button>
        </div>
        <div>
          <Field name='example' required label='Example' component='input' />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user.response,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    successAction: (msg: string) => dispatch(snackbarSuccess(msg)),
    warningAction: (msg: string) => dispatch(snackbarWarning(msg)),
    failureAction: (msg: string) => dispatch(snackbarFailure(msg)),
    testAction: () => dispatch(testAction.request()),

    stepAction: (data: any) => dispatch(firstAction.request(data)),

    redirectTest: (url: string) => dispatch(push(url)),
  };
};

export default compose<TProps, EXProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'example-form',
    destroyOnUnmount: true,
    enableReinitialize: false,
    // validate: createValidate,
  })
)(Example);
