/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { getAll } from "../../services/UniversalAPI";
import { getCurrentUser } from '../../services/auth.service';
import Permission from "../../services/Permission";
  
const getResponsibleCaseData = async (dashboardFilter) => {
  const currentUser = getCurrentUser();
  const { role, changwat, amphur, hospcode, casestatusRole } = Permission("dashboard");
  let officerId = "";
  let userTeams = [];
  if(currentUser!=null){
    officerId = currentUser.userId;
    userTeams = (currentUser.user.teams!==undefined)?currentUser.user.teams.map((teams)=>{return teams.id;}):[];
  }
  const params = {
    filter: {
      fields: { name: true, osccpersonCount: true },
      counts: "osccperson"
    }
  };
  try {
    if (role === "R8") { // นับทั้งหมด

    } else if (role === "CH") { // นับเฉพาะจังหวัดตนเอง
      params.filter.countsWhere = [{ province_name: changwat }];
    } else if (role === "HOS") { // นับเฉพาะหน่วยบริการตนเอง
      params.filter.countsWhere = [{ hcode: hospcode }];
    } else if (role === "OG") { // Organization
      params.filter.countsWhere = {and:[]};
      if(dashboardFilter.changwat.changwatname !== ""){
        params.filter.countsWhere.and.push({ province_name: dashboardFilter.changwat.changwatname });
      }else if(dashboardFilter.amphur.amphur_name !== ""){
        params.filter.countsWhere.and.push({ amphur_name: dashboardFilter.amphur.amphur_name });
      }else if(dashboardFilter.hospital.hos_id !== ""){
        params.filter.countsWhere.and.push({ hcode: dashboardFilter.hospital.hos_id });
      }else{
        params.filter.countsWhere = {};
      }
    } else if (role === "CM") { // นับเฉพาะในอำเภอตนเอง
      if(amphur!==""){
        params.filter["countsWhere"] = [{ amphur_name: amphur }];
      }
    } else if (role === "ATM") { // Admin Teams
      if(userTeams!==""){
        params.filter.countsWhere = [{ osccTeamId : { in: userTeams } }];
      }
    } else if (role==="TM") { // Teams
      if(officerId!==""){
        params.filter.countsWhere = [{ officerId : { in : [officerId] } }];
      }
    }
    // console.log(JSON.stringify(params.filter));
    const response = await getAll(params, "casestatuses");
    if (response.status === 200) {
      let responsibleCase = {
        labels:["",""],
        supervising:0,
        discharged:0
      };
      response.data.map(function (currentValue, index) {
        let counts = (typeof currentValue.osccpersonCount !=='undefined' && currentValue.osccpersonCount !==null)?currentValue.osccpersonCount:0;
        if(["assignedTeam", "assignedOfficer","recievedCase","sentCM","inProgress"].includes(currentValue.name)){
          responsibleCase["supervising"] += counts;
        }else if(["completed", "finish"].includes(currentValue.name)){
          responsibleCase["discharged"] += counts;
        }
        return null;
      });
      
      responsibleCase.labels[0]="อยู่ระหว่างดูแล : "+responsibleCase.supervising;
      responsibleCase.labels[1]="จำหน่ายแล้ว : "+responsibleCase.discharged;
      return responsibleCase;
    }
  } catch (error) { console.log('error', error) }
};

export default getResponsibleCaseData;