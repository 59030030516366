import { INSERT_ANSWER } from "../../actions";
import { AnyAction } from "redux";
import { InsertAnswer } from "../../types/activity";

const initialState:InsertAnswer = {
  isFetching: false,
  data: {}
};

export default function insertAnswer(
  state = initialState,
  action: AnyAction
): InsertAnswer {
  switch (action.type) {
    case INSERT_ANSWER.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case INSERT_ANSWER.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case INSERT_ANSWER.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
