import { AnyAction } from 'redux';
// import _ from 'lodash';
import { LoginResponse, LoginResponseData } from '../../types';
import { LOGIN, LOGOUT } from '../../actions/users';

const defaultState: LoginResponse = {
  isLoginError: false,
  response: {
    created: "",
    id: "",
    ttl: 0,
    userId: "",
    user: {
      id: "",
      cid: "",
      fullname: "",
      position: "",
      email: "",
      mobile: "",
      role: "",
      changwat: {
        changwatcode: "", 
        changwatname: ""
      },
      amphur: {
        amphur_id: "", 
        amphur_name: ""
      },
      tambon: {
        tambon_id: "",
        tambon_name: ""
      },
      department: {
        hos_fullname: "", 
        hos_name: "", 
        hcode: ""
      },
      application: [],
      informConsent: [],
      osccteams: [{
        memberTypeId: "",
        memberTypeName: "",
        teamCode: "",
        teamName: ""
      }],
      teams: [{
          hospcode: "",
          teamCode: "",
          teamName: "",
      }],
      updateDatetime: "",
      userType: {
        userTypeId: 0,
        userTypeName: ""
      },
      registerDatetime: ""
    }
  }
};

export default function user(state = defaultState, action: AnyAction): LoginResponse {
  switch (action.type) {
    case LOGIN.REQUEST: {
      return {
        ...state,
        response: {},
        isLoginError: false,
      };
    }
    case LOGIN.SUCCESS: {
      return {
        ...state,
        response: action.data.response,
        isLoginError: false,
      };
    }

    case LOGOUT.REQUEST:
    case LOGIN.FAILURE:
      return {
        ...state,
        response: {},
        isLoginError: false,
        // token: null,
      };

    default:
      return state;
  }
}
