/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useCallback, useEffect,useState} from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from '@material-ui/core';
import moment from "moment";
import "moment/dist/locale/th";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../reducers";
import { jaJP } from '@material-ui/data-grid';
import _ from 'lodash';
import Actions from '../../../actions/activity';

interface IProps {
  excelParams: Record<string, any>;
  label?: string;
  disabled?: boolean;
}

const ExcelExport=(props:IProps)=>{
  const dispatch = useDispatch();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const globalState = useSelector((state: RootState) => state);
  const caseList = globalState.activity.caseListExcel.data;
  const [column, setColumn] = useState<Array<string>>([]);
  const [buttonClick,setButtonClick]=useState<boolean>(false);
  const getThaiLabel = (type:string, name:string)=>{
    const caseStatus: Array<Record<string,string>> = [
      {'new':'รายใหม่'},
      {'assigned':'มอบหมายแล้ว'},
      {'inprogress':'ระหว่างดูแล'},
      {'waitconclusion':'รอสรุป'},
      {'finish':'เสร็จสิ้น'},
      {'renew':'เริ่มกระบวนการใหม่'}
    ];
    const riskLevel: Array<Record<string,string>> = [
      {'low':'ต่ำ'},
      {'medium':'ปานกลาง'},
      {'high':'สูง'},
      {'hign':'สูง'},
      {'victim':'เหยื่อ'},
      {'cased':'เหยื่อ'},
      {'other':'อื่นๆ'},
      {'normal':'ทั่วไป'}
    ];
    let thaiLabel = "";
    if(type==="caseStatus"){
      caseStatus.forEach((item:any)=>{
        if(name===Object.keys(item)[0]){thaiLabel = item[name];}
      });
    }else{
      riskLevel.forEach((item:any)=>{
        if(name===Object.keys(item)[0]){thaiLabel = item[name];}
      });
    }
    return thaiLabel;
  }

  const exportToExcel = useCallback((data:any, columnTH:any) => {
    const fileName = "case_"+(moment().format("YYYYMMDDHHmmss"));
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [columnTH], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + ".xlsx");
    setButtonClick(false);
  },[]);

  const getCases = (excelParams:Record<string,any>) => {
    if(!_.isEmpty(excelParams)){
      const newParams = {where: excelParams};
      setColumn(["สถานะเคส","ความเสี่ยง","หมายบัตรประชาชน","ชื่อ","สกุล","เพศ","อายุ"]);
      dispatch(Actions.GetCasesExcel.request(newParams));
      setButtonClick(true);
    }
  };
  
  useEffect(()=>{
    if(caseList.length>0){
      if(buttonClick){
        let newData: Array<Record<string,string>> = [];
        caseList.forEach((item:any)=>{
          newData.push({
            caseStatus: (item.caseStatus)?getThaiLabel("caseStatus",item.caseStatus?.toLowerCase()):item.caseStatus,
            riskLevel: (item.riskLevel)?getThaiLabel("riskLevel",item.riskLevel?.toLowerCase()):item.riskLevel,
            cid: item.cid,
            fname: item.fname,
            lname: item.lname,
            sex: item.sex,
            age: item.age
          })
        });
        exportToExcel(newData,column);
      }
    }
  },[buttonClick, caseList, column, exportToExcel]);

  return (
    <Button 
      fullWidth 
      size="medium"
      variant="contained" 
      color="primary"
      disabled={props.disabled}
      style={{ height: "40px", margin: 0 }}
      onClick={()=>getCases(props.excelParams)}
      >
        {props.label||""}
    </Button>
  )
}

export default ExcelExport;