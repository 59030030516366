/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_PERSON } from "../index";

const getPerson = {
  request: (payload:Record<string,any>) => action(GET_PERSON.REQUEST, { data:payload }),
  success: (response: any) => action(GET_PERSON.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_PERSON.FAILURE, { error }),
};

export default getPerson;