import React from 'react';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import { Control, Controller, UseFormRegister, UseFormSetValue } from "react-hook-form";
import moment from "moment";
import MomentUtils from "@date-io/moment";

interface IProps {
  data: {
    key: number;
    name: any;
    answersList?: Array<Record<string,any>>;
    disable: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  }
}

export default function GenTimePicker(props: IProps){
  const { key, name, disable, uForm } = props.data;
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Controller
        name={name}
        control={uForm.control}
        defaultValue={ moment() }
        render={({ field : { onChange, value } }: any) => {
          return <KeyboardTimePicker
            key={name}
            margin="normal"
            ampm={false}
            value={ value }
            disabled={disable}
            onChange={(e) => (e!==null)&&onChange(e)}
            KeyboardButtonProps={{"aria-label": "change time"}}
            
          />
        }}
      />
    </MuiPickersUtilsProvider>
  )
}