import { COUNT_WAITCONCLUSTION } from "../../../actions";
import { AnyAction } from "redux";
import { CountCaseStatus } from "../../../types/dashboard";

const initialState:CountCaseStatus = {
  isFetching: false,
  count: 0
};

export default function countCaseStatus(
  state = initialState,
  action: AnyAction
): CountCaseStatus {
  switch (action.type) {
    case COUNT_WAITCONCLUSTION.REQUEST:{
      return {
        ...state,
        isFetching: true,
        count: 0
      };
    }
    case COUNT_WAITCONCLUSTION.SUCCESS: {
      if(!action.data.count){delete action.data.count;}
      return {
        ...state,
        isFetching: false,
        count: action.data.rows?.count
      };
    }
    case COUNT_WAITCONCLUSTION.FAILURE:
      return {
        ...state,
        isFetching: false,
        count: 0
      };
    default:
      return state;
  }
}
