import { fork, call, takeEvery } from 'redux-saga/effects';
import ACTIONS from '../../actions/filter';
import * as TYPES from '../../actions';
import * as API from '../../services/api';
import { fetchEntity } from '../saga';

const getEntity = fetchEntity.bind(null, ACTIONS.agency, API.getAgency);

function* callAction(action: any) {
  yield call(getEntity, { ...action });
}

function* watchAction() {
  yield takeEvery(TYPES.GET_AGENCY.REQUEST, callAction);
}

export default function* root() {
  yield fork(watchAction);
}
