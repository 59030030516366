/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React from "react";
// Icons
import HomeIcon from "@material-ui/icons/Home";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Assignment from "@material-ui/icons/Assignment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonIcon from '@material-ui/icons/Person';
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SettingsIcon from "@material-ui/icons/Settings";
import DvrIcon from "@material-ui/icons/Dvr";
import GroupIcon from '@material-ui/icons/Group';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LowPriorityIcon from '@material-ui/icons/LowPriority';

// Pages Component
import Cases from "../components/case/Cases";
import CaseEMR from "../components/case/CaseEMR";
import CasePlanList from "../components/case/CasePlanList";
import CasePlan from "../components/case/CasePlan";
import CasePlanTeam from "../components/case/CasePlanTeam";
import CaseConclude from "../components/case/CaseConclude";
import CaseRemoved from "../components/case/CaseRemoved";
import Dashboard from "../components/dashboard/DashBoard";
import Questionnaire from "../components/questionnaire/Questionnaire";
import QuestionnaireEdit from "../components/questionnaire/QuestionnaireEdit";
import QuestionnaireView from "../components/questionnaire/QuestionnaireView";
import Teams from "../components/team/Teams";
import TeamList from "../components/team/TeamList";
import TeamLists from "../components/team/TeamList";
import Home from "../components/people/Home";
import ServiceSlot from "../pages/serviceslot";
import UserProfile from "../components/users/UserProfile";


import Login from "../components/users/Login";
import ThaidCallback from "../components/users/ThaidCallback";
import Logout from "../components/users/Logout";
import LineLogin from "../components/users/LineLoginCallback";
import Register from "../components/users/Register";
import ChangePassword from "../components/users/ChangePassword";
import RequestResetPassword from "../components/users/RequestResetPassword";
import ResetPassword from "../components/users/ResetPassword";
import UserList from "../components/users/UserList";
import UserEdit from "../components/users/UserEdit";
import Setting from "../components/setting/setting";
import Report from "../components/report/report";
import ReportQuestionnaireAnswer from "../components/report/reportQuestionnaireAnswer";
import ReportCaseSummary from "../components/report/reportCaseSummary";

import JACK from "../components/case/JACK";

import ExampleRedux from "../components/Example";
import Osccperson from "../pages/osccperson";
import CaseManagement from "../pages/activity/case";
import CaseManagementNew from "../pages/cases";
import QuestionnaireList from "../pages/activity/questionair";
import TakeQuestionnaire from "../pages/activity/takeQuestionair";
import Questionnaires from "../pages/questionairs/take";
import QuestionnairesList from "../pages/questionairs/take/list";

import Case from "../pages/activity/case";
import Groups from "../pages/activity/groups";
import AssignPage from "../pages/activity/assign";
import TeamsNew from "../pages/teams";
import Reports from "../pages/report";
import Report1 from "../pages/report/report1";
import Report2 from "../pages/report/report2";
import Evaluate from "../pages/evaluate";
// Component Route

// Side Bar Route and Menu Display
const dashboard = {
  id: "menu_dashboard",
  path: "/",
  icon: <DashboardIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization','Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: Dashboard,
};
const teams = {
  id: "menu_teams",
  path: "/teams",
  icon: <PermContactCalendarIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital'],
  component: Teams,
};
const teamsnew = {
  id: "menu_teamsnew",
  path: "/teams-manage",
  icon: <PermContactCalendarIcon />,
  roles: ['AdminR8'],
  component: TeamsNew,
};
const serviceslot = {
  id: "menu_serviceslot",
  path: "/serviceslot",
  icon: <LowPriorityIcon />,
  roles: ["AdminR8"],
  component: ServiceSlot,
};
const evaluateFacilitator = {
  id: "menu_evaluate",
  path: "/evaluate-fa",
  icon: <PermContactCalendarIcon />,
  roles: ['Coach'],
  component: Evaluate,
};
const caseManagement = {
  id: "menu_cases",
  path: "/activity/case",
  icon: <GroupIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization','Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: CaseManagement,
};
const caseManagementNew = {
  id: "menu_cases_new",
  path: "/activity/case-manage",
  icon: <GroupIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization','Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: CaseManagementNew,
};
const questionnaireList = {
  id: "menu_questionnaire_list",
  path: "/questionnaire_list",
  icon: <Assignment />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: QuestionnaireList,
};
// const questionnaires = {
//   id: "menu_questionnaires",
//   path: "/questionnaire_list",
//   icon: <Assignment />,
//   roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
//   component: Questionnaires,
// };
// const questionnairesList = {
//   id: "menu_questionnaires_list",
//   path: "/questionnaire_list",
//   icon: <Assignment />,
//   roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
//   component: QuestionnairesList,
// };

const userlist = {
  id: "menu_users",
  path: "/userlist",
  icon: <PersonAdd />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital'],
  component: UserList,
};
const caseremoved = {
  id: "menu_casesOut",
  path: "/caseremoved",
  icon: <TransferWithinAStationIcon />,
  roles: ["AdminR8"],
  component: CaseRemoved,
};
const report = {
  id: "menu_report",
  path: "/report",
  icon: <DvrIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: Reports,
};
const report1 = {
  id: "menu_report",
  path: "/report-recording-summary",
  icon: <DvrIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: Report1,
};
const report2 = {
  id: "menu_report",
  path: "/summary-report",
  icon: <DvrIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital', 'Organization', 'Coach', 'Assessor', 'CM', 'AdminTeams', 'Teams'],
  component: Report2,
};
const setting = {
  id: "menu_settings",
  path: "/setting",
  icon: <SettingsIcon />,
  roles: ['AdminR8', 'AdminChangwat', 'AdminHospital'],
  component: Setting,
};


const takeQuestionnaire = {
  id: "takeQuestionnaire",
  path: "/questionaire",
  icon: <HomeIcon />,
  roles: [
    "AdminR8", "AdminChangwat", "AdminHospital", "Organization", "Coach", "Assessor", "CM", "AdminTeams", "Teams",
  ],
  component: TakeQuestionnaire,
};
const activityCase = {
  id: "activityCase",
  path: "/activity/case",
  icon: <HomeIcon />,
  roles: [
    "AdminR8", "AdminChangwat", "AdminHospital", "Organization", "Coach", "Assessor", "CM", "AdminTeams", "Teams",
  ],
  component: Case,
};
const assignPage = {
  id: 'activityCase',
  path: '/assign',
  icon: <HomeIcon />,
  roles: ["AdminR8", "AdminChangwat", "AdminHospital", "Organization", "Coach", "Assessor", "CM", "AdminTeams", "Teams"],
  component: AssignPage,
};
const group = {
  id: "activityGroups",
  path: "/activity/groups",
  icon: <HomeIcon />,
  roles: ["AdminR8", "AdminChangwat", "AdminHospital", "Organization", "Coach", "Assessor", "CM", "AdminTeams", "Teams"],
  component: Groups,
};
const home = {
  id: "Home",
  path: "/",
  icon: <HomeIcon />,
  roles: ["Case", "CareGiver"],
  component: Home,
};

const teamlist = {
  id: "teamlist",
  path: "/teamlist",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: TeamLists,
};
const questionnaire = {
  id: "Questionnaires",
  path: "/questionnaire",
  icon: <Assignment />,
  roles: ["CM", "AdminTeams"],
  // roles: ["AdminR8"],
  component: Questionnaire,
};
const questionnaireedit = {
  id: "questionnaireedit",
  path: "/questionnaireedit",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: QuestionnaireEdit,
};
const questionnaireview = {
  id: "questionnaireview",
  path: "/questionnaireview",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
    "Case",
    "CareGiver",
  ],
  component: QuestionnaireView,
};
const cases = {
  id: "cases",
  path: "/cases",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: Cases,
};
const caseplanlist = {
  id: "caseplanlist",
  path: "/caseplanlist",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: CasePlanList,
};
const caseplan = {
  id: "caseplan",
  path: "/caseplan",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: CasePlan,
};
const caseconclude = {
  id: "caseconclude",
  path: "/caseconclude",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: CaseConclude,
};
const caseplanteam = {
  id: "caseplanteam",
  path: "/caseplanteam",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: CasePlanTeam,
};
const caseemr = {
  id: "caseemr",
  path: "/caseemr",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "Coach",
    "Assessor", 
    "AdminTeams",
    "Teams",
  ],
  component: CaseEMR,
};


const userprofile = {
  id: "ข้อมูลผู้ใช้งาน",
  path: "/userprofile",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
    "Case",
    "CareGiver",
  ],
  component: UserProfile,
};

const osccperson = {
  id: "Osccperson",
  path: "/osccperson",
  icon: <SupervisedUserCircleIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: Osccperson,
};

// System Route
const root = {
  id: "root",
  path: "/",
  exact: true,
  icon: <LockOpenIcon />,
  roles: ["noRole"],
  component: Login,
};
const register = {
  id: "register",
  path: "/register",
  icon: <PersonAddIcon />,
  roles: ["AdminR8", "AdminChangwat", "AdminHospital", "noRole"],
  component: Register,
};
const login = {
  id: "Login",
  path: "/login",
  icon: <LockOpenIcon />,
  roles: ["noRole"],
  component: Login,
};
const thaidCallback = {
  id: "thaidcallback",
  path: "/thaid/callback",
  exact: true,
  roles: ["noRole"],
  component: ThaidCallback,
};
const linelogin = {
  id: "linelogin",
  path: "/linelogin",
  icon: null,
  roles: ["noRole"],
  component: LineLogin,
};
const logout = {
  id: "Logout",
  path: "/logout",
  icon: <ExitToAppIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "Coach", 
    "Assessor", 
    "CM",
    "AdminTeams",
    "Teams",
    "Case",
    "CareGiver",
  ],
  component: Logout,
};

const useredit = {
  id: "useredit",
  path: "/useredit",
  icon: <SupervisorAccountIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: UserEdit,
};
const changepassword = {
  id: "เปลี่ยนรหัสผ่าน",
  path: "/changepassword",
  icon: <VpnKeyIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "Coach",
    "Assessor", 
    "CM",
    "AdminTeams",
    "Teams",
    "Case",
    "CareGiver",
  ],
  component: ChangePassword,
};
const requestresetpassword = {
  id: "รีเซ็ตรหัสผ่าน",
  path: "/requestresetpassword",
  icon: <VpnKeyIcon />,
  roles: ["noRole"],
  component: RequestResetPassword,
};
const resetpassword = {
  id: "รีเซ็ตรหัสผ่าน",
  path: "/resetpassword/:userId/:tokenParam",
  icon: <VpnKeyIcon />,
  roles: ["noRole"],
  component: ResetPassword,
};

const reportQuestionnaireAnswer = {
  id: "reportQuestionnaireAnswer",
  path: "/reportQuestionnaireAnswer",
  icon: <DvrIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: ReportQuestionnaireAnswer,
};
const reportCaseSummary = {
  id: "reportCaseSummary",
  path: "/reportCaseSummary",
  icon: <DvrIcon />,
  roles: [
    "AdminR8",
    "AdminChangwat",
    "AdminHospital",
    "Organization",
    "CM",
    "AdminTeams",
    "Teams",
  ],
  component: ReportCaseSummary,
};

const jack = {
  id: "jack",
  path: "/jack",
  icon: <VpnKeyIcon />,
  roles: ["AdminR8", "noRole"],
  component: JACK,
};

const example = {
  id: "example",
  path: "/example",
  icon: <VpnKeyIcon />,
  roles: ["CM", "AdminTeams", "noRole"],
  component: ExampleRedux,
};

export const appBarRoute = [
  dashboard,
  register,
  changepassword,
  login,
  logout,
  userprofile,
];
export const sideBarRoute = [
  dashboard,
  // teams,
  teamsnew,
  caseManagement,
  // caseManagementNew,
  // serviceslot,
  // questionnaires,
  caseremoved,
  report,
  userlist,
  setting
];
export const mainRoute = [
  root,
  dashboard,
  caseManagement,
  caseManagementNew,
  // questionnaires,
  // questionnairesList,
  questionnaireList,
  activityCase,
  assignPage,
  takeQuestionnaire,
  group,
  register,
  userlist,
  useredit,
  changepassword,
  requestresetpassword,
  resetpassword,
  linelogin,
  login,
  thaidCallback,
  logout,
  teams,
  teamsnew,
  evaluateFacilitator,
  teamlist,
  questionnaire,
  cases,
  caseplanlist,
  questionnaireedit,
  questionnaireview,
  caseplan,
  caseconclude,
  caseplanteam,
  caseemr,
  home,
  serviceslot,
  caseremoved,
  userprofile,
  setting,
  report,
  report1,
  report2,
  reportQuestionnaireAnswer,
  reportCaseSummary,
  jack,
  example,
  osccperson,
];
