import { callGet, callPost, callDelete, callPatch } from "../request";
import { getToken } from "./getToken";
const token = getToken();

export const getGroups = async (payload: any={}) => {
	interface Param {
		access_token: string;
		filter: {
			where: Record<any, any>;
			include?: Record<any, any>;
		};
	}
	let params: Param = {
		access_token: token,
		filter: {
			where: {
				"teams.teamCode": { in: payload },
			},
			include: { relation: "osccperson_assign" },
		},
	};


	const response = await callGet(`osccperson_groups`, token , params);
	return { 
		data : response.response.data, 
		totalPage: response.response.headers["x-total-count"] as number
	};
};