/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "../custom_components/Grid/GridItem.js";
import GridContainer from "../custom_components/Grid/GridContainer.js";
import CustomInput from "../custom_components/CustomInput/CustomInput.js";
import Button from "../custom_components/CustomButtons/Button.js";
import Card from "../custom_components/Card/Card.js";
import CardHeader from "../custom_components/Card/CardHeader.js";
import CardAvatar from "../custom_components/Card/CardAvatar.js";
import CardBody from "../custom_components/Card/CardBody.js";
import CardFooter from "../custom_components/Card/CardFooter.js";
import useGlobal from "../../store";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validation from "../../services/validation";
import { get,getAll,patch } from "../../services/UniversalAPI";
import { getCurrentUser } from "../../services/auth.service";
import { useDialog } from '../../services/dialog/ModalProvider';
import moment from "moment";
import "moment/locale/th";
const styles = {
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -16px) scale(0.75) !important',
    }
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const { setDialog } = useDialog();
  const [globalState, globalActions] = useGlobal();
  const [edit,setEdit] = useState(false);
  const userProfile = (typeof globalState.currentUser.user !== 'undefined') ? globalState.currentUser.user : {};
  const [disabled, setDisabled] = useState(false);
  const [showPosition, setShowPosition] = useState(false);
  const [changwats, setChangwats] = useState([]);
  const [amphurs, setAmphurs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selected,setSelected]=useState({
    changwat : null,
    ampur : null,
    department : null
  });
  const [formData, setFormdata] = useState({
    fullname: '',
    position: '',
    cid: '',
    email: '',
    mobile: '',
    changwat: {changwatcode: "", changwatname: ""},
    amphur: {amphur_id: "", amphur_name: ""},
    department: {hos_name: "", hos_fullname: "", hcode: ""},
    updateDatetime: '',
  });
  const [validator, setValidator] = useState({
    formElements: {
      userType: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      fullname: {
        validator: { required: true, minLength: 5 },
        error: { status: false, message: '' }
      },
      position: {
        validator: {},
        error: { status: false, message: '' }
      },
      cid: {
        validator: { required: true, number: true, stringLength: 13, pattern: 'mod13' },
        error: { status: false, message: '' }
      },
      email: {
        validator: { required: true, pattern: 'email' },
        error: { status: false, message: '' }
      },
      mobile: {
        validator: { required: true, stringLength: 10 },
        error: { status: false, message: '' }
      },
      changwat: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      amphur: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      department: {
        validator: { required: true },
        error: { status: false, message: '' }
      }
    },
    formValid: true
  });
  const onElementChange = (e) => {
    let result = validation(validator, e);
    if (typeof result !== 'undefined') {
      const name = (typeof e.target !== 'undefined') ? e.target.name : e.name;
      const value = (typeof e.target !== 'undefined') ? e.target.value : e.value;
      if(["changwat","amphur","department"].includes( name )){
        if(name==="changwat"){
          let newChwVal = (value!=="")?value:null;
          setSelected({...selected,
            changwat : newChwVal,
            ampur : null,
            department : null
          });
          setFormdata({ ...formData, 
            changwat : newChwVal,
            amphur : null,
            department : null
          });
        }else if(name==="amphur"){
          let newAmpVal = (value!=="")?value:null;
          setSelected({
            ...selected,
            ampur : newAmpVal,
            department : null
          });
          setFormdata({ ...formData, 
            amphur : newAmpVal,
            department : null
          });
        }else if(name==="department"){
          let newHosVal = (value!=="")?value:null;
          setSelected({...selected,department : newHosVal});
          setFormdata({ ...formData, department : newHosVal});
        }
      }else if(name==="userType"){
        if(value.userTypeId!==3){
          validator.formElements.position.validator={};
          setFormdata({ ...formData, position: '',[name]: value });
          setShowPosition(false);
        }else{
          validator.formElements.position.validator={ required: true, minLength: 5 };
          setFormdata({ ...formData, position: '',[name]: value });
          setShowPosition(true);
        } 
      }else{
        setFormdata({ ...formData, [name]: value });
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  }
  const getChwAmpHos = async (model, val) => {
    try {
      const params = { filter: {} };
      switch (model) {
        case "cchangwats":
          params.filter.fields = { changwatcode: true, changwatname: true };
          params.filter.where = { zonecode: "08" };
          break;
        case "amphurs":
          params.filter.fields = { amphur_id: true, amphur_name: true };
          params.filter.where = { province_id: val };
          break;
        case "hospitals":
          params.filter.fields = { hos_id: true, hos_name: true, hos_fullname: true };
          params.filter.where = { hos_ampid: val };
          break;
        default: ;
      }
      // console.log(JSON.stringify(params));
      const response = await getAll(params, model);
      if (response.status === 200) {
        switch (model) {
          case "cchangwats": 
            // let newCW = [{changwatcode:"",changwatname:""}].concat(response.data);
            setChangwats(response.data); 
            setAmphurs([]); 
            setDepartments([]);
            break;
          case "amphurs": 
            // let newAM = [{amphur_id:"",amphur_name:""}].concat(response.data);
            setAmphurs(response.data); 
            setDepartments([]);
            break;
          case "hospitals": 
            // let newHS = [{hos_id:"",hos_fullname:"",hos_name:""}].concat(response.data);
            setDepartments(response.data); 
            break;
          default: ;
        }
      }
    } catch (error) { console.log('error', error) }
  };
  const onFormSubmit = (event) => {
    // Validate all element in form 
    if (validator.formValid) {
      Object.entries(formData).forEach(([k, v]) => {
        onElementChange({ name: k, value: v });
      })
    }
    let formStatus = true;
    let form = { ...validator.formElements };
    for (let name in form) {
      if (form[name].validator.required === true) {
        formStatus = (!form[name].error.status) ? formStatus : false;
      }
    }
    if (formStatus) {
      setValidator({ ...validator, formValid: false });
      formData.updateDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
      patch(userProfile.id, formData, "teamusers").then(
        (response) => {
          if (response.status === 200) {
            setDialog({
              title: 'ผลการแก้ไขข้อมูล',
              titleStyle:{padding:'8px 16px',color: '#383838', borderBottom:'1px solid #ccc'},
              content: 'แก้ไขข้อมูลสำเร็จ คุณจำเป็นต้องเข้าสู่ระบบอีกครั้ง',
              contentStyle: { padding:'20px 24px 0 24px', width: '400px', textAlign: 'center', color: 'green' },
              button: {
                confirm: { enable: false, text: '' },
                cancel: { enable: true, text: 'ปิด', redirect: '/logout' },
              }
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  };
  useEffect( () => {
    let unmounted = false;
    const initState = async() => {
      setFormdata({
        fullname: globalState.currentUser.user.fullname||'',
        position: globalState.currentUser.user.position||'',
        cid: globalState.currentUser.user.cid||'',
        email: globalState.currentUser.user.email||'',
        mobile: globalState.currentUser.user.mobile||'',
        changwat: globalState.currentUser.user.changwat||null,
        amphur: globalState.currentUser.user.amphur||null,
        department: globalState.currentUser.user.department||null,
        role:globalState.currentUser.user.role||null,
      });
      await getChwAmpHos("cchangwats");
      await getChwAmpHos("amphurs",globalState.currentUser.user.changwat.changwatcode);
      await getChwAmpHos("hospitals",globalState.currentUser.user.amphur.amphur_id);
      setSelected({...selected,
        changwat : globalState.currentUser.user.changwat,
        ampur : globalState.currentUser.user.amphur,
        department : globalState.currentUser.user.department
      });
    }
    if (typeof globalState.currentUser.user !== 'undefined') {
      if (!unmounted) {
        initState();
        if(typeof globalState.currentUser.user.lineRegister !== 'undefined' && globalState.currentUser.user.lineRegister){
          setDisabled(true);
        }
      }
    }

    return () =>  unmounted = true ;
    // eslint-disable-next-line 
  }, [globalState.currentUser]);

  return (
    <div className={classes.root} >
      <GridContainer style={{padding:'1vh',justifyContent: 'center'}}>
        <GridItem xs={12} sm={12} md={12} style={{display:(edit)?'block':'none'}}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>แก้ไขข้อมูลส่วนตัว</h4>
              <p className={classes.cardCategoryWhite}>ระบุข้อมูลให้ครบถ้วนและตรวจสอบความถูกต้องก่อนดำเนินการบันทึกข้อมูล</p>
            </CardHeader>
            <CardBody>
              <GridContainer style={{padding:'1rem 0.2rem 0 0.2rem'}}>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    id="fullname"
                    name="fullname"
                    label="ชื่อสกุล"
                    type="text"
                    size="small"
                    variant="outlined"
                    onChange={(e) => onElementChange(e)}
                    value={formData.fullname||''}
                    error={validator.formElements.fullname.error.status}
                    helperText={validator.formElements.fullname.error.message}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    id="cid"
                    name="cid"
                    label="หมายเลขบัตรประชาชน"
                    type="text"
                    size="small"
                    variant="outlined"
                    onChange={(e) => onElementChange(e)}
                    value={formData.cid}
                    error={validator.formElements.cid.error.status}
                    helperText={validator.formElements.cid.error.message}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    id="email"
                    name="email"
                    label="อีเมลล์"
                    type="text"
                    size="small"
                    variant="outlined"
                    onChange={(e) => onElementChange(e)}
                    value={formData.email}
                    error={validator.formElements.email.error.status}
                    helperText={validator.formElements.email.error.message}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    id="mobile"
                    name="mobile"
                    label="หมายเลขศัพท์ที่ติดต่อได้"
                    type="text"
                    size="small"
                    variant="outlined"
                    onChange={(e) => onElementChange(e)}
                    value={formData.mobile}
                    error={validator.formElements.mobile.error.status}
                    helperText={validator.formElements.mobile.error.message}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{padding:'0 0.2rem 0 0.2rem'}}>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    id="position"
                    name="position"
                    label="ตำแหน่ง"
                    type="text"
                    size="small"
                    variant="outlined"
                    onChange={(e) => onElementChange(e)}
                    value={formData.position}
                    error={validator.formElements.position.error.status}
                    helperText={validator.formElements.position.error.message}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    id="changwat"
                    size="small"
                    fullWidth
                    options={changwats}
                    value={selected.changwat}
                    getOptionSelected={(option, value) => value.changwatname === option.changwatname}
                    getOptionLabel={(option) => option.changwatname || ''}
                    onChange={(e, newValue) => {
                      let changwatcode = (newValue != null) ? newValue.changwatcode : '';
                      onElementChange({ name: 'changwat', value: newValue });
                      getChwAmpHos("amphurs", changwatcode);
                    }}
                    renderInput={(params) =>
                      <TextField {...params}
                        id="changwat_texfield"
                        name="changwat"
                        label="จังหวัด"
                        variant="outlined"
                        onChange={(e) => onElementChange(e)}
                        error={validator.formElements.changwat.error.status}
                        helperText={validator.formElements.changwat.error.message}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    id="amphur"
                    size="small"
                    fullWidth
                    options={amphurs}
                    value={selected.ampur}
                    getOptionSelected={(option, value) => {
                      return value.amphur_name === option.amphur_name;
                    }}
                    getOptionLabel={(option) => option.amphur_name || null}
                    onChange={(_, newValue) => {
                      let amphur_id = (newValue != null) ? newValue.amphur_id : null;
                      onElementChange({ name: 'amphur', value: newValue });
                      getChwAmpHos("hospitals", amphur_id);
                    }}
                    renderInput={(params) => <TextField {...params}
                      label="อำเภอ"
                      variant="outlined"
                      onChange={(e) => onElementChange(e)}
                      error={validator.formElements.amphur.error.status}
                      helperText={validator.formElements.amphur.error.message}
                    />}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    id="department"
                    size="small"
                    fullWidth
                    options={departments}
                    value={selected.department}
                    getOptionSelected={(option, value) => value.hos_fullname === option.hos_fullname}
                    getOptionLabel={(option) => option.hos_name || ''}
                    onChange={(_, newValue) => {
                      if (newValue != null) {
                        delete Object.assign(newValue, { 'hcode': newValue['hos_id'] })['hos_id'];
                      }
                      let newHos = (newValue != null) ? newValue : null;
                      onElementChange({ name: 'department', value: newHos });
                      setFormdata({ ...formData, department: newHos });
                    }}
                    renderInput={(params) => <TextField {...params}
                      label="หน่วยงาน"
                      variant="outlined"
                      onChange={(e) => onElementChange(e)}
                      error={validator.formElements.department.error.status}
                      helperText={validator.formElements.department.error.message}
                    />}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={()=>{setEdit(false)}}>ยกเลิกแก้ไข</Button>
              <Button color="success" onClick={()=>{onFormSubmit()}}>บันทึก</Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{display:(edit)?'none':'block'}}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>ข้อมูลส่วนตัว</h4>
              <p className={classes.cardCategoryWhite}>คุณสามารถแก้ไขข้อมูลส่วนตัวของคุณได้โดยกดปุ่ม "แก้ไขข้อมูล"</p>
            </CardHeader>
            <CardBody>
              <GridContainer style={{padding:'1rem 1rem 0 1rem'}}>
                <GridItem xs={12} sm={12} md={12}><strong>ชื่อ-สกุล</strong> : {formData.fullname||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>หมายเลขบัตรประชาชน</strong> : {formData.cid||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>อีเมลล์</strong> : {formData.email||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>เบอร์โทรศัพท์</strong> : {formData.mobile||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>ตำแหน่ง</strong> : {formData.position||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>หน่วยงาน</strong> : {formData.department.hos_fullname||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>อำเภอ</strong> : {formData.amphur.amphur_name||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>จังหวัด</strong> : {formData.changwat.changwatname||''}</GridItem>
                <GridItem xs={12} sm={12} md={12}><strong>สิทธิ์การใช้งาน</strong> : {formData.role||''}</GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="success"  onClick={()=>{setEdit(true)}}>แก้ไขข้อมูล</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
