import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { Control, Controller, UseFormRegister, UseFormSetValue } from "react-hook-form";
import moment from "moment";
import MomentUtils from "@date-io/moment";

interface IProps {
  data: {
    key: number;
    name: any;
    answersList?: Array<Record<string,any>>;
    disable: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  }
}

export default function GenDatePicker(props: IProps){
  const { key, name, disable, uForm } = props.data;
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Controller
        name={name}
        control={uForm.control}
        defaultValue={moment().format("YYYY-MM-DD")}
        render={({ field : { onChange, value } }:any) => {
          return <KeyboardDatePicker
            key={key}
            value={value}
            margin="normal"
            format="DD/MM/YYYY"
            disabled={disable}
            KeyboardButtonProps={{"aria-label": "change date"}}
            onChange={(e) => (e!==null)&&onChange(e.format("YYYY-MM-DD"))} // date picker format ตรงนี้ได้
          />
        }}
      />
    </MuiPickersUtilsProvider>
  )
}