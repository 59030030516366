/* eslint-disable @typescript-eslint/no-unused-vars */
import { DELETE_ASSIGN } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const deleteAssign = fetchEntity.bind(null, Actions.DeleteAssign, Api.deleteAssign);
const updateCase = fetchEntity.bind(null, Actions.UpdateCase, Api.updateCase);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getCases = fetchEntity.bind(null, Actions.GetCases, Api.getCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);

function* firstAction(action: any) {
  yield call(
    deleteAssign, action.deleteAssign.params,
    {
      onSuccess: (success: any) => {
        if(action.insertAssign.params.type!=="group"){
          return call(actionUpdateCase, action);
        }else{
          return call(actionUpdateCases, action);
        }
      },
      onFailure: () => put(snackbarFailure('deleteAssign failed',1500))
    }
  );
}

function* actionUpdateCase(action: any) {
  yield call(
    updateCase, action.updateCase.params,
    {
      onSuccess: (success: any) => {        
        return call(actionGetCases, action);
      },
      onFailure: () => put(snackbarFailure('updateCase failed',1500))
    }
  );
}
function* actionUpdateCases(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {        
        return call(actionGetGroups, action)
      },
      onFailure: () => put(snackbarFailure('updateCases failed',1500))
    }
  );
}

function* actionGetCases(action: any) {
  yield call(
    getCases, { data: action.getCases.params },
    {
      onSuccess: (success: any) => {    
        return put(snackbarSuccess(action.insertAssign.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getCases failed',1500))
    }
  );
}
function* actionGetGroups(action: any) {
  yield call(
    getGroups, { data: action.getGroups.params },
    {
      onSuccess: (success: any) => {    
        return put(snackbarSuccess(action.deleteAssign.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getGroups failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(DELETE_ASSIGN.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
